require("../styles/_Main.scss");

class HeadlineDescription extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            descFocused: false
        };
    }
    
    componentDidMount() {
        this.autoHeight("description");
    }
    
    
    handleDescAlignClick() {
        // onBlur deletes alignment div, which deletes its onClick, so use onMouseOver
        // Use short delay so page can update first.
        setTimeout( function() {
            this.refs.description.focus();
        }.bind(this), 10)
        
    }
    
    handleDescFocus(status) {
        if (status == "blur") {  
            this.setState(
                {
                    descFocused: false
                }
            );
            
        } else if (status == "focus") {
            this.setState(
                {
                    descFocused: true
                }
            );
        }
    }
    
    autoHeight(ref, align) {
        this.refs[ref].style.height = "15px";
        this.refs[ref].style.height = (this.refs[ref].scrollHeight + 2) + "px";
        this.refs[ref].style.lineHeight = "16px";
    }
    

    render() {
        
        var descAlignVisibility     = (this.state.descFocused)  ? "displayBlock"    : "displayNone";

        return (
            <div>
            
                <div className="chart_title">
                    <h1>
                        <input 
                            ref             = "headline"
                            className       = "pathHeadline text_gold"  
                            type            = "text"  
                            defaultValue    = { this.props.varsJSON.headline }
                            onBlur          = { function() { 
                                                    this.props.updateBasicParams("headline", this.refs.headline.value) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                    </h1>
                </div>
                <div className = "descContainer">
                    <div className="descAlignContainer">
                        <div className={`descAlign ${ descAlignVisibility }`}>
            
                            <div    className   = "alignDescRight"
                                    onMouseDown = { function(e) {
                                                        this.props.updateBasicParams("descAlign", "textAlignRight");
                                                        this.handleDescAlignClick();
                                                     }.bind(this)   
                                    }
                            />
                            <div    className   = "alignDescCenter"
                                    onMouseDown = { function(e) {
                                                        this.props.updateBasicParams("descAlign", "textAlignCenter");
                                                        this.handleDescAlignClick();
                                                     }.bind(this)
                                    }
                            />
                            <div    className   = "alignDescLeft"
                                    onMouseDown = { function(e) {
                                                        this.props.updateBasicParams("descAlign", "textAlignLeft");
                                                        this.handleDescAlignClick();
                                                    }.bind(this)
                                    }
                            />
            
                        </div>  {/* end descAlign */}
                    </div>      {/* end descAlignContainer */}
                    
                    <div className="clearBoth" />
                    
                    <textarea 
                        ref             = "description"
                        className       = {`pathDescription overflowHidden ${ this.props.varsJSON.descAlign }`}
                        wrap            = "on"
                        type            = "text"  
                        defaultValue    = { this.props.varsJSON.desc }
                        onBlur          = { function(e) {
                                                this.props.updateBasicParams("desc", this.refs.description.value);
                                                this.handleDescFocus("blur");
                                            }.bind(this)
                        }
                        onFocus         = { function() {
                                                this.handleDescFocus("focus"); 
                                            }.bind(this)
                        }
                        onKeyUp         = { function() { 
                                                this.autoHeight("description");
                                            }.bind(this)
                        }
                    />
                </div>      {/* end descContainer */}
            </div>
        );
    }
}


export default HeadlineDescription;
