require("../styles/_Main.scss");

import {isMobile, isIOS, keysPressed, roundNumber, abbrNum} from "./Common.jsx";
//var Hammer = require('react-hammerjs');  //.default;
import Hammer from 'react-hammerjs';


class Slider extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            //text            : props.text,
            hasHover        : false,
            showInputError  : false,
            componentType   : 'slider',
        };
        
        // updated in componentDidUpdate, so can accept dependent var
           
        this.hint           = (!isMobile)                           ? "drag or arrows"                  : "drag";
        
        // this is needed to be able to removeEventListener, as the func changes when later bound again.
        // so, pre-bind
        this.onMouseOver    = this.onMouseOver.bind(this);
        this.onMouseOut     = this.onMouseOut.bind(this);
        this.doKeyDown      = this.doKeyDown.bind(this);
        this.onDoubleClick  = this.onDoubleClick.bind(this);
        this.onInputKeyUp   = this.onInputKeyUp.bind(this);
        this.onInputBlur    = this.onInputBlur.bind(this);
        
        this.doMouseDown    = this.doMouseDown.bind(this);
        this.doMouseUp      = this.doMouseUp.bind(this);
        this.doMouseMove    = this.doMouseMove.bind(this);
        // this.onTap          = this.onTap.bind(this);
        
        this.origX          = 0;
        this.mouseDown      = false;

        this.originalIndex  = props.choices.indexOf(props.value);
    }
    
    componentDidMount() {
        this.refs.main.addEventListener("mousedown",    this.doMouseDown);
        this.refs.main.addEventListener("touchstart",   this.doMouseDown);
    }
    
    componentWillUnmount() {
        // make sure you remove any event listeners
        this.refs.main.removeEventListener("mousedown", this.doMouseDown);
        this.refs.main.removeEventListener("touchstart",this.doMouseDown);
        window.removeEventListener("mouseup",           this.doMouseUp);
        window.removeEventListener("touchend",          this.doMouseUp);
        window.removeEventListener("mousemove",         this.doMouseMove);
        window.removeEventListener("touchmove",         this.doMouseMove);
        document.removeEventListener("keydown",         this.doKeyDown);
    }

    doMouseDown(e) {
		if (isMobile) {
            try {
                e = e.touches[0];
            } catch(e) {
                // with a Tap, touches vanishes before it is captured
            }
            
            this.setState(
                {
                    hasHover: true
                }
            );
			
            //e.preventDefault();
			//we have to manually calculate, as Android's clientX,Y is wonky
		}
        
        this.mouseDown  = true;
		this.origX      = e.pageX;
        
        this.props.handleUserEvents("mousedown", true);
        
        // prevent dragging from highlighting text
        
        // changed to  user-select in css, so can control
        
		//document.body.onselectstart = function() { return false; };
		//document.body.style.MozUserSelect = "none";
        
        //if this is a problem, set a flag in state to check
		//document.body.onmousedown = function() {
			//return false;
            //};
        
        window.addEventListener("mousemove", this.doMouseMove);
        window.addEventListener("touchmove", this.doMouseMove);
        window.addEventListener("mouseup",   this.doMouseUp);
        window.addEventListener("touchend",  this.doMouseUp);
    }
    
    doMouseUp(e) {
        
        this.mouseDown  = false;

        this.props.handleUserEvents("mousedown", false);
        
        this.setState(
            {
                hasHover: false // needed for touchend
            }
        );
        
        window.removeEventListener("mousemove", this.doMouseMove);
        window.removeEventListener("touchmove", this.doMouseMove);
        window.removeEventListener("mouseup",   this.doMouseUp);
        window.removeEventListener("touchend",  this.doMouseUp);
    }
    
    doMouseMove(e) {
		if (isMobile) {
            //e.preventDefault();
			e = e.touches[0];
		}
        
		var dragPct = (e.pageX - this.origX) / this.props.virtualWidth * 2;
        var index   =   Math.max(
                            Math.min(
                                Math.round(dragPct * this.props.choices.length + this.originalIndex), 
                                this.props.choices.length - 1
                            ), 
                            0
                        );
        
        this.props.updateValues(this.props.jsonKey, {[this.props.moduleKey]: this.props.choices[index]});
    }

 
    onMouseOver() {
        
        if (isIOS) {
            this.setState(
                {
                    hasHover: false
                }
            );
            return true;    // iOS returns to hover after mouseup? remove this
        }
        
        this.setState(
            {
                hasHover: true
            }
        );
        
        document.addEventListener("keydown", this.doKeyDown);
    }
    
    doKeyDown(e) {
        e.preventDefault();
        
            
        if (keysPressed[37] || keysPressed[40])   {   // left, down
            
            var currentIndex    = this.props.choices.indexOf( this.props.value ),
                maxIndex        = this.props.choices.length - 1,
                newIndex        = Math.min(Math.max(currentIndex - 1, 0), maxIndex);   
            
            this.props.handleUserEvents("keydown", true);
            
            this.props.updateValues(this.props.jsonKey, 
                {
                    [this.props.moduleKey]: this.props.choices[newIndex]
                }
            );
            
            if (this.props.handleDependentParams) {
                this.props.handleDependentParams(this.props.jsonKey, 
                    {
                        [this.props.moduleKey]: this.props.choices[newIndex]
                    }
                );
            }
            
        } else if (keysPressed[38] || keysPressed[39]) {  // up, right
            
            var currentIndex    = this.props.choices.indexOf( this.props.value ),
                maxIndex        = this.props.choices.length - 1,
                newIndex        = Math.min(Math.max(currentIndex + 1, 0), maxIndex);
                
            this.props.handleUserEvents("keydown", true);
        
            this.props.updateValues(this.props.jsonKey, 
                {
                    [this.props.moduleKey]: this.props.choices[newIndex]
                }
            );
            
            if (this.props.handleDependentParams) {
                this.props.handleDependentParams(this.props.jsonKey, 
                    {
                        [this.props.moduleKey]: this.props.choices[newIndex]
                    }
                );
            }
            
        }
        return false; // if no arrow keys, do nothing

    }

    
    onMouseOut() {
        //console.log("onMouseOut")
        this.setState(
            {
                hasHover: false
            }
        )
        document.removeEventListener("keydown", this.doKeyDown )
    }
    
    onDoubleClick(event) {
        // TODO: need to add doubletap listener also
        
        this.props.handleUserEvents("mousedown", false);
        
        this.setState(
            {
                componentType: 'input'
            },
            function() {
                window.removeEventListener("mouseup",           this.doMouseUp);
                window.removeEventListener("touchend",          this.doMouseUp);
                window.removeEventListener("mousemove",         this.doMouseMove);
                window.removeEventListener("touchmove",         this.doMouseMove);
                document.removeEventListener("keydown",         this.doKeyDown);
                
                this.refs.inputBox.focus();
                this.refs.inputBox.select();
            }
        );
    }
    
    onInputBlur() {
        
        var valueAsFloat = parseFloat(this.refs.inputBox.value);
        
        if (isNaN(valueAsFloat) || valueAsFloat < this.minVal || valueAsFloat > this.maxVal)  {
            
            this.refs.inputBox.value = roundNumber(Math.max(Math.min(valueAsFloat, this.maxVal), this.minVal), this.decimals);
            
            this.setState(
                {
                    showInputError   : true
                }
            );
            return;
        }
        
        this.setState(
            {
                componentType   : 'slider',
                showInputError  : false
            },
            function() {
                this.refs.main.addEventListener("mousedown", this.doMouseDown);
                this.refs.main.addEventListener("touchstart",this.doMouseDown);
                
                this.props.updateValues(this.props.jsonKey, {[this.props.moduleKey]: valueAsFloat} );
            }
        );
        
        
        
        if (this.props.handleDependentParams) {
            this.props.handleDependentParams(this.props.moduleKey, valueAsFloat);
        }
    }
    
    onInputKeyUp(e) {
        
        this.props.handleUserEvents("keydown", false);
        
        e = e || window.event;
        var charCode = (e.which) ? e.which : event.keyCode;
        
        if ( charCode == 13 || charCode == 9 ) {    // Enter or tab                                     
            this.onInputBlur();
        }
        
        if (this.refs.inputBox.value != this.refs.inputBox.value.replace(/[^\d.-]/g, '') ) {
            this.refs.inputBox.value = this.refs.inputBox.value.replace(/[^\d.-]/g, '');
            
            console.log("??")
            this.setState(
                {
                    showInputError   : true
                }
            );
        } 
    }

    
    render() {
        
    
        // ************   We standardize on all format types... value|abbreviation|hint
        // takes val and creates a child span, in nice comma format for user to see: $12,345,67.00, with hint div
        // then puts the real val, with more decimal places, as attr("val") of DOWslider span $(this)

        var className           =   (this.props.className != undefined)   ? this.props.className       : "DOWslider"; 

        var hintClassName       =   (this.state.hasHover)             ? 'hintHovered'                  :  'displayNone';
        var inputErrorClassName =   (this.state.showInputError)       ? 'errorMsg'                     :  'displayNone'; 
        
        var componentHTML       =   <span ref="main" style = {{"zIndex":"2000"}}>
                                    <Hammer     onDoubleTap={ this.onDoubleClick } >
                                        <span className="relative colResizeCursor">
                                            <span 
                                                className       = { className }
                                                onMouseOver     = { this.onMouseOver }
                                                onMouseOut      = { this.onMouseOut }
                                                onTouchStart    = { function(e) { this.doMouseDown(e) }.bind(this) }   // bind to pass event
                                                onTouchEnd      = { this.doMouseUp }
                                                onDoubleClick   = { this.onDoubleClick }
                                            >
                                                { this.props.value }
                                            </span>
                                            <span className = "numAbbr">{ this.numAbbr }</span>
                                            <div className = { hintClassName }>{ this.hint }</div>
                                        </span>
                                    </Hammer>
                                    </span>;
        
        if (this.state.componentType == 'input') {
            var inputType   = (isIOS) ? 'number' : 'text';   // Android doesn't like number type
            var errorMsg    = 'Enter value between ' + this.minVal + ' & ' + this.maxVal;
            if ( this.minVal == -Infinity && this.maxVal == Infinity ) {
                errorMsg    = 'Enter a number';
            } else if ( this.minVal == -Infinity && this.maxVal != Infinity ) {
                errorMsg    = 'Enter a value less than ' + this.maxVal;
            } else if ( this.minVal != -Infinity && this.maxVal == Infinity ) {
                errorMsg    = 'Enter a value greater than ' + this.minVal;
            }
                                
            componentHTML    =   <span className="relative">
                                    <input 
                                        ref             = "inputBox"
                                        type            = { inputType }  
                                        size            = "9" 
                                        maxLength       = "12" 
                                        defaultValue    = { roundNumber(this.props.value, this.decimals) }
                                        onKeyUp         = { this.onInputKeyUp }
                                        onBlur          = { this.onInputBlur }
                                        autoFocus       = { true }
                                    />

                                    <div className = { inputErrorClassName }>
                                        { errorMsg }
                                    </div>
                                </span>;
            
        }

        return (
            <span>
                { componentHTML }
            </span>
        );
    }
}

//Slider.propTypes = {
//  text: PropTypes.string.isRequired,
    //};



export default Slider;
