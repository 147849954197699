require("../styles/_Main.scss");

import Slider                           from "./Slider.jsx";
import TextToggle                       from "./TextToggle.jsx";
import StackedBarChart                  from "./StackedBarChart.jsx";
import SaveDialog                       from "./SaveDialog.jsx";
import HeadlineDescription              from "./HeadlineDescription.jsx";
import Button                           from "./Button.jsx";

import {roundNumber, displayFormatted}  from "./Common.jsx"


class Lease extends React.Component {
    
    constructor(props) {
        super(props);
        
        if (MsgToUser.indexOf('Saved version not found') == 0) {
            this.urlKey = ''
        } else {
            this.urlKey         = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        }
        
        this.state = {
            varsJSON            : varsJSON,
            showHelpDOM         : false,
            showPasswordHelpDOM : false,
            mousedown           : false,
            keydown             : false,
            descFocused         : false,
            varsChanged         : false,
            saveClicked         : "",
            errorText           : "",
            savedKey            : "",
            chartWarningText    : "",
            overlayGray         : false,
            moPmt               : 0,
        };
        
        this.showHelpDom        = this.showHelpDom.bind(this);
        this.hideHelpDom        = this.hideHelpDom.bind(this);
        this.updateValues       = this.updateValues.bind(this);
        this.handleUserEvents   = this.handleUserEvents.bind(this);
        this.hidePasswordHelpDom= this.hidePasswordHelpDom.bind(this);
        this.updateBasicParams  = this.updateBasicParams.bind(this);
        this.updateChart        = this.updateChart.bind(this);
        this.resetVarsChanged   = this.resetVarsChanged.bind(this);
        this.setOverlay         = this.setOverlay.bind(this);
        this.clearOverlay       = this.clearOverlay.bind(this);
        
        this.updateCapCost      = this.updateCapCost.bind(this);
    }
    
    componentDidMount() {
        this.updateChart();
    } 
    
    setOverlay() {
        this.setState(
            {
                overlayGray     : true
            }
        );
    }
    clearOverlay() {
        this.setState(
            {
                overlayGray     : false,
                varsChanged     : false
            }
        );
    }
    
    resetVarsChanged() {
        // called after a save
        this.setState(
            {
                varsChanged     : false
            }
        );
    }
    
 
    
    handleUserEvents(key, value) {
        // mousedown handles touchstart too
        // keydown
        var obj  = {}
        obj[key] = value;
    }
    

    updateBasicParams(param, value) {
        // text areas controlled, so need to give them back a new value

        if (param == "headline" || param == "desc") {
            value = value.replace(/<\s?a\b[^>]*>(.*?)<\/a>/gi,"<link removed>");
            //var obj  = {}
            //obj[param] = value;
            //this.setState(obj);
        }
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        toUpdateJSON[param] = value;
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function() {
                //console.log(this.state.varsJSON)
            }
        );
        toUpdateJSON = null;
        
        
        // this.updateChartSeries();        // no need to redraw the chart for these changes
    }
    
    updateValues(jsonKey, valueDict) {
        // this is a synchronous setting of state/calling new chart
        // valueDict in form {moduleKey: value}
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        if (jsonKey == "base") {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON[key] = valueDict[key];
            });
        } else {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON.modules[jsonKey][key] = valueDict[key];
            });
        }
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function () {
                this.updateChart();
            }
        );
        
        toUpdateJSON = null;
        /*  send function to setState to make completely sync:
            setState(function(previousState, currentProps) {
                return {myInteger: previousState.myInteger + 1};
            });
        */
    }
    
    handleCheckboxChange(jsonKey, ref) {
        var isChecked = this.refs[ref].checked;
        this.updateValues( jsonKey, {[ref]: isChecked} );
    }

    showHelpDom() {
        this.setState(
            {
                showHelpDOM: true
            }
        );
    }
    hideHelpDom() {
        this.setState(
            {
                showHelpDOM: false
            }
        );
    }
    showPasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: true
            }
        );
    }
    hidePasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: false
            }
        );
    }

    
    getGoodRange(num) {
        var power = Math.floor(Math.log(num)/Math.log(10));
        var digit = Math.floor(num / Math.pow(10, power)) + 1;
        return digit * Math.pow(10, power);
    }
    
    updateChart() {
        
        var params = this.state.varsJSON.modules["1"];
        
        var capCost             = parseInt(params.capCost),
            capReduction        = parseInt(params.capReduction),
            residual            = parseInt(params.residual),
            intRate             = parseFloat(params.intRate) / 1200,
            numMonths           = parseInt(params.numMonths);
        
        var moPmt               = (capCost - capReduction - residual) / numMonths + (capCost - capReduction + residual) / 2 * intRate;

        if (moPmt < 0) { moPmt = 0 }

        // chart data
        var validSeries = [[residual,0], [capCost - capReduction - residual,0], [capReduction,0]];

        this.setState(
            {
                moPmt       : moPmt,
            }
        );
    }
    
    
    updateCapCost(jsonKey, kvdict) {
        // let user adjust desired pmt to affect cap cost
        
        var newPmt = kvdict["moPmt"];
        
        var params = this.state.varsJSON.modules["1"];
        
        var capCost             = parseInt(params.capCost),
            capReduction        = parseInt(params.capReduction),
            residual            = parseInt(params.residual),
            intRate             = parseFloat(params.intRate) / 1200,
            numMonths           = parseInt(params.numMonths);
        
        var newCapCost = ( newPmt + ( capReduction + residual) / numMonths  + (capReduction - residual) / 2 * intRate ) / ( 1/numMonths + intRate/2);
        
        this.updateValues("1", {"capCost": Math.round(newCapCost)})
    }
        

    render() {
        
        var params              = this.state.varsJSON.modules["1"];
        
        var moneyFactor         = roundNumber(params.intRate / 2400, 4);
        
        var maxCapReduction     = params.capCost - params.residual;
        var maxResidual         = params.capCost - params.capReduction;
        var minCapCost          = params.residual + params.capReduction;
        
        var errorDOM            = <div/>;
        if (params.capCost - params.residual <= params.capReduction) {
            params.capReduction = params.capCost - params.residual;
            errorDOM            = <div><span className="text_red ">ERROR</span>: Cap Reduction cannot exceed (Cap Cost - Residual)</div>;
        }
        
            
        var overlayClass        = (this.state.overlayGray) ? "overlayGray" : "displayNone";
        var overlayDOM          = <div className = {overlayClass} />
        
        var descAlignVisibility = (this.state.descFocused)          ? "displayBlock"        : "displayNone";
       

        var helpDOMClassName    = (this.state.showHelpDOM)          ? 'helpDOM'             : 'displayNone'; 
        
        var helpDOM =   <div className= { helpDOMClassName }> 
                            <div className="heading_bar"> 
                                <div className="center_heading text_blue">Help</div>  
    
                                <div className="right_heading"> 
                                    <div className="icon_delete" onClick={ this.hideHelpDom }></div> 
                                </div> 
                            </div> 
                            <div> 


                            <div className="text_blue clearBoth"><div className="clearBoth">Changing Values:</div></div><br /> 

                            There are several ways to easily change the values:<br/><br />

                            <div style={{"marginLeft":"16px"}}>

                                1) Click and drag numerical values to change.<br />
                                2) Double-click the value to enter directly<br />
                                3) Mouse over the value, and use the arrow keys to finely adjust<br /><br />
                            </div>

                            Hold down the following keys to adjust the rate of change of the slider: <br/><br /> 
                            <div style={{"marginLeft":"16px"}}>
                                (.) - adjust by 0.01<br /> 
                                (0) - adjust by 0.1<br /> 
                                (2) - adjust by 10x<br /> 
                                (3) - adjust by 100x<br /> 
                                (4) - adjust by 1000x<br /> 
                                (5) - adjust by 10000x<br /> 
                                (6) - adjust by 100000x 
                            </div>

                            <br />

                            <div className="text_blue clearBoth"><div className="clearBoth">Editing Headline and Description:</div></div><br />

                    
                            Double-click the Title or Description to edit either. &nbsp;While editing the description, icons for text alignment become visible.<br /><br />

                    
                            <div className="text_blue clearBoth"><div className="clearBoth">Icons:</div></div><br /> 
                            </div> 
                            <div><div className="icon_help clearBoth"></div> - this help file</div> 
                            <div><div className="icon_minimize clearBoth"></div> - minimize module to show just header (toggle to display again)</div> 
                            <div><div className="icon_down clearBoth"></div> - move module down.  (shift-click to move all the way to bottom)</div> 
                            <div><div className="icon_up clearBoth"></div>  - move module up.  (shift-click to move all the way to top)</div> 
                            <div><div className="icon_highlight clearBoth"></div> - highlight the date region of this module</div> 
                            <div><div className="icon_toggle clearBoth"></div> - mouse over to show effects of module</div> 
                            <div><div className="icon_deactivate"></div> - deactivate this module, without deleting</div>
                            <div><div className="icon_delete clearBoth"></div> - delete module</div> 
                            <div>
                            <br />


                                <div className="textAlignCenter">
                                    Questions or comments? &nbsp; Please <a href="mailto:plan@dynamicsofwealth.com">email Us</a>.<br /><br /> 
                                    <Button     
                                            text            = "Close"
                                            clickHandler    = { this.hideHelpDom }
                                            className       = "blue_button"
                                    />
                                </div>
                                <br /> <br /> 
                            </div> 
                    
                    
                        </div>;
                
      
        var currencySymbol = ""
                        
        switch (this.state.varsJSON.currencySymbol) {
            case "$":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "€":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "￥":
                currencySymbol = <span style = {{"marginRight":"0px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "£":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
        }
    
        return (
            <div id="outer_canvas">
            
                { overlayDOM }
                { helpDOM }
                
                <HeadlineDescription
                    varsJSON            = { this.state.varsJSON }
                    updateBasicParams   = { this.updateBasicParams }
                />
                        
                        
                <StackedBarChart
                        data            = {[
                                                {
                                                "column": "1",
                                                "Residual Financed": params["residual"],
                                                "Depreciation Financed": params["capCost"] - params["capReduction"] - params["residual"],
                                                "Cap Reduction": params["capReduction"],
                                              }
                                          ]}

                        colors          = { ["#4191c5", "#880e16", "#dfbc4c"] }
                        height          = "400"
                        width           = "900"
                        xDataLabel      = { currencySymbol }
                        currencySymbol  = { currencySymbol }
                        axisClasses     = {["displayNone", "displayNone"]}
                />
                    
                <div className="paramsContainer module_box">
                    <div className="heading_bar">
                        <div className="left_heading text_blue" style={{"paddingLeft":"0"}}>Lease Calculator</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_blue"  
                            type            = "text"  
                            defaultValue    = { params.title }
                            onBlur          = { function() { 
                                this.updateValues("1", {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                        <div className="right_heading">
                        	<div className="icon_help"      onClick={ this.showHelpDom }></div>
            
                    		<div className="icon_blank"     ></div>
                            <TextToggle 
                                jsonKey             = { "base" }
                                moduleKey           = "currencySymbol"
                                classname           = "currencySymbol"
                                updateValues        = { this.updateValues }
                                value               = { this.state.varsJSON.currencySymbol }                             
                                choices             = { [ "$", "€", "￥", "£" ] }
                                handleUserEvents    = { this.handleUserEvents }
                            />
                        	<div className="clearBoth"></div>
                        </div>  
                    </div>
                
            		<div className="module_content">
            			Automobile dealers love to lease automobiles. &nbsp;Why? &nbsp;Because through a lease, they do not have to disclose to you the selling price of the vehicle, or the interest rate. &nbsp;
            			That means they can hide thousands of dollars of additional profit in your agreement. &nbsp;Use this calculator to determine the real terms of the lease, then negotiate a better deal.
            			<br /><br />
            			I am considering the lease of an automobile (or boat, plane, motorcycle, etc.). &nbsp;Here are the parameters:
            			<br /><br />
			
            			Capitalized Cost (selling price plus fees): &nbsp;{ currencySymbol }
                        <Slider 
                            jsonKey             = "1"
                            moduleKey           = "capCost"
                            value               = { params.capCost }
                            step                = "100"
                            min                 = { minCapCost }
                            updateValues        = { this.updateValues }
                            handleUserEvents    = { this.handleUserEvents }
                        />. <br /><br />
                            
                        { errorDOM }
                        Capital Reduction (down payment): &nbsp;{ currencySymbol }
                        <Slider 
                            jsonKey             = "1"
                            moduleKey           = "capReduction"
                            value               = { params.capReduction }
                            step                = "100"
                            max                 = { maxCapReduction }
                            updateValues        = { this.updateValues }
                            handleUserEvents    = { this.handleUserEvents }
                        />. <br /><br />
                    
                        Residual: &nbsp;{ currencySymbol }
                        <Slider 
                            jsonKey             = "1"
                            moduleKey           = "residual"
                            value               = { params.residual }
                            step                = "100"
                            max                 = { maxResidual }
                            updateValues        = { this.updateValues }
                            handleUserEvents    = { this.handleUserEvents }
                            />. <br /><br />
                            
            			Number of Payments:  &nbsp;  
                        <Slider 
                            jsonKey             = "1"
                            moduleKey           = "numMonths"
                            value               = { params.numMonths }
                            updateValues        = { this.updateValues }
                            handleUserEvents    = { this.handleUserEvents }
                        />. <br /><br />
                        
            			Market Interest Rate: &nbsp;
                        <Slider 
                            jsonKey             = "1"
                            moduleKey           = "intRate"
                            value               = { params.intRate }
                            step                = "0.05"
                            updateValues        = { this.updateValues }
                            handleUserEvents    = { this.handleUserEvents }
                        />% APR. &nbsp;(Money Factor: { moneyFactor })<br />
			
            			<br /><br />
                            My lease payment should be: &nbsp;{ currencySymbol }
                            
                        <Slider 
                            jsonKey             = "1"
                            ref                 = "moPmt"
                            moduleKey           = "moPmt"
                            value               = { this.state.moPmt }
                            updateValues        = { this.updateCapCost }
                            handleUserEvents    = { this.handleUserEvents }
                        />, plus taxes.
                            
            			<br /><br />
            			Is the dealer charging too much for the vehicle, or too high a rate? &nbsp;Negotiate better terms, and save the money &nbsp;Let us know how much you have saved.
            			<span className="displayNone"></span>
            		</div>
            	</div>
                
                        
                <SaveDialog
                    varsJSON            = { this.state.varsJSON }
                    varsChanged         = { this.state.varsChanged }
                    resetVarsChanged    = { this.resetVarsChanged }
                    thisDir             = "/lease-calculator/"
                    thisKey             = { this.urlKey }
                    submitUrl           = "/save_lease/"
                    messages            = {{
                                                saveExisting    : "Save This Data Set",
                                                saveAsNew       : "Save As New Data Set",
                                          }}
                    setOverlay          = { this.setOverlay }
                    clearOverlay        = { this.clearOverlay }
                />
                                           
            </div>

      )            
  }
}


export default Lease;
