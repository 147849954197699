require("../styles/_Main.scss");

import Slider       from "./Slider.jsx";
import TextToggle   from "./TextToggle.jsx";
//import {roundNumber} from "./Common.jsx"

class BaselineModule extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            ageStart : this.props.module.ageStart 
        };

        this.updateRetirementWithdrawal = this.updateRetirementWithdrawal.bind(this);
        this.handleDependentParams      = this.handleDependentParams.bind(this);
    }

    
    componentDidMount() {
        // 
    }
    
    componentWillUnmount() {
        // make sure you remove any event listeners
    }
    
    handleDependentParams(key, value) {
        // store independent in state, so dependent can call it.
        // e.g. selling age should not be before purchase age.
        var obj  = {}
        obj[key] = value;
        this.setState(obj);
    }
    
    updateRetirementWithdrawal(jsonKey, kvdict) {
        // allow user to change after tax amount, then re-calc pre-tax amount withdrawn from account  
        // taxes deducted from tax-deferred accounts

        var newAfterTaxWithdrawal = kvdict["spendable"];
        
        var beforeTaxes = newAfterTaxWithdrawal / (1 - this.props.module.taxRateWithdrawals/100 *  this.props.module.taxDeferPct/100);   
        this.props.updateValues(this.props.JSONkey, {"retireWithdraw": beforeTaxes});
    }

    
    render() {
        

        var JSONkey         =           this.props.JSONkey;
        var moduleClassName =           ( this.props.module.minimized == false)
                                            ? "BL_box module_box module_visible"
                                            : "BL_box module_box module_minimized";
        
        var activeClassName;
                                                                               
        if ( this.props.module.active == false) {
            moduleClassName                 = "BL_box module_box module_minimized";
            activeClassName                 = "inactive";
        } else {
            activeClassName                 = "displayNone";
        }
        
        var retireAfterTax  = this.props.module.retireWithdraw * (1 - this.props.module.taxRateWithdrawals/100 * this.props.module.taxDeferPct/100 );

        return (

            <div className={ moduleClassName }>
                <div className="heading_bar">
                    <div className="left_heading text_blue">Baseline</div>
                    <input 
                        ref             = "title"
                        className       = "center_heading text_blue"  
                        type            = "text"  
                        defaultValue    = { this.props.module.title }
                        onBlur          = { function() { 
                            this.props.updateValues(JSONkey, {"title": this.refs.title.value}) }.bind(this)
                        }
                        maxLength       = "60"
                    />
                    <div className="right_heading">
                    	<div className={ activeClassName }>(inactive) &nbsp; &nbsp; </div>
                    	<div className="icon_help"      onClick={ this.props.showHelpDom }></div>
                        <div className="icon_minimize"  onClick={ function() { this.props.stepChoices(JSONkey, "minimized") }.bind(this) }></div>
                    	<div className="icon_down"      onClick={ function() { this.props.moveDown(JSONkey) }.bind(this) }></div>
                    	<div className="icon_up"        onClick={ function() { this.props.moveUp(JSONkey) }.bind(this) }></div>
                		<div className="icon_highlight" onClick={ function() { this.props.stepChoices(JSONkey, "highlighted") }.bind(this) }></div>
                		<div className="icon_blank"     ></div>
                        <TextToggle 
                            jsonKey             = { "base" }
                            moduleKey           = "currencySymbol"
                            classname           = "currencySymbol"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.currencySymbolStr }                             
                            choices             = { [ "$", "€", "￥", "£" ] }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />
                    	<div className="clearBoth"></div>
                    </div>  
                </div>
                <div className="module_content">
                    My financial path starts at age &nbsp; 
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "ageStart"
                            updateValues        = { this.props.updateValues }
                            max                 = "100" 
                            //step                = "0.083333333" 
                            value               = { this.props.module.ageStart }
                            handleUserEvents    = { this.props.handleUserEvents }
                            handleDependentParams= { this.handleDependentParams }
                        />,
                            with an initial account balance of { this.props.currencySymbol }
                        <Slider
                            jsonKey             = { JSONkey }
                            moduleKey           = "principalStart"
                            updateValues        = { this.props.updateValues }
                            min                 = "-Infinity"
                            growth              = ".01"
                            value               = { this.props.module.principalStart }
                            handleUserEvents    = { this.props.handleUserEvents }
                            />.&nbsp; 
                	I expect to save  { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "addPrincMo"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.addPrincMo }
                            handleUserEvents    = { this.props.handleUserEvents }
                        /> per month
                	until I retire at age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "ageRetire"
                            updateValues        = { this.props.updateValues }
                            min                 = { this.state.ageStart }
                            max                 = "150" 
                            growth              = "10"
                            value               = { this.props.module.ageRetire }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />,
                	and wish to withdraw { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "retireWithdraw"
                            updateValues        = { this.props.updateValues }
                            growth              = ".2"
                            value               = { this.props.module.retireWithdraw } 
                            handleUserEvents    = { this.props.handleUserEvents }
                        /> per month from my account while in retirement. &nbsp;
                	I chart my path until age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "ageEnd"
                            updateValues        = { this.props.updateValues }
                            min                 = { this.state.ageStart }
                            max                 = "200" 
                            growth              = "5"
                            value               = { this.props.module.ageEnd }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />.
                	<br /><br />
                	Before retirement, I expect to earn a reasonable return rate on my investments of &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "preRetireRate"
                            updateValues        = { this.props.updateValues }
                            max                 = "1000" 
                            step                = "0.01" 
                            decimals            = "2"
                            value               = { this.props.module.preRetireRate }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%.&nbsp;
                	After retirement, I expect to move into less volatile investments, with a return rate of &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "retireRate"
                            updateValues        = { this.props.updateValues }
                            max                 = "1000" 
                            step                = "0.01" 
                            decimals            = "2"
                            value               = { this.props.module.retireRate }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%.
                	&nbsp; (I have entered real (after inflation) return rates, or entered nominal (before inflation) rates, then adjusted for inflation below.)
                		<div className="inactive2">(inactive)</div><br /><br />
                	I cannot ignore the effect of income taxes on my returns or withdrawals.&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "taxDeferPct"
                            updateValues        = { this.props.updateValues }
                            min                 = "0"
                            max                 = "100" 
                            growth              = "5"
                            value               = { this.props.module.taxDeferPct }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% of my savings will be in tax-deferred accounts.&nbsp;
                	Before retirement, the average income tax rate on the returns in my taxable accounts will be &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "taxRatePreRetire"
                            updateValues        = { this.props.updateValues }
                            max                 = "100" 
                            step                = "0.5" 
                            value               = { this.props.module.taxRatePreRetire }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%,
                	based on the expected tax rates on my combination of interest, dividends, and capital gains.&nbsp;
                    
                    While in retirement, the average income tax rate on the returns in my taxable accounts will be &nbsp; 
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "taxRateRetire"
                            updateValues        = { this.props.updateValues }
                            max                 = "100" 
                            step                = "0.5" 
                            value               = { this.props.module.taxRateRetire }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%. &nbsp;
                	While in retirement, the average income tax rate on the funds withdrawn from my tax-deferred accounts will be &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "taxRateWithdrawals"
                            updateValues        = { this.props.updateValues }
                            max                 = "100" 
                            step                = "0.5" 
                            value               = { this.props.module.taxRateWithdrawals }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%,
                	leaving me with { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "spendable"
                            updateValues        = { this.updateRetirementWithdrawal }
                            decimals            = "0"
                            value               = { retireAfterTax }
                            handleUserEvents    = { this.props.handleUserEvents }
                        /> spendable cash each month.<br />
                	<span style={{"display": "none"}}></span>
                </div>
            </div>
        );
    }
}


export default BaselineModule;