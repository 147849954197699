import { min, max, axisBottom, axisLeft, scaleLinear, scaleBand, scaleOrdinal, format, bisector } from 'd3';
import { select, selectAll } from "d3-selection";

const d3 = Object.assign(
  {},
  { select, selectAll, min, max, axisBottom, axisLeft, scaleLinear, scaleBand, scaleOrdinal, format, bisector}, //request
)

import {roundNumber, appendArray} from "./Common.jsx";
 
class BarChart extends React.Component {

    constructor(props) {
        super(props);
            
        this.ref            = React.createRef();
        this.bisectXparam   = 0;
        this.draw           = this.draw.bind(this);
        this.formatValue    = this.formatValue.bind(this);
        this.mousemove      = this.mousemove.bind(this);
        this.customXAxis    = this.customXAxis.bind(this);
        this.customYAxis    = this.customYAxis.bind(this);


        // Margin is for chart and Axes
        // labelPadding lets us control the location of Axis Labels
        this.margin          = this.props.margin || {top: 20, right: 20, bottom: 20, left: 40};
        this.labelPadding    = this.props.labelPadding || {top: 0, right: 18, bottom: 18, left: 18};
        this.DOMwidth        = this.props.width;
        this.DOMheight       = this.props.height;
    
        this.chart           = {
            top         : this.labelPadding.top + this.margin.top,
            bottom      : this.DOMheight - (this.labelPadding.bottom + this.margin.bottom),
            left        : this.labelPadding.left + this.margin.left,
            right       : this.DOMwidth - (this.labelPadding.right + this.margin.right),
            width       : this.DOMwidth - this.labelPadding.left - this.labelPadding.right - this.margin.left - this.margin.right,
            height      : this.DOMheight - this.labelPadding.top - this.labelPadding.bottom - this.margin.top - this.margin.bottom
        };

        this.xDataLabel     = this.props.xDataLabel;
        this.yDataLabel     = this.props.yDataLabel;   
    }
    
    componentDidMount() {
        this.svgParent = d3.select(this.ref.current)
            .append("svg")
            .attr("class", "barChart")
            .attr("width",  this.DOMwidth)
            .attr("height", this.DOMheight);
    }
    
    componentDidUpdate() {
        // define x and y linear ranges, with min and max of all series.  accept zero  
        this.xMin = (typeof this.props.xMin == 'number') ? this.props.xMin : d3.min(this.props.mainSeries, (d) => d[this.xDataLabel["name"]]);
        this.xMax = (typeof this.props.xMax == 'number') ? this.props.xMax : d3.max(this.props.mainSeries, (d) => d[this.xDataLabel["name"]]);
        this.yMin = (typeof this.props.yMin == 'number') ? this.props.yMin : d3.min(this.props.mainSeries, (d) => d[this.yDataLabel["name"]]);
        this.yMax = (typeof this.props.yMax == 'number') ? this.props.yMax : d3.max(this.props.mainSeries, (d) => d[this.yDataLabel["name"]]);

        this.xScale = d3.scaleLinear()
            .rangeRound([this.chart.left, this.chart.right])
            .domain([ this.xMin, this.xMax ]);

        this.yScale = d3.scaleLinear()
            .rangeRound([ this.chart.bottom, this.chart.top ])
            .domain([ this.yMin, this.yMax ]);

        this.numBars  = this.props.numBars ? Math.max(this.props.numBars, (this.xMax - this.xMin)) : (this.xMax - this.xMin);
        this.barWidth = Math.round( Math.max(1, this.chart.width / this.numBars)) ;
        
        if (this.barWidth > 5) this.barWidth -=2;  // add 1px space between bars
        
        // customize the axes
        this.xAxis = d3.axisBottom(this.xScale)
            .ticks(10)
            .tickSizeInner(-this.chart.height)
            .tickSizeOuter(-this.chart.height)
            .tickPadding(5);

        this.yAxis = d3.axisLeft(this.yScale)
            //.tickArguments(["color:red"])
            .ticks(10, this.yDataLabel["name"])
            .tickFormat(d3.format("." + this.yDataLabel["axisDecimals"] + "f"))
            .tickSizeInner(-this.chart.width)
            .tickSizeOuter(-this.chart.width)
            .tickPadding(5);
        
        this.x = d3.scaleBand()
            .rangeRound([0, this.chart.width/4])
            .paddingInner(0.05)
            .align(0.1);

        this.y = d3.scaleLinear()
            .rangeRound([this.chart.height, 0]);
            
        if (this.props.mainSeries) {
            this.svgParent.selectAll(".g").remove();
            this.draw();
        }
        
    }
    
    draw() {
        /*
        this.props.mainSeries.forEach(function(d) {
            var y0 = 0;
            d.vals = this.z.domain().map(function(name) { return {name: name, y0: y0, y1: y0 += +d[name]}; });
            d.total = d.vals[d.vals.length - 1].y1;
        }.bind(this));

        this.props.mainSeries.sort(function(a, b) { return b.total - a.total; });
        */

        this.x.domain(this.props.mainSeries.map(function(d) { return d.column; }));
        this.y.domain([0, d3.max(this.props.mainSeries, function(d) { return d.total; })]);
        
        var svg             = this.svgParent
                                .append("g")
                                .attr("class", "g");
        var highlightGroup  = svg.append("g")
            
        // Title
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform",   "translate("+ (this.DOMwidth/2) + "," +  (this.margin.top*2/3) + ")")
            .attr("class" ,      "chartTitle")
            .text(this.props.title);
    
        // mouseover values at top-right
        svg.append("text")
            .attr("class",       "mouseoverValuesText")
            .attr("text-anchor", "end")
            .attr("transform",   "translate(" + this.chart.right + ", " +  (this.margin.top*2/3) + ")");
            
        // X Axis
        svg.append("g")
            .attr("transform", "translate(0," + this.chart.bottom + ")")
            //.call(this.customXAxis); // can call special functions to alter things after setup
            .call(this.customXAxis, this.xAxis)
        
        // Y Axis
        svg.append("g")
            .attr("class", "yAxis")
            //.call(this.customYAxis); // can call special functions to alter things after setup
            .call(this.customYAxis, this.yAxis)
        
        // X Axis Label
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform",   "translate("+ (this.DOMwidth/2) + "," + (this.DOMheight-(this.labelPadding.bottom/3))+")")
            .attr("class",       "axisLabels")
            .text(this.xDataLabel["prefix"] + this.xDataLabel["name"] + this.xDataLabel["suffix"]);
        
        // Y Axis Label
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform",   "translate("+ (this.labelPadding.left/3) + "," + (this.DOMheight/2) + ")rotate(-90)")
            .attr("dy",          ".71em")
            .attr("class",       "axisLabels")
            .text(this.yDataLabel["prefix"] + this.yDataLabel["name"] + this.yDataLabel["suffix"]);

        // if X ranges changed so there is no data
        if ( this.props.mainSeries.length < 1) {
            svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "translate("+ (this.DOMwidth/2) + "," + (this.DOMheight/2) + ")")
                .attr("dy", ".71em")
                .attr("class", "axisLabels")
                .text("Ending " + this.xDataLabel["name"] + " must be larger than starting " + this.xDataLabel["name"] + ".");
        
            return (
                <div className  = {"visible"} 
                     ref        = { this.ref } 
                />
            );
        }
        
        // Draw areas (pos & neg) (with no stroke), then put path on it later
        svg.selectAll(".bar")
            .data(this.props.mainSeries)
            .enter().append("rect")
            .attr("class", "blueBar")
            .attr("x", (d) => this.xScale(d["$"]))
            .attr("display", (d) => (this.xScale(d["$"]) >= this.chart.left && this.xScale(d["$"]) < this.chart.right) ? "visible" : "none")
            .attr("width", this.barWidth)
            .attr("y", (d) => this.yScale(d["%"]))
            .attr("height", (d) => {return this.chart.height + this.labelPadding.top + this.labelPadding.bottom + 2 - this.yScale(d["%"])});

        for (var i = 0; i < this.props.highlightSeries.length; i++) {
            svg.selectAll(".bar")
                .data(this.props.highlightSeries[i].data)
                .enter().append("rect")
                .attr("class", this.props.highlightSeries[i].className)
                .attr("x", (d) => this.xScale(d["$"]))
                .attr("display", (d) => (this.xScale(d["$"]) >= this.chart.left && this.xScale(d["$"]) < this.chart.right) ? "visible" : "none")
                .attr("width", this.barWidth)
                .attr("y", (d) => this.yScale(d["%"]))
                .attr("height", (d) => {return this.chart.height + this.labelPadding.top + this.labelPadding.bottom + 2 - this.yScale(d["%"])});
        }
      
        var cursorGroup = svg.append("g")
            .attr("width",     100)
            .attr("class",    "cursorGroup")
            .style("display", "none");
        
        cursorGroup.append("rect")
            .attr("width",  1)
            .attr("height", this.chart.height)
            .attr("class",  "cursorLine");

        cursorGroup.append("text")
            .attr("class" , "cursorLine")
            .attr("x" ,     10)
            .attr("y",      "1em");

        cursorGroup.append("circle")
            .attr("r"         , 3)
            .attr("class"     , "cursorLine");
        
        svg.append("rect")
            .attr("class"     , "transparentOverlay")
            .attr("width"     , this.chart.width)
            .attr("height"    , this.chart.height)
            .attr("transform" , "translate(" + (this.chart.left + 2) + ", " +  this.chart.top + ")") // 2 for border
            .on("mouseover",    function() { d3.select(".cursorGroup").style("display", null); })
            .on("mouseout",     function() { d3.select(".cursorGroup").style("display", "none"); })
            .on("mousemove",    this.mousemove)
            .on("touchstart",   function() { d3.select(".cursorGroup").style("display", null); })
            .on("touchend",     function() { d3.select(".cursorGroup").style("display", "none"); })
            .on("touchmove",    this.mousemove);
    
        // finally, add a border, to cover up dotted axes at max
        //var chartCont = document.getElementById('chartCont');
        //svg.attr("border", "10px solid red")
        
        
        this.bisectXparam = d3.bisector( (d) => d[this.xDataLabel["name"]]).left;
    }
    
    customXAxis(g, xAxis) {
        g.call(xAxis);
        g.selectAll(".tick:not(:first-of-type) line")
            .attr("stroke",  "#aaa")
            .attr("stroke-dasharray",  "2,2");
    }

    customYAxis(g, yAxis) {
        g.call(yAxis)
            .attr("transform", "translate(" + this.chart.left + ", " + 0 + ")");
            //g.select(".domain").remove();
            //g.selectAll(".tick line")

        g.selectAll(".tick:not(:first-of-type) line")
            .attr("stroke", "#aaa")
            .attr("stroke-dasharray", "2,2");
    }
    
    mousemove(event) {
        // d3.mouse(this)[0] is a bug not yet fixed for use with FauxDOM.  Use layerX
        event.preventDefault();
        
        var mouseX = event.layerX;  
        
        var xParam = this.xScale.invert( mouseX );   // exact Xaxis lookup, based on scale given
        
        // lookup from mouse position xParam falls between 2 actual data points, 
        // so find closest real one
        
        // set to mainSeries min or max if off chart
        xParam = Math.max(Math.min(xParam, this.xMax), this.xMin);
        
        try {
            var JSONpos     = Math.max(Math.min(this.bisectXparam(this.props.mainSeries, xParam), this.props.mainSeries.length - 1), 0);

            var prevDatum   = ( JSONpos > 0 ) ? this.props.mainSeries[JSONpos - 1] : this.props.mainSeries[JSONpos];
            var Datum       = this.props.mainSeries[JSONpos];
        
            var match       = (xParam - prevDatum[this.xDataLabel["name"]]) < (Datum[this.xDataLabel["name"]] - xParam) 
                                    ? prevDatum 
                                    : Datum;
                                    
            var xPos        = (this.xScale(match[this.xDataLabel["name"]]) + this.barWidth/2);

            d3.select(".cursorGroup rect")
                .attr("transform", "translate(" + xPos + ", " +  this.chart.top + ")")
                                
            d3.select(".cursorGroup circle")
                .attr("transform", "translate(" + xPos + ", " + this.yScale(match[this.yDataLabel["name"]])  + ")")
            d3.select(".cursorGroup text")  
                .selectAll("tspan").remove()
                                        
            // adjust Y of cursorGroup
                                
            var dyAdjust    = -4.2;
            /*
            if (yLookAhead > match[this.yDataLabel["name"]]) {
                // positive slope
                //dyAdjust    = 0;
            }
            */
                              
            d3.select(".cursorGroup text")
                .attr("transform", "translate(" + xPos + ", " + this.yScale(match[this.yDataLabel["name"]]) + ")")
            
                .append('svg:tspan')
                    .attr("class", "cursorRedText")
                    .attr('x',     8)
                    .attr('dy',    dyAdjust + "em")
                    .text( this.xDataLabel["name"] + ": " + this.formatValue(match[this.xDataLabel["name"]], this.xDataLabel["prefix"], this.xDataLabel["suffix"], this.xDataLabel["decimals"]) )
                .append('svg:tspan')
                    .attr("class", "cursorRedText")
                    .attr('x',     8)
                    .attr('dy',    "1.1em")
                    .text( this.yDataLabel["name"] + ":"  + this.formatValue(match[this.yDataLabel["name"]], this.yDataLabel["prefix"], this.yDataLabel["suffix"], this.yDataLabel["decimals"]) );
            
        
            // top-right display     
            d3.select(".mouseoverValuesText")
                    .text( this.xDataLabel["name"] + ": " + this.formatValue(match[this.xDataLabel["name"]], this.xDataLabel["prefix"], this.xDataLabel["suffix"], this.xDataLabel["decimals"]) + ",     " + this.yDataLabel["name"] + ":"  + this.formatValue(match[this.yDataLabel["name"]], this.yDataLabel["prefix"], this.yDataLabel["suffix"], this.yDataLabel["decimals"]) );
                    
                    
            if (this.props.cursorExtra && this.props.cursorExtra == "percentile" ) {
                var percentileTotal = 0;
                for (var i = 0; i < JSONpos; i++) {
                    percentileTotal += this.props.mainSeries[i]["%"];
                }
                /*d3.select(".cursorGroup text")
                    .append('svg:tspan')
                        .attr("class", "cursorRedText")
                        .attr('x', 8)
                        .attr('dy', "1.1em")
                        .text( "Percentile: " + Math.round(percentileTotal) );
                */
                d3.select(".mouseoverValuesText")
                    .append('svg:tspan')
                        .text( ",     Percentile: " + roundNumber(percentileTotal, 1) + "%" );
            }
                    
            // send x,y values back to parent's handler
            //this.props.shareData(
            //    {
            //        x   : this.xDataLabel["name"], 
            //        y   : this.yDataLabel["name"]
            //  }
            //);
            
        } catch(e) {
            console.log("chart not ready, or cursor out of bounds");
        }
    }
    
    formatValue(value, prefix, suffix, decimals) {
        var formatVal = d3.format(",." + decimals + "f");
        return prefix + formatVal(value) + suffix;
    }
    
    render() {
        return (
            <div className  = {"visible"} 
                 ref        = { this.ref } 
            />
        );
    }

}

export default BarChart;
