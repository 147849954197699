require("../styles/_Main.scss");

import BaselineModule       from "./BaselineModule.jsx";
import AddFundsModule       from "./AddFundsModule.jsx";
import WithdrawFundsModule  from "./WithdrawFundsModule.jsx";
import AssetPurchaseModule  from "./AssetPurchaseModule.jsx";
import InflationModule      from "./InflationModule.jsx";
import AdvisorModule        from "./AdvisorModule.jsx";
//import BarChart             from "./BarChart.jsx";
import LineChart            from "./LineChart.jsx";
import HeadlineDescription  from "./HeadlineDescription.jsx";
import SaveDialog           from "./SaveDialog.jsx";
import Button               from "./Button.jsx";
import Tab                  from "./Tab.jsx";

import {roundNumber, displayFormatted} from "./Common.jsx"


class Path extends React.Component {
    
    constructor(props) {
        super(props);
        
        if (MsgToUser.indexOf('Saved version not found') == 0) {
            this.urlKey = ''
        } else {
            this.urlKey         = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        }
               
        
        this.killGhostTimeout   = 3000
        
        this.state = {
            varsJSON            : varsJSON,
            mainSeries          : [],
            showHelpDOM         : false,
            max_acct_value      : "0",
            end_acct_value      : "0",
            ghostSeries         : [],
            showGhostSeries     : true,
            killGhost           : true,
            highlightSeries     : [],
            mousedown           : false,
            keydown             : false,
            descFocused         : false,
            varsChanged         : false,
            chartWarningText    : "",
            showChartWarningText: false,
            effectChangeMax     : "",
            effectChangeEnd     : "",
            overlayGray         : false,
        };
        
        this.showHelpDom        = this.showHelpDom.bind(this);
        this.hideHelpDom        = this.hideHelpDom.bind(this);
        this.updateValues       = this.updateValues.bind(this);
        this.moveDown           = this.moveDown.bind(this);
        this.moveUp             = this.moveUp.bind(this);
        this.stepChoices        = this.stepChoices.bind(this);
        this.toggleActive       = this.toggleActive.bind(this);
        this.removeModule       = this.removeModule.bind(this);
        this.updateChartSeries  = this.updateChartSeries.bind(this);
        this.killGhost          = this.killGhost.bind(this);
        this.handleUserEvents   = this.handleUserEvents.bind(this);
        this.handleTabClick     = this.handleTabClick.bind(this);
        this.convertAPRtoMonthlyRate = this.convertAPRtoMonthlyRate.bind(this);
        this.showEffects        = this.showEffects.bind(this);
        this.handleChartWarningClick = this.handleChartWarningClick.bind(this);
        this.hideChartWarning   = this.hideChartWarning.bind(this);
        this.resetVarsChanged   = this.resetVarsChanged.bind(this);
        this.setOverlay         = this.setOverlay.bind(this);
        this.clearOverlay       = this.clearOverlay.bind(this);
        this.updateBasicParams  = this.updateBasicParams.bind(this);
        this.shareData          = this.shareData.bind(this);
    }
    
    componentDidMount() {
        this.updateChartSeries();
    } 
    
    shareData(data) {
        //
    }
    
    handleTabClick(which) {
        // just add to varsJSON
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        var keysMap = [];

        Object.keys(toUpdateJSON.modules).map(function(key, index) {
        	  keysMap.push(key); 
        });
        
        var maxKey = keysMap.reduce(function(a, b) {
            return Math.max(a, b);
        });
        
        var nextKey = parseInt(maxKey) + 1;
        
        var newModule           = {};
    
        if (which           == "+ Add Funds") {
            
            newModule   =   {
                                'type'                : 'addfunds',
                                'title'               : 'Add Funds',
                                'active'              : true,
                                'minimized'           : false,
                                'addAmount'           : 200,             
                                'addMonths'           : 60,
                                'addStartAge'         : 40
                            };
           
        } else if (which    == "+ Withdraw Funds") {
            
            newModule   =   {
                                'type'                : 'withdrawfunds',
                                'title'               : 'Withdraw Funds',
                                'active'              : true,
                                'minimized'           : false,
                                'withdrawAmount'      : 400,             
                                'withdrawMonths'      : 24,
                                'withdrawStartAge'    : 44
                            };
            
        } else if (which    == "+ Asset Purchase") {
            
            newModule   =   {
                                'type'                : 'assetpurchase',
                                'title'               : 'Asset Purchase',
                                'active'              : true,
                                'minimized'           : false,
                                'agePurchase'         : 50,             
                                'assetPrice'          : 25000,
                                'payMethod'           : 'Finance the purchase',
                                'payChoices'          : ["Pay Cash", "Finance the purchase"],
                                'downPmt'             : 1000,
                                'pmtMonths'           : 60,
                                'intRate'             : 3.75,
                                'ageSell'             : 60,
                                'sellPrice'           : 11500,
                                'appDepRate'          : 15
                            };
            
        } else if (which    == "+ Adjust for Inflation") {

            newModule   =   {
                                'type'                : 'adjustInflation',
                                'title'               : 'Adjust for Inflation',
                                'active'              : true,
                                'minimized'           : false,
                                'inflationRate'       : 2.5,
                                'inflationAgeStart'   : 30,
                                'inflationAgeEnd'     : 100
                            };
                            
        } else if (which == "+ Adjust for Advisor Fees") {
            
            newModule   =   {
                                'type'                : 'adjustAdvisorFees',
                                'title'               : 'Adjust for Advisor Fees',
                                'active'              : true,
                                'minimized'           : false,
                                'feeStartAge'         : 30,
                                'feeEndAge'           : 75,
                                'feePctUnderAdvisor'  : 100,
                                'feeAnnualPct'        : 1.5,
                                'feeCarry'            : 0,
                                'feeLoad'             : 5.75,
                                'feeLoadFraction'     : 50,
                                'feeChurn'            : 30,
                                'feeReturn'           : 6.5
                            };
                            
        }
        
        toUpdateJSON.modules[ nextKey.toString() ] = newModule;
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function() {
                this.updateChartSeries();
            }
        );
        
        
        toUpdateJSON = null;
    }
    
    handleUserEvents(key, value) {
        // mousedown handles touchstart too
        // keydown
        var obj  = {}
        obj[key] = value;
        
        //console.log("1: " + this.state.mousedown, this.state.keydown)
        
        this.setState(obj, function() {
            if ( !value && this.state.killGhost) {     // either keydown or mousedown is false
                this.killGhost( this.killGhostTimeout );
            }
            
            //console.log("2: " + this.state.mousedown, this.state.keydown)
            
        });
        
        
    }
    
    convertAPRtoMonthlyRate(APR) {
        // Users enter APR, but we compound monthly.  Not as simple as r/12
        // The array adds the rates as percentages, not decimals.  i.e. 2.5, not .025
                // (1 + MPR)^12 = 1 + APR/100 
                // (1 + MPR) = (1 + APR/100)^1/12
                // MPR = (1 + APR/100)^1/12 - 1
        
        
        return (Math.pow(1 + APR/100, 1/12) - 1);   // * 100; 
    }

    killGhost(time) {
        // time milliseconds later, set ghostSeries, remove ghostPath from chart
        // fading ghostPath is done in LineChart.jsx
        // will redraw with fresh axes
        
        clearTimeout(ghostKiller);

        if ( !(this.state.mousedown || this.state.keydown) ) {
            
            ghostKiller = setTimeout(function(){ 
                // changing these states causes a chart redraw, without ghostSeries
                this.setState(
                    {
                        ghostSeries: this.state.mainSeries,     //JSON.parse(JSON.stringify(this.state.mainSeries)),
                        showGhostSeries : false,
                    }
                );
            }.bind(this), time);
            
        } else {
            //console.log("ghostseries not changed b/c key/mousedown")
        }
        
    }
    
    resetVarsChanged() {
        // called after a save
        this.setState(
            {
                varsChanged     : false
            }
        );
    }
    
    updateBasicParams(param, value) {
        // text areas controlled, so need to give them back a new value

        if (param == "headline" || param == "desc") {
            value = value.replace(/<\s?a\b[^>]*>(.*?)<\/a>/gi,"<link removed>");
            //var obj  = {}
            //obj[param] = value;
            //this.setState(obj);
        }
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        toUpdateJSON[param] = value;
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function() {
                //console.log(this.state.varsJSON)
            }
        );
        toUpdateJSON = null;
        
        
        // this.updateChartSeries();        // no need to redraw the chart for these changes
    }
    
    
    updateValues(jsonKey, valueDict) {
        // this is a synchronous setting of state/calling new chart
        // valueDict in form {moduleKey: value}
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        if (jsonKey == "base") {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON[key] = valueDict[key];
            });
        } else {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON.modules[jsonKey][key] = valueDict[key];
            });
        }
        
    
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function () {
                this.updateChartSeries();
            }
        );
        
        toUpdateJSON = null;
        /*  send function to setState to make completely sync:
            setState(function(previousState, currentProps) {
                return {myInteger: previousState.myInteger + 1};
            });
        */
    }

    stepChoices(jsonKey, moduleKey, options) {
        // steps through a list of choices, usually T/F
        options             = options           || {};
        var choices         = options.choices   || [ true, false ];
        
        var currentIndex    = choices.indexOf( this.state.varsJSON.modules[jsonKey][moduleKey] );
        var nextIndex       = currentIndex + 1;
        
        if (nextIndex >= choices.length) nextIndex = 0;

        this.updateValues(jsonKey, {[moduleKey]: choices[nextIndex]} );
    }
    
    toggleActive(jsonKey) {
        // minimized and active change together when "active" is toggled.
        // when "showEffects" is toggled, we don't change minimized
        
        this.updateValues(jsonKey, {
                "minimized" :  this.state.varsJSON.modules[jsonKey]["active"],
                "active"    :  !this.state.varsJSON.modules[jsonKey]["active"]
            } 
        );
    }

    showEffects(jsonKey, hover) {
        
        clearTimeout(ghostKiller);
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        
        if (hover) {
            // we don't change the state of the JSON, so can go back after mouseup
            // we only change the series on the chart
            
            // make deep copy, so state doesn't change while manipulating
            var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
            
            //[ mainSeries, maxAcctValue, totalPrincipalAccts ]
            
            toUpdateJSON.modules[jsonKey]["active"] = true;
            var mainSeriesList  = this.updateChartSeries( { redraw: false, modules: toUpdateJSON.modules } );
            
            toUpdateJSON.modules[jsonKey]["active"] = false;
            var ghostSeriesList = this.updateChartSeries( { redraw: false, modules: toUpdateJSON.modules } );
            
            var diffMax         = mainSeriesList[1] - ghostSeriesList[1];
            var diffEnd         = mainSeriesList[2] - ghostSeriesList[2];
            var diffMaxSymb     = (diffMax >= 0) ? "+" : "";
            var diffEndSymb     = (diffEnd >= 0) ? "+" : "";
            var formattedMax    = displayFormatted( diffMax, 3, 9999999, { maximumFractionDigits: 0 } );
            var formattedEnd    = displayFormatted( diffEnd, 3, 9999999, { maximumFractionDigits: 0 } );
            
            var effectChangeMax = "(" + diffMaxSymb + formattedMax[0] + formattedMax[1] + ")";
            var effectChangeEnd = "(" + diffEndSymb + formattedEnd[0] + formattedEnd[1] + ")";

            this.setState(
                {   
                    mainSeries      : mainSeriesList[0],
                    ghostSeries     : ghostSeriesList[0],
                    effectChangeMax : effectChangeMax,
                    effectChangeEnd : effectChangeEnd,
                    showGhostSeries : true,
                    killGhost       : false
                }
            );
            
        } else {
            
            this.setState(
                {
                    ghostSeries     : [],
                    effectChangeMax : "",
                    effectChangeEnd : "",
                    killGhost       : true
                },
                function() {
                    // redraw with previous state
                    this.updateChartSeries( { showGhostSeries: false });
                }
            );
        }
    }
    
    
    
    removeModule(jsonKey) {
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        var modules = toUpdateJSON.modules;
        
        delete modules[jsonKey];
        
        toUpdateJSON.modules = modules;
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function () {
                this.updateChartSeries();
            }
        );
        
        toUpdateJSON = null;
    }

    moveDown(currentJSONkey) {
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        var keysMap = [];

        Object.keys(toUpdateJSON.modules).map(function(key, index) {
        	  keysMap.push(key); 
        });

        var currentIndex = keysMap.indexOf(currentJSONkey);
        
        if (currentIndex == keysMap.length - 1) {
            return false;   // that's the bottom
        }

        var nextKey = Object.keys(toUpdateJSON.modules)[currentIndex + 1];

        toUpdateJSON.modules["temp"]            = toUpdateJSON.modules[currentJSONkey];
        toUpdateJSON.modules[currentJSONkey]    = toUpdateJSON.modules[nextKey];
        toUpdateJSON.modules[nextKey]           = toUpdateJSON.modules["temp"];
        delete toUpdateJSON.modules["temp"];

        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            }
        );
        toUpdateJSON = null;
    }
    
    moveUp(currentJSONkey) {
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        var keysMap = [];

        Object.keys(toUpdateJSON.modules).map(function(key, index) {
        	  keysMap.push(key); 
        });

        var currentIndex = keysMap.indexOf(currentJSONkey);
        
        if (currentIndex == 0) {
            return false;   // that's the top
        }

        var prevKey = Object.keys(toUpdateJSON.modules)[currentIndex - 1];

        toUpdateJSON.modules["temp"]            = toUpdateJSON.modules[currentJSONkey];
        toUpdateJSON.modules[currentJSONkey]    = toUpdateJSON.modules[prevKey];
        toUpdateJSON.modules[prevKey]           = toUpdateJSON.modules["temp"];
        delete toUpdateJSON.modules["temp"];

        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            }
        );
        toUpdateJSON = null;
    }

    showHelpDom() {
        this.setState(
            {
                showHelpDOM: true
            }
        );
    }
    hideHelpDom() {
        this.setState(
            {
                showHelpDOM: false
            }
        );
    }
    
    
      
    updateChartSeries(options) {
        
        options             = options                   || {};
        var showGhostSeries = (options.showGhostSeries != undefined)
                                    ? options.showGhostSeries
                                    : true;
        var redraw          = (options.redraw != undefined)      
                                    ? options.redraw
                                    : true;
        var modules         = options.modules           || this.state.varsJSON.modules;
        
        var P       = [[]];  //  Principal (Base Line asset account)  Initialize as array of arrays.
            P[0]    = [];    //  (Base Line asset account). 
            P[1]    = [];
        var CF      = [];    //  Cash Flow array
        var A       = [];    //  Age with month in fractions of year months
        var t       = [];    //  Tax rate array
        var inf     = [];    //  Inflation Array
        
        // r[]'s stored as decimal, so that P1 = P0*(1+r[n])
        var r       = [];    //  hold rates for each Principal acount (main plus asset purchases)
        var r_asset = [];    // hold rates for Asset Purchase account in separate array, as they do not change with n.
        var HS      = [];    // highlighted Age regions, per module
        
        // start with empty char wanrings:
        this.setState( {
            chartWarningText   : "",
            
        });
        
        // #############################################################
        // ####################    Baseline Module   ###################
        // #############################################################
        
        // first, get BaseLine, to set up monthly array, and get rates of return
        // taxes must be done at end, after all returns calculated
        // then inflation
        
        for (var moduleKey in modules) {
            if (modules[moduleKey].type == 'baseline') {
                var BaselineModule  = modules[moduleKey];
                
                    A[0]            = parseFloat( BaselineModule.ageStart );
                    P[0][0]         = parseFloat( BaselineModule.principalStart );
                
                var n_retire        = Math.round( ( parseFloat( BaselineModule.ageRetire )  - A[0] ) * 12);
                var n_end           = Math.round( ( parseFloat( BaselineModule.ageEnd )     - A[0] ) * 12);
            
                var P_preRetire     = parseFloat( BaselineModule.addPrincMo         );
                var P_retire        = parseFloat( BaselineModule.retireWithdraw     );
                var R_preRetire     = parseFloat( BaselineModule.preRetireRate      );
                var R_retire        = parseFloat( BaselineModule.retireRate         );
                var TaxDefpercent   = parseFloat( BaselineModule.taxDeferPct        );
                var Tax_preRetire   = parseFloat( BaselineModule.taxRatePreRetire   );
                var Tax_retire      = parseFloat( BaselineModule.taxRateRetire      );
                var Tax_withdrawals = parseFloat( BaselineModule.taxRateWithdrawals );
                
                //var afterTaxR_preRetire = this.convertAPRtoMonthlyRate( R_preRetire * (1 - Tax_preRetire/100 * (1 - TaxDefpercent/100) ) );
                //var afterTaxR_retire    = this.convertAPRtoMonthlyRate( R_retire    * (1 - Tax_retire/100    * (1 - TaxDefpercent/100) ) );

                // Calculate Base Line effects
                for (var n = 0; n <= n_end; n++) {
                    if (n <= n_retire )  {  // before and until retirement
                        // because the user later can adjust the rates during different periods of their lives, 
                        // we store rates of returns in an array r[]
                        // for now, we save r[]'s as APR, until final calc of weighted average of rates.
                        
                        r[n]    = {
                            baseline: R_preRetire,  // rates, as APR
                            others  : []
                        };      
                        
                        CF[n]   = P_preRetire;      // so we can keep track of cash flows in or out
                        t[n]    = Tax_preRetire;    // we do taxes last, so keep in separate array
                        
                    } else {                        // after retirement
                        r[n]    = {
                            baseline: R_retire,
                            others  : []
                        };        
                        CF[n]   = -P_retire;
                        t[n]    = Tax_retire;
                    }
                    A[n]        = A[0] + n/12; // array of months
                }
                if (BaselineModule.highlighted) {
                    HS.push( 
                        [
                            BaselineModule.ageStart, 
                            BaselineModule.ageEnd, 
                            "BL_highlight"
                        ] 
                    );
                }
            }
        }
        /*******************************************************************/
        /*****   So now we have the arrays built, with base values    ******/  
        /********   for every month from ageStart to ageEnd    *************/
        /*******************************************************************/
        
        var currentModule,
            AP_module_count = 0;
        
        // now go through again, for other modules
        for (moduleKey in modules) {
            
            // #############################################################
            // ##################    Add Funds Modules   ###################
            // #############################################################
            if (modules[moduleKey].type == 'addfunds') {
                currentModule   = modules[moduleKey];

                if (!currentModule.active) { continue; }
                
                var monthly_amt = parseFloat( currentModule.addAmount );
                var num_months  = parseFloat( currentModule.addMonths );
                var start_n     = Math.round( (parseFloat( currentModule.addStartAge ) - A[0]) * 12);  // we standardize on using months
    
                for (var n = start_n+1; n <= start_n + num_months; n++) {
                    CF[n] += monthly_amt;
                }  
                
                if (currentModule.highlighted) {
                    HS.push( 
                        [
                            currentModule.addStartAge, 
                            parseFloat(currentModule.addStartAge + currentModule.addMonths/12), 
                            "ADM_highlight"
                        ] 
                    );
                }
                     
            // #############################################################
            // ###################    Withdraw Modules   ###################
            // #############################################################
            } else if (modules[moduleKey].type == 'withdrawfunds') {
                currentModule   = modules[moduleKey];

                if (!currentModule.active) { continue; }
                
                var monthly_amt = parseFloat( currentModule.withdrawAmount );
                var num_months  = parseFloat( currentModule.withdrawMonths );
                var start_n     = Math.round( (parseFloat( currentModule.withdrawStartAge ) - A[0]) * 12); 

                for (var n = start_n+1; n <= start_n + num_months; n++) {
                    CF[n] -= monthly_amt;
                }
                
                if (currentModule.highlighted) {
                    HS.push( 
                        [
                            currentModule.withdrawStartAge, 
                            parseFloat(currentModule.withdrawStartAge + currentModule.withdrawMonths/12), 
                            "WDM_highlight"
                        ] 
                    );
                }

            
            // #############################################################
            // ################   Asset Purchase Modules   #################
            // #############################################################
            } else if (modules[moduleKey].type == 'assetpurchase') {
                currentModule   = modules[moduleKey];

                if (!currentModule.active) { continue; }
                AP_module_count += 1;
                P[AP_module_count] = [];
                //var this_id = moduleKey // ???
                
                var purchase_n  = Math.round( (parseFloat( currentModule.agePurchase )  - A[0]) * 12);
                var sell_n      = Math.round( (parseFloat( currentModule.ageSell )      - A[0]) * 12);
                var n           = sell_n - purchase_n;    
        
                // In separate account, we handle asset appreciation/depreciation
                // let's find the consistent app/dep rate, not just straight-line
        
                var PV          = parseFloat( currentModule.assetPrice );
                var FV          = parseFloat( currentModule.sellPrice );
                //var mo_depreciation = (sell_price - asset_price) / num_months;
                //  FV = PV * (1 + r)^n
                var rr          = (Math.pow(FV/PV,1/n) - 1) * 1200;
        
                //r_AP[AP_module_count] = rr;   //same rate for all n


                if (currentModule.payMethod == "Pay Cash") { 
                    // we take funds out of main acct at purchase, then out them back in at sale.
                    CF[purchase_n]  -= PV;
                    CF[sell_n]      += FV;
            
                    try{
                        P[AP_module_count][purchase_n] = PV;
                    } catch(e) {
                        console.log("error at AP module: " + AP_module_count);
                    }
                    
                    // depreciated or appreciated asset value
                    for (var jj = (purchase_n + 1); jj <= sell_n; jj++) {
                        P[AP_module_count][jj] = P[AP_module_count][jj-1]  * ( 1 + rr / 1200);
                    }
                    P[AP_module_count][sell_n] = 0;

                } else if (currentModule.payMethod  == "Finance the purchase") {
                    
                    // loans use APR/n as the rate.  No compounding
                    var downPmt     = parseFloat( currentModule.downPmt );
                    var pmtMonths   = parseFloat( currentModule.pmtMonths );
                    var intRate     = parseFloat( currentModule.intRate ) / 1200;
                    var amtFinanced = PV - downPmt;
                    
                    var loan_balance    = 0;
                    var moPmt           = ( intRate == 0 ) 
                                            ? amtFinanced / pmtMonths
                                            : (intRate * amtFinanced) / (1 - Math.pow((1 + intRate), -pmtMonths)); 
            
                    // we take funds out of main acct at purchase, then out them back in at sale.
                    CF[purchase_n]                  -= downPmt;
                    P[AP_module_count][purchase_n]  = downPmt;     // no equity, except down pmt
                    var asset_value                 = PV;
                    
            
                    for (var jj = (purchase_n + 1); jj <= sell_n; jj++) {
                        
                        if (jj <= (purchase_n + pmtMonths)) {
                            CF[jj]          -= moPmt;
                            
                            var nnn         = jj - purchase_n;
                            
                            if ( intRate == 0 ) {
                                loan_balance    = amtFinanced - moPmt * nnn;
                            } else {
                                loan_balance    = amtFinanced * Math.pow((1 + intRate), nnn);
                                loan_balance    -= moPmt/(intRate) * ( Math.pow((1 + intRate), nnn) - 1);
                            }
                        }
                        
                        asset_value             *= ( 1 + rr / 1200);

                        P[AP_module_count][jj]  = asset_value - loan_balance;
                    }
            
                    CF[sell_n]                  += (asset_value - loan_balance);
                    P[AP_module_count][sell_n]  = 0;
                } else {
                    alert('pmt method not allowed');
                }
                
                if (currentModule.highlighted) {
                    HS.push( 
                        [
                            currentModule.agePurchase, 
                            currentModule.ageSell, 
                            "APM_highlight"
                        ] 
                    );
                }
                
            // #############################################################
            // ##########   Adjust for Advisor Fees Modules   ##############
            // #############################################################
            } else if (modules[moduleKey].type == 'adjustAdvisorFees') {
                currentModule   = modules[moduleKey];

                if (!currentModule.active) { continue; }
            
                var start_n         = Math.round( (parseFloat( currentModule.feeStartAge )   - A[0]) * 12);
                var end_n           = Math.round( (parseFloat( currentModule.feeEndAge )   - A[0]) * 12);
            
                var pctUnderAdvisor = parseFloat( currentModule.feePctUnderAdvisor )
            
                var advisorReturn   = parseFloat( currentModule.feeReturn );
                var annualFeeRate   = parseFloat( currentModule.feeAnnualPct );
            
                var carry           = parseFloat( currentModule.feeCarry )
                var load            = parseFloat( currentModule.feeLoad )
                var loadFraction    = parseFloat( currentModule.feeLoadFraction )
                var churn           = parseFloat( currentModule.feeChurn )
            
            
                // what if they try to open account when broke?
                // we start when they get above zero
            
                if (P[0][start_n] < 0) {
                    for (var n = start_n + 1; n <= end_n; n++) {
                        if (P[0] > 0) {
                            start_n = n;
                            continue;
                        }
                    }
                }
            
            //console.log(r[start_n])
                // handle initial load separately
                if (r[start_n] != undefined) {
                    r[start_n]["others"].push( [load/100 * loadFraction/100,  pctUnderAdvisor/100, "load"] );
                }
            
                var annualChurnLoad = churn/100 * load * loadFraction/100;  // as APR.  /1200 in convertAPRtoMonthlyRate

                var actualAdvisorReturn = (advisorReturn * (1 - carry/100) - annualFeeRate - annualChurnLoad);
                
                for (var n = start_n + 1; n <= end_n; n++) {
                    if (r[n] != undefined) {
                        r[n]["others"].push( [actualAdvisorReturn, pctUnderAdvisor/100, "rate"] );
                    }
                }
            
                if (currentModule.highlighted) {
                    HS.push( 
                        [
                            currentModule.feeStartAge, 
                            currentModule.feeEndAge, 
                            "AAF_highlight"
                        ] 
                    );
                }
                
            // #############################################################
            // #############   Adjust for Inflation Modules   ##############
            // #############################################################
            } else if (modules[moduleKey].type == 'adjustInflation') {
                
                currentModule   = modules[moduleKey];

                if (!currentModule.active) { continue; }
        
                var infl_rate   = this.convertAPRtoMonthlyRate(parseFloat( currentModule.inflationRate ));
                var start_n     = Math.round( (parseFloat( currentModule.inflationAgeStart ) - A[0]) * 12);
                var end_n       = Math.round( (parseFloat( currentModule.inflationAgeEnd )   - A[0]) * 12);
        
                // It's not simply (r[n] - infl_rate).  That is approximate for small values, but not useful
                // for our compounding. Buying power = (1 + rate) / (1 + inflation rate)
                for (var n = start_n + 1; n <= end_n; n++) {
                    inf[n] = infl_rate;     // (1 + r[n]) / (1 + infl_rate) -  1;
                }
        
                if (currentModule.highlighted) {
                    HS.push( 
                        [
                            currentModule.inflationAgeStart, 
                            currentModule.inflationAgeEnd, 
                            "AIM_highlight"
                        ] 
                    );
                }
            }
            
        }
            
        // #############################################################
        // ############   Adjust for Taxes and Inflation   #############
        // #############################################################
        
        // done separately, as taxes on weighted average of all rates, including under advisor
            
        var onMargin        = false;
        
        for (var n = 0; n <= n_end; n++) {
            if (n > 0) {
                
                var myRate          = r[n].baseline,
                    myShare         = 1.0,
                    loadAmt         = 0.0,
                    weightedAvgRate = 0.0,
                    afterTaxRate    = 1.0;
                    
                // get weighted average rate   
                    
                //console.log(myRate)             
                
                //console.log(r[n]["others"])
                    
                for (var k=0; k < r[n]["others"].length; k++) {
                    if (r[n]["others"][k][2] == "rate") {
                        
                        myShare         -= r[n]["others"][k][1];
                        weightedAvgRate += (r[n]["others"][k][0] * r[n]["others"][k][1]);
                    } else if (r[n]["others"][k][2] == "load") {
                        loadAmt         += r[n]["others"][k][2];
                    }
                }
                
                if (myShare < 0) { 
                    myShare = 0;
                    onMargin = true;
                }
                
                weightedAvgRate = weightedAvgRate + ( myRate * myShare );
                
                //console.log(weightedAvgRate)
                
                //find after tax rate
                
                r[n] = this.convertAPRtoMonthlyRate( weightedAvgRate * (1 - t[n]/100 * (1 - TaxDefpercent/100) ) );
                
                //var afterTaxR_preRetire = this.convertAPRtoMonthlyRate( R_preRetire * (1 - Tax_preRetire/100 * (1 - TaxDefpercent/100) ) );
                //var afterTaxR_retire    = this.convertAPRtoMonthlyRate( R_retire    * (1 - Tax_retire/100    * (1 - TaxDefpercent/100) ) );
                
                // adjust for inflation
                if (inf[n] != undefined) {
                    
                    //console.log(r[n], inf[n])
                    r[n] = (1 + r[n]) / (1 + inf[n]) -  1;
                }
            }
        }
        

        if (onMargin) {
            this.setState( {
                chartWarningText   : this.state.chartWarningText + "More than 100% under advisement.  Are you on margin? |",
                
            });
        } 

        
        // Arrays built.  Now prepare chart
        var mainSeries=[];
    
        var maxAcctValue = -Infinity;
    
        for (var n = 0; n <= n_end; n++) {
            if (n > 0) {
                
                if ( P[0][n-1] > 0) {
                    P[0][n] = P[0][n-1] * ( 1 + r[n] ) + CF[n];   // main Principal acct
                } else {
                    // can't earn return on negative account
                    P[0][n] = P[0][n-1] + CF[n];   // main Principal acct
                }
                
                
                //console.log(n, P[0][n])
            }
            var totalPrincipalAccts = P[0][n];
            for (var j = 1; j <= AP_module_count; j++) {        // number Accounts (assets)
                if (P[j][n] != undefined) {
                    totalPrincipalAccts += P[j][n];
                }
            }   
        
            mainSeries.push( 
                {   "Age": roundNumber(A[n], 2), 
                    "Amount": roundNumber(totalPrincipalAccts, 2) 
                }
            );

            maxAcctValue = Math.max(maxAcctValue, totalPrincipalAccts);
            //if (totalPrincipalAccts > maxAcctValue) maxAcctValue = totalPrincipalAccts;
        }
        
        //console.log(mainSeries)

        
        if (redraw) {
            
            this.setState(
                {
                    mainSeries      : mainSeries,
                    max_acct_value  : displayFormatted( maxAcctValue, 3, 9999999, { 
                                                    maximumFractionDigits: 0
                                                } ),
                    end_acct_value  : displayFormatted( totalPrincipalAccts, 3, 9999999, { 
                                                    maximumFractionDigits: 0
                                                } ),
                    showGhostSeries : showGhostSeries,
                    highlightSeries : HS,
                },
                function() {
                    if (this.state.killGhost) {
                        this.killGhost( this.killGhostTimeout );
                    }
                }
            );
            
        } else {
            return [ mainSeries, maxAcctValue, totalPrincipalAccts ]
        }

    }
    
    handleChartWarningClick() {
        this.setState( {
            showChartWarningText: true,
        });
    }
    
    hideChartWarning() {
        this.setState( {
            showChartWarningText: false,
        });
    }
    
    setOverlay() {
        this.setState(
            {
                overlayGray     : true
            }
        );
    }
    clearOverlay() {
        this.setState(
            {
                overlayGray     : false,
                varsChanged     : false
            }
        );
    }
    
    render() {
        
        var overlayClass = (this.state.overlayGray) ? "overlayGray" : "displayNone";
        var overlayDOM = <div className = {overlayClass} />
                            
        var modules     = this.state.varsJSON.modules;
        
        var currencySymbol = ""
                        
        switch (this.state.varsJSON.currencySymbol) {
            case "$":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "€":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "￥":
                currencySymbol = <span style = {{"marginRight":"0px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "£":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
        }
        
        //var stateJSON = this.state.varsJSON;
        var innerDOM = Object.keys(modules).map(function(key, index) {
            
            if (modules[key].type == "baseline") {
                
                return (
                    
                    <BaselineModule
                        key                 = {`baseline-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        handleUserEvents    = { this.handleUserEvents }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        stepChoices         = { this.stepChoices }
                        removeModule        = { this.removeModule }
                        currencySymbol      = { currencySymbol }
                        currencySymbolStr   = { this.state.varsJSON.currencySymbol }
                    />
                );
                
            } else if (modules[key].type == "addfunds") {
                return (
                    
                    <AddFundsModule
                        key                 = {`addfunds-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        stepChoices         = { this.stepChoices }
                        toggleActive        = { this.toggleActive }
                        showEffects         = { this.showEffects }
                        removeModule        = { this.removeModule }
                        handleUserEvents    = { this.handleUserEvents }
                        currencySymbol      = { currencySymbol }
                    />
                );
                
            } else if (modules[key].type == "withdrawfunds") {
                
                return ( 
                    <WithdrawFundsModule
                        key                 = {`withdraw-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        toggleActive        = { this.toggleActive }
                        showEffects         = { this.showEffects }
                        stepChoices         = { this.stepChoices }
                        removeModule        = { this.removeModule }
                        handleUserEvents    = { this.handleUserEvents }
                        currencySymbol      = { currencySymbol }
                    />
                );
                
            } else if (modules[key].type == "assetpurchase") {
                
                return (
                    <AssetPurchaseModule
                        key                 = {`assetpurchase-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        toggleActive        = { this.toggleActive }
                        showEffects         = { this.showEffects }
                        stepChoices         = { this.stepChoices }
                        removeModule        = { this.removeModule }
                        handleUserEvents    = { this.handleUserEvents }
                        currencySymbol      = { currencySymbol }
                    />
                )
                
            } else if (modules[key].type == "adjustInflation") {
                
                return (    
                    <InflationModule
                        key                 = {`inflation-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        toggleActive        = { this.toggleActive }
                        showEffects         = { this.showEffects }
                        removeModule        = { this.removeModule }
                        handleUserEvents    = { this.handleUserEvents }
                    />
                );
            } else if (modules[key].type == "adjustAdvisorFees") {
                
                return (    
                    <AdvisorModule
                        key                 = {`advisor-${key}`}
                        JSONkey             = { key }
                        module              = { modules[key] }
                        showHelpDom         = { this.showHelpDom }
                        moveDown            = { this.moveDown }
                        moveUp              = { this.moveUp }
                        updateValues        = { this.updateValues }
                        stepChoices         = { this.stepChoices }
                        toggleActive        = { this.toggleActive }
                        showEffects         = { this.showEffects }
                        removeModule        = { this.removeModule }
                        handleUserEvents    = { this.handleUserEvents }
                    />
                );
            }
                            

        }.bind(this));
        
        //console.log(this.state.saveClicked);
        
        // show warning triangle
        var chartWarningClass   = (this.state.chartWarningText != "") 
                                    ? "chartWarning"                  
                                    : "displayNone";
                                    
        var chartWarningItems    = this.state.chartWarningText.split("|").map(function(sentence, key) {
            if (sentence != "") {
                return <li key={`warn-${key}`}>{ sentence.replace("|") } </li>
            }
            
        });
                                    
        var chartWarningTextClass = (this.state.showChartWarningText)
                                    ? "width900centered"
                                    : 'displayNone';
       

        var helpDOMClassName    = (this.state.showHelpDOM)          ? 'helpDOM'             : 'displayNone'; 
        
        var helpDOM =   <div className= { helpDOMClassName }> 
                            <div className="heading_bar"> 
                                <div className="center_heading text_blue">Help</div>  
            
                                <div className="right_heading"> 
                                    <div className="icon_delete" onClick={ this.hideHelpDom }></div> 
                                </div> 
                            </div> 
                            <div> 
        
        
                            <div className="text_blue clearBoth"><div className="clearBoth">Changing Values:</div></div><br /> 
        
                            There are several ways to easily change the values:<br/><br />
        
                            <div style={{"marginLeft":"16px"}}>
        
                                1) Click and drag numerical values to change.<br />
                                2) Double-click the value to enter directly<br />
                                3) Mouse over the value, and use the arrow keys to finely adjust<br /><br />
                            </div>
        
                            Hold down the following keys to adjust the rate of change of the slider: <br/><br /> 
                            <div style={{"marginLeft":"16px"}}>
                                (.) - adjust by 0.01<br /> 
                                (0) - adjust by 0.1<br /> 
                                (2) - adjust by 10x<br /> 
                                (3) - adjust by 100x<br /> 
                                (4) - adjust by 1000x<br /> 
                                (5) - adjust by 10000x<br /> 
                                (6) - adjust by 100000x 
                            </div>
        
                            <br />
        
                            <div className="text_blue clearBoth"><div className="clearBoth">Editing Headline and Description:</div></div><br />
        
                            
                            Double-click the Title or Description to edit either. &nbsp;While editing the description, icons for text alignment become visible.<br /><br />
        
                            
                            <div className="text_blue clearBoth"><div className="clearBoth">Icons:</div></div><br /> 
                            </div> 
                            <div><div className="icon_help clearBoth"></div> - this help file</div> 
                            <div><div className="icon_minimize clearBoth"></div> - minimize module to show just header (toggle to display again)</div> 
                            <div><div className="icon_down clearBoth"></div> - move module down.  (shift-click to move all the way to bottom)</div> 
                            <div><div className="icon_up clearBoth"></div>  - move module up.  (shift-click to move all the way to top)</div> 
                            <div><div className="icon_highlight clearBoth"></div> - highlight the date region of this module</div> 
                            <div><div className="icon_toggle clearBoth"></div> - mouse over to show effects of module</div> 
                            <div><div className="icon_deactivate"></div> - deactivate this module, without deleting</div>
                            <div><div className="icon_delete clearBoth"></div> - delete module</div> 
                            <div>
                            <br />
        
                            <div>
                                <div className="chartWarningHelp" style={{"top":"5px"}}></div> 
                                <div className="vertAlignMiddle inlineBlock">
                                    &nbsp; &nbsp;If this symbol appears at the bottom-right of the chart, click it to check for warnings.
                                </div>
                            
                            </div>
                            <br /><br />
        
                                <div className="textAlignCenter">
                                    Questions or comments? &nbsp; Please <a href="mailto:plan@dynamicsofwealth.com">email Us</a>.<br /><br /> 
                                    <Button     
                                            text            = "Close"
                                            clickHandler    = { this.hideHelpDom }
                                            className       = "blue_button"
                                    />
                                </div>
                                <br /> <br /> 
                            </div> 
                                            
                            
                            
                        </div>;
                
      
        return (
            <div>
                { overlayDOM }
                { helpDOM }
                
                <HeadlineDescription
                    varsJSON            = { this.state.varsJSON }
                    updateBasicParams   = { this.updateBasicParams }
                />
                

                <div id="chart1">
                    <LineChart
                        mainSeries      = { this.state.mainSeries }
                        height          = "400"
                        width           = "900"
                        xDataLabel      = { 
                            {
                                name        : "Age",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 0
                            } 
                        }
                        yDataLabel      = { 
                             {
                                name        : "Amount",
                                prefix      : this.state.varsJSON.currencySymbol,
                                suffix      : "",
                                decimals    : 0
                             }
                        }
                        ghostSeries     = { this.state.ghostSeries }
                        showGhostSeries = { this.state.showGhostSeries }
                        killGhost       = { this.state.killGhost }
                        mousedown       = { this.state.mousedown }
                        keydown         = { this.state.keydown }
                        highlightSeries = { this.state.highlightSeries }
                        shareData       = { this.shareData }
                    />
                        
                </div>
                <div id="max_ending_values">
                    <div id="max_acct_value">Max Account Value:  { currencySymbol }<span style={{"textAlign": "right"}}>
                        { this.state.max_acct_value}  {this.state.effectChangeMax }</span></div>
                    <div id="end_acct_value">End Account Value:  { currencySymbol }<span style={{"textAlign": "right"}}>
                        { this.state.end_acct_value}  {this.state.effectChangeEnd }</span></div>
                </div>
                        
                <div    className       = { chartWarningClass }
                        onClick         = { this.handleChartWarningClick }
                />
                      
                <div className = { chartWarningTextClass }>
                    <div className= "passwordHelpDOM chartErrorDOM"> 
                        <div className="heading_bar"> 
                            <div className="center_heading text_blue">Please double-check these items:</div>  
                            <div className="right_heading"> 
                                <div className="icon_delete" onClick={ this.hideChartWarning }></div> 
                            </div> 
                        </div> 
                        <div className = "passwordHelpText width900"> 
                            <ul>
                                { chartWarningItems }
                            </ul>
                        </div>
                        
                        <div className="textAlignCenter">
                    
                            <Button     
                                    text            = "Close"
                                    clickHandler    = { this.hideChartWarning }
                                    className       = "blue_button"
                            />
                        </div>

                    </div>
                </div>

                <div>
                    <div id="outer_canvas">

                                    { innerDOM }
                           
                    </div>{/* end outer_canvas */}
                    
                    <div id="add_module_tabs_container">
                        <div id="temp"></div>
                        <Tab
                            title           = "+ Add Funds"
                            className       = "add_module_tab_ADM"
                            handleTabClick  = { this.handleTabClick }
                        />
                        <Tab
                            title           = "+ Withdraw Funds"
                            className       = "add_module_tab_WDM"
                            handleTabClick  = { this.handleTabClick }
                        />
                        <Tab
                            title           = "+ Asset Purchase"
                            className       = "add_module_tab_APM"
                            handleTabClick  = { this.handleTabClick }
                        />
                        <Tab
                            title           = "+ Adjust for Inflation"
                            className       = "add_module_tab_AIM"
                            handleTabClick  = { this.handleTabClick }
                        />
                        <Tab
                            title           = "+ Adjust for Advisor Fees"
                            className       = "add_module_tab_AAF"
                            handleTabClick  = { this.handleTabClick }
                        />
                        <div className="clearBoth" />
                    </div> {/* end tabs container */}
                </div>
                                              
                <SaveDialog
                    varsJSON            = { this.state.varsJSON }
                    varsChanged         = { this.state.varsChanged }
                    resetVarsChanged    = { this.resetVarsChanged }
                    thisDir             = "/path/"
                    thisKey             = { this.urlKey }
                    submitUrl           = "/save_path/"
                    messages            = {{
                                                saveExisting    : "Save This Path",
                                                saveAsNew       : "Save As New Path",
                                          }}
                    setOverlay          = { this.setOverlay }
                    clearOverlay        = { this.clearOverlay }
                />
                                       
            </div>

      )            
  }
}


export default Path;
