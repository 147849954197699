import { max, scaleBand, scaleLinear, scaleOrdinal, axisBottom, axisLeft } from 'd3';
import { select, selectAll } from "d3-selection";

const d3 = Object.assign(
  {},
  { select, selectAll, max, scaleBand, scaleLinear, scaleOrdinal, axisBottom, axisLeft}, //request
)

import {appendArray} from "./Common.jsx";
 
class HorzStackedBarChart extends React.Component {
    
    constructor(props) {
        super(props);
            
        this.ref            = React.createRef();
        this.draw           = this.draw.bind(this);

        // Margin is for chart and Axes
        // labelPadding lets us control the location of Axis Labels
        this.margin         = this.props.margin                     || {top: 8, right: 0, bottom: 10, left: 0};
        this.labelPadding   = this.props.labelPadding               || {top: 10, right: 0, bottom: 10, left: 0};
        //this.axisClasses    = this.props.axisClasses              || ["xAxis", "yAxis"];
        this.DOMwidth        = this.props.width                     || 400,
        this.DOMheight       = this.props.height                    || 60;
        this.numBars         = Object.keys(this.props.data[0]).length;
        this.barHeight       = parseInt(this.props.barHeight)       || 9;
        this.titleWidth      = parseInt(this.props.xDataLabel.titleWidth);
        this.legendFontSize  = parseInt(this.props.legendFontSize)  || 11;
        this.legendRectSize  = parseInt(this.props.legendRectSize)  || 10;
        this.legendMargin    = parseInt(this.props.legendMargin)    || 5;
        
        this.chart           = {
            top         : this.labelPadding.top + this.margin.top,
            bottom      : this.props.height - (this.labelPadding.bottom + this.margin.bottom),
            left        : this.labelPadding.left + this.margin.left,
            right       : this.props.width - (this.labelPadding.right + this.margin.right),
            width       : this.props.width - this.labelPadding.left - this.labelPadding.right - this.margin.left - this.margin.right,
            height      : this.props.height - this.labelPadding.top - this.labelPadding.bottom - this.margin.top - this.margin.bottom
        }; 
        
        this.x = d3.scaleLinear()
                .rangeRound([0, this.chart.width]);

        this.y = d3.scaleBand()
                .rangeRound([0, this.barHeight])
                .paddingInner(0.05)
                .align(0.1);

        this.z = d3.scaleOrdinal()
                .range(this.props.colors);

    }
    
    componentDidMount() {
        this.svgParent = d3.select(this.ref.current)
            .append("svg")
            .attr("class", "stackedBarChart")
            .attr("width",  this.DOMwidth)
            .attr("height", this.DOMheight)
            .style("margin-top", this.margin.top);
    }
    
    componentDidUpdate() {
        this.svgParent.selectAll(".g").remove();
        this.draw();
    }
    
    draw() {
        //z.domain(d3.keys(data[0]).filter(function (key) { return key !== "row"; }));
        this.z.domain(Object.keys(this.props.data[0]).filter(function (key) { return key !== "row"; }));
        
        this.props.data.forEach(function(d) {
            var x0 = 0;
            d.vals = this.z.domain().map(function(name) { 
                return {
                    name    : name, 
                    x0      : x0, 
                    x1      : x0 += d[name],
                    val     : d[name]
                }; 
            });
            d.total = d.vals[d.vals.length - 1].x1;
        }.bind(this));
        
        this.props.data.sort(function(a, b) { return b.total - a.total; });
        
        this.x.domain([0, d3.max(this.props.data, function(d) { return d.total; })]);
        this.y.domain(this.props.data.map(function(d) { return d.row; }));

        var svg = this.svgParent;
                
        // create row group
        var row = svg.selectAll(".row")
            .data(this.props.data)
            .enter().append("g")
            .attr("class", "g");
            
            // draw legend title
            row.append("text")
                .attr("transform", "translate(-16,0)")
                .attr("dx", "16")
                .style("font-size", this.legendFontSize + "px")
                .text( this.props.xDataLabel.title );
            
        var rects = row.selectAll("rect")
            .data(function (d,i) { return d.vals; })
            .enter()
            
            // draw full color facial rectangles
            rects.append("rect")
                .attr("transform", function (d, i) { return "translate(0," + (this.legendFontSize - this.barHeight + this.legendMargin) + ")"; }.bind(this))   // move to bottom of element
                .attr("height", this.y.bandwidth())
                .attr("x",      function (d) { return this.x(d.x0); }.bind(this))
                .attr("width",  function (d) { return this.x(d.x1) - this.x(d.x0); }.bind(this))
                .style("fill",  function (d) { return this.z(d.name); }.bind(this));
                
            // draw legend text
            rects.append("text")
                .attr("transform", function (d, j) { return "translate(" + (this.titleWidth + j * (this.chart.width - this.titleWidth)/this.numBars) + ",0)"; }.bind(this))
                .attr("dx", "16")
                .style("font-size",this.legendFontSize + "px")   
                .text(function (d) { return d.name + ": " + this.props.xDataLabel["prefix"] + d.val + this.props.xDataLabel["suffix"]; }.bind(this));
            
            // draw legend rectangles
            rects.append("rect")
                .data(this.z.domain().slice())
                .attr("transform", function (d, i) { return "translate(" + (this.titleWidth + i * (this.chart.width - this.titleWidth)/this.numBars) + ",-" + (this.legendRectSize - 1) + ")"; }.bind(this))
                .attr("width", this.legendRectSize)
                .attr("height", this.legendRectSize)
                .style("fill", this.z)
    }
    
    render() {
        return (
            <div className  = {"visible"} 
                 ref        = { this.ref } 
            />
        );
    }
}

export default HorzStackedBarChart;
