require("../styles/_Main.scss");

class Home extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
        };
        
       
    }
    
    componentDidMount() {
    } 
    

    render() {
        
        return (
            <div id="outer_canvas">
                <div className="homeTemp1">
                	<div className="homeTemp2">
	
                	</div>
                	<div style={{"position":"relative", "color":"#fff "}}>
                			<span style={{"textDecoration":"underline"}}>Coming Soon.</span>
                	</div>
                </div>

                <div className="homeTemp3">
	
	
                	<br />
                	What is <span className="largeCursive">Wealth?</span> &nbsp; When thinking about <span className="largeCursive">wealth</span>, many people think about the acquisition or accumulation of <span className="largeCursive">"things"</span>:  fancy <span className="largeCursive">cars</span>, estates, expensive <span className="largeCursive">jewelry</span>, designer items. &nbsp; On the contrary!  <span className="largeCursive">Wealth</span> is a much <span className="largeCursive">deeper</span> concept. 
                	<br /><br />
                	<span className="largeCursive">Wealth</span> is about <span className="largeCursive">freedom</span> and <span className="largeCursive">security</span>. &nbsp; <span className="largeCursive">Wealth</span> is empowering. &nbsp; Dynamics of Wealth is here to <span className="largeCursive">illuminate</span> the path to help you discover a <span className="largeCursive">stable</span> foundation, a <span className="largeCursive">peaceful</span> coalescence, a <span className="largeCursive">harmonious</span> life.
                </div>
            </div>

      )            
  }
}


export default Home;
