require("../styles/_Main.scss");

import Button from "./Button.jsx";

class SaveDialog extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.urlKey         = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] ;
        
        this.state = {
            showPasswordHelpDOM : false,
            saveAsNewMsg        : this.props.messages.saveAsNew,
            saveExistingMsg     : this.props.messages.saveExisting,
            saveClicked         : "",
            errorText           : "",
            afterSaveMsg        : "",
        };
        
        this.handleSave         = this.handleSave.bind(this);
        this.showPasswordHelpDom= this.showPasswordHelpDom.bind(this);
        this.hidePasswordHelpDom= this.hidePasswordHelpDom.bind(this);
        this.gotoNewUrl         = this.gotoNewUrl.bind(this);
        this.continueEditing    = this.continueEditing.bind(this);
    }
    
    componentDidMount() {

    }
    
    handleSubmit() {
        
        var password =          this.refs.password.value;
        
        if (!password) {
            this.setState(
                {
                                errorText       : "Simple password required to save Path.",
                                saveAsNewMsg    : this.props.messages.saveAsNew,
                                saveExistingMsg : this.props.messages.saveExisting,
                }
            )
            return;
        }
        
        var thisKey = (this.state.saveClicked == "saveNew") ? "new" : this.props.thisKey;
        
        //console.log(password, thisKey, this.props.varsJSON, JSON.stringify(this.props.varsJSON) )
        
        var submitUrl = this.props.submitUrl;
        
        var formData = new FormData();
            formData.append("password",     password);
            formData.append("thisKey",      thisKey);
            formData.append("varsJSON",     JSON.stringify(this.props.varsJSON));
            
            var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    
                    console.log(ajaxReq.responseText)
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    
                    console.log(responseJSON) ;
                    
                    if ( responseJSON["msg"] == "success" ) { 
                        
                        this.props.resetVarsChanged();  // back to normal unchanged state
                        
                        if ( thisKey == "new") {
                            this.setState( 
                                {
                                    saveAsNewMsg        : "saved",
                                    saveExistingMsg     : this.props.messages.saveExisting,
                                    saveClicked         : "",
                                    errorText           : "",
                                    afterSaveMsg        : responseJSON["line1"],
                                    newUrl              : responseJSON["newUrl"],
                                }
                            );
                            
                            this.props.setOverlay();
                            
                        } else {
                            this.setState( 
                                {
                                    saveAsNewMsg        : this.props.messages.saveAsNew,
                                    saveExistingMsg     : "saved",
                                    saveClicked         : "",
                                    errorText           : "",
                                    //afterSaveMsg            : responseJSON["thisKey"],
                                }
                            );
                        }

                    } else {    // save error
                        this.setState( 
                            {
                                errorText       : responseJSON["msg"],
                                saveAsNewMsg    : this.props.messages.saveAsNew,
                                saveExistingMsg : this.props.messages.saveExisting,
                            }
                        );
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error(submitUrl, status, err.toString());
                }.bind(this), 
                false
            );
            //console.log(csrfToken);
            ajaxReq.open( "post", submitUrl, true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            ajaxReq.send(formData);
        return;
    }
    
    handleSave(whichClicked) {
        if (whichClicked == "cancel") {
            this.setState(
                {
                    saveClicked     : "",
                    errorText       : "",
                    saveAsNewMsg    : this.props.messages.saveAsNew,    // do ewe change these?
                    saveExistingMsg : this.props.messages.saveExisting
                }
            );
            return;
        }
        if (this.state.saveClicked == "") {
            // first click, ask for password
            this.setState(
                {
                    saveClicked     : whichClicked,
                    errorText       : "",
                    saveAsNewMsg    : this.props.messages.saveAsNew,
                    saveExistingMsg : this.props.messages.saveExisting
                }
            );
        } else if (whichClicked == "saveNew") {
            
            this.setState(
                {
                    saveAsNewMsg    : "Saving",
                },
                function() {
                    this.handleSubmit();
                }  
            );
            

        } else {    // save existing
            
            this.setState(
                {
                    saveExistingMsg  : "Saving",
                },
                function() {
                    this.handleSubmit();
                }
            );
        }
        return;
    }
    
    showPasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: true
            }
        );
    }
    
    hidePasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: false
            }
        );
    }
    
    gotoNewUrl() {
        window.location.href = this.state.newUrl;
    }
    
    continueEditing() {
        this.props.clearOverlay();
        this.setState(
            {
            saveAsNewMsg        : this.props.messages.saveAsNew,
            saveExistingMsg     : this.props.messages.saveExisting,
            saveClicked         : "",
            errorText           : "",
            afterSaveMsg        : "",
            }
        );
    }
    

    render() {
        
        //var passwordHelpDOMClassName = (this.state.showPasswordHelpDOM)  ? 'passwordHelpDOM' : 'displayNone'; 
        
        var passwordHelpDOM =   <div className= "passwordHelpDOM"> 
                                    <div className="heading_bar"> 
                                        <div className="left_heading text_blue">Why is a Password Needed?</div>  
                                        <div className="right_heading"> 
                                            <div className="icon_delete" onClick={ this.hidePasswordHelpDom }></div> 
                                        </div> 
                                    </div> 
                                    <div className = "passwordHelpText"> 
        We encourage users to store their data anonymously. &nbsp;We collect no personal identification: &nbsp;No name, no username, no email address. &nbsp;To allow a user to update their stored data in the future, we give the option of password-protecting the data. &nbsp;This allows the user to share this screen, and not have the sharee alter the stored parameters. &nbsp;Of course, the sharee can tinker with the parameters, but without the password, they will not be able to alter the stored data. &nbsp;(They can always save as a new data set of their own, if they wish.)
        <br /><br />
        
        After you save this screen, make sure you record the URL and password, so you can update in the future!
                                    </div>
                                    <Button     
                                            text            = "Close"
                                            clickHandler    = { this.hidePasswordHelpDom }
                                            className       = "blue_button"
                                    />

                                </div>;
        
                                
        var saveErrorDOM    =   <div className = "saveErrorDiv">
                                    { this.state.errorText }
                                </div>;
                                    
        var saveDOM  =  (this.state.afterSaveMsg != "")
                            ? // just saved.  Show msg
                                <div className = "pathSaveDialog passwordHelpDOM inlineBlock userSelect">
                                    <div className = "padding20">
                                
                                        <div>
                                            { this.state.afterSaveMsg }
                                        </div>
                                        <br />
                                    
                                        <div>
                                            <a href={ this.state.newUrl }>
                                                { this.state.newUrl }
                                            </a>
                                        </div>
                                        <br />
                                        
                                        <div>
                                            Make sure you record the URL and password, so you can edit in the future!
                                        </div>
                                        
                                        <br />
                                    
                                        <div>
                                                
                                            <Button
                                                    text            = "Take me there"
                                                    clickHandler    = { this.gotoNewUrl }
                                                    whichClicked    = "saveNew"
                                                    className       = "save_button"
                                            />
                                                
                                            <Button
                                                    text            = "Continue editing unsaved version"
                                                    clickHandler    = { this.continueEditing }
                                                    whichClicked    = "saveNew"
                                                    className       = "save_button"
                                            />
    
                                        </div>
                                    </div>
           
                                </div>
                                
                            : (this.state.showPasswordHelpDOM)
                                ? // clicked password help. Replace save dialog with password help screen
                                <div className = "pathSaveDialog">
                                
                                    { passwordHelpDOM }
           
                                </div>
                              
                                : // password help not clicked, show buttons as needed
                                  (this.state.saveClicked == "" || this.state.saveClicked == undefined) 
                                
                                    ?   // ↓↓↓↓↓↓↓↓ no Save button yet clicked, only offer Save dialog if there are changes ↓↓↓↓↓↓↓↓
                                        ( this.props.thisKey == "" || this.props.thisKey == undefined) 

                                            ?   // ↓↓↓↓↓↓↓↓ No Buttons clicked, still at generic url /path/ ↓↓↓↓↓↓↓↓
                                                ( this.props.varsChanged )  
                                            
                                                    ?   // ↓↓↓↓↓↓↓↓ made changes to generic path ↓↓↓↓↓↓↓↓
                                                        <div className = "pathSaveDialog">
                                                    
                                                            <Button
                                                                    text            = { this.state.saveAsNewMsg }
                                                                    clickHandler    = { this.handleSave }
                                                                    whichClicked    = "saveNew"
                                                                    className       = "save_button"
                                                            />
                                                        </div>
                                                            
                                                    :   // ↓↓↓↓↓↓↓↓ no changes from generic.  Don't offer to save ↓↓↓↓↓↓↓↓
                                                        <div className = "pathSaveDialog" />    
                                
                                
                                            :   // ↓↓↓↓↓↓↓↓ No Buttons clicked, at custom  url /path/1234 ↓↓↓↓↓↓↓↓
                                                ( this.props.varsChanged )
                                                              
                                                    ?   // ↓↓↓↓↓↓↓↓ custom path and made changes ↓↓↓↓↓↓↓↓
                                                        <div className = "pathSaveDialog">
                                                    
                                                            <Button
                                                                    text            = { this.props.messages.saveExisting }
                                                                    clickHandler    = { this.handleSave }
                                                                    whichClicked    = "reSaveExisting"
                                                                    className       = "save_button"
                                                            />
                                                            <Button
                                                                    text            = { this.props.messages.saveAsNew }
                                                                    clickHandler    = { this.handleSave }
                                                                    whichClicked    = "saveNew"
                                                                    className       = "save_button"
                                                            />
                                                        </div>
                                                        
                                                    :   // ↓↓↓↓↓↓↓↓ Custom path url, no changes ↓↓↓↓↓↓↓↓
                                                        <div className = "pathSaveDialog">
                                                    
                                                            <Button     // maybe don't show in this case?
                                                                    text            = "Saved"
                                                                    clickHandler    = { function() { return; } }
                                                                    className       = "save_button"
                                                            />
                                                            <Button
                                                                    text            = { this.props.messages.saveAsNew }
                                                                    clickHandler    = { this.handleSave }
                                                                    whichClicked    = "saveNew"
                                                                    style           = {{"verticalAlign":"middle"}}
                                                                    className       = "save_button"
                                                            />
                                                        </div>

                                    :   // ↓↓↓↓↓↓↓↓ Clicked on Save Existing or Save New ↓↓↓↓↓↓↓↓
                                        (this.state.saveClicked == "reSaveExisting") 
                                   
                                            ?   // ↓↓↓↓↓↓↓↓ Clicked on ReSave Existing Path ↓↓↓↓↓↓↓↓
                                                <div className = "pathSaveDialog">

                                                    <div className="savePWcentering">
                                                        Enter original password to allow new changes:
                                                        <div className="savePWcenterInner">
                                                            <input
                                                                ref         = "password"
                                                                type        = "text"
                                                                className   = "saveInput"
                                                            />
                                                        <br/>
                                                        <a  href            = "javasript:void[0]" 
                                                            onClick         = { this.showPasswordHelpDom } 
                                                            style           = {{"fontSize":"10px"}}
                                                        >       why?
                                                        </a>
                                                        </div>
                                                    </div>
                                                    <Button     // resave Existing button
                                                            text                = { this.state.saveExistingMsg }
                                                            clickHandler        = { this.handleSave }
                                                            whichClicked        = "reSaveExisting"
                                                            className           = "save_button"
                                                    />
                                                    <Button     // cancel button
                                                            text            = "Cancel"
                                                            clickHandler    = { this.handleSave }
                                                            className       = "cancel_button"
                                                            whichClicked    = "cancel"
                                                    />
                                                    
                                                </div>
                                
                                
                                            :   // ↓↓↓↓↓↓↓↓ Clicked on Save New button ↓↓↓↓↓↓↓↓
                                                <div className = "pathSaveDialog">
                                            
                                                    <div className="savePWcentering">
                                                        Enter simple password to allow future changes:
                                                        <div className="savePWcenterInner">
                                                            <input
                                                                ref         = "password"
                                                                type        = "text"
                                                                className   = "saveInput"
                                                            />
                                                        <br/>
                                                        <a  href            = "javasript:void[0]"
                                                            onClick         = { this.showPasswordHelpDom } 
                                                            style           = {{"fontSize":"10px"}}> 
                                                                why?
                                                        </a>
                                                        </div>
                                                    </div>
                                                    
                                                    <Button     // Save New button
                                                            text                = { this.state.saveAsNewMsg }
                                                            clickHandler        = { this.handleSave }
                                                            whichClicked        = "saveNew"
                                                            className           = "save_button"
                                                    />
                                                    <Button     // cancel button
                                                            text            = "Cancel"
                                                            clickHandler    = { this.handleSave }
                                                            className       = "cancel_button"
                                                            whichClicked    = "cancel"
                                                    />
                                                    
                                                </div>
                               

        return (
            <div className="textAlignCenter">
                { saveDOM }
                { saveErrorDOM }
            </div>
        );
    }
}


export default SaveDialog;
