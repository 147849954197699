require("../styles/_Main.scss");

import { NavLink } from 'react-router-dom'

class Tab extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div    className={ this.props.className }
                    onClick = { function() { this.props.handleTabClick( this.props.title ); }.bind(this) }
            >
                <NavLink to="#" onClick={e => e.preventDefault()}>{ this.props.title }</NavLink>
            </div>
        )

        
    }
}

export default Tab;
