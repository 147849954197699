import { max, scaleBand, scaleLinear, scaleOrdinal, axisBottom, axisLeft } from 'd3';
import { select, selectAll } from "d3-selection";

const d3 = Object.assign(
  {},
  { select, selectAll, max, scaleBand, scaleLinear, scaleOrdinal, axisBottom, axisLeft}, //request
)

import {appendArray} from "./Common.jsx";
 
class StackedBarChart extends React.Component {
    
    constructor(props) {
        super(props);
            
        this.ref            = React.createRef();
        this.draw           = this.draw.bind(this);

        // Margin is for chart and Axes
        // labelPadding lets us control the location of Axis Labels
        this.margin         = this.props.margin         || {top: 20, right: 20, bottom: 20, left: 40};
        this.labelPadding   = this.props.labelPadding   || {top: 0, right: 18, bottom: 18, left: 18};
        this.axisClasses    = this.props.axisClasses    || ["xAxis", "yAxis"];
    
        this.chart          = {
            top         : this.labelPadding.top + this.margin.top,
            bottom      : this.props.height - (this.labelPadding.bottom + this.margin.bottom),
            left        : this.labelPadding.left + this.margin.left,
            right       : this.props.width - (this.labelPadding.right + this.margin.right),
            width       : this.props.width - this.labelPadding.left - this.labelPadding.right - this.margin.left - this.margin.right,
            height      : this.props.height - this.labelPadding.top - this.labelPadding.bottom - this.margin.top - this.margin.bottom
        }; 
        
        this.x = d3.scaleBand()
            .rangeRound([0, this.chart.width/4])
            .paddingInner(0.05)
            .align(0.1);

        this.y = d3.scaleLinear()
            .rangeRound([this.chart.height, 0]);

        this.z = d3.scaleOrdinal()
            .range(this.props.colors);
        
        //console.log(this.x, this.y, this.z)
    }
    
    componentDidMount() {
        this.svgParent = d3.select(this.ref.current)
            .append("svg")
            .attr("class", "stackedBarChart")
            .attr("width",  this.props.width)
            .attr("height", this.props.height);
    }
    
    componentDidUpdate() {
        this.svgParent.selectAll(".g").remove();
        this.draw();
    }
    
    draw() {

        this.z.domain(Object.keys(this.props.data[0]).filter(function (key) { return key !== "column"; }));

        this.props.data.forEach(function(d) {
            var y0 = 0;
            d.vals = this.z.domain().map(function(name) { return {name: name, y0: y0, y1: y0 += +d[name]}; });
            d.total = d.vals[d.vals.length - 1].y1;
        }.bind(this));

        this.props.data.sort(function(a, b) { return b.total - a.total; });


        this.x.domain(this.props.data.map(function(d) { return d.column; }));
        this.y.domain([0, d3.max(this.props.data, function(d) { return d.total; })]);
        
        var svg = this.svgParent;
        
        // create X axis
        if (this.axisClasses[0] != 'displayNone') {
            svg.append("g")
                .attr("class", this.axisClasses[0])
                .attr("transform", "translate(0," + this.chart.height + ")")
                .call(d3.axisBottom(this.x));
        }
        
        // create Y axis
        if (this.axisClasses[1] != 'displayNone') {
            svg.append("g")
                .attr("class", this.axisClasses[1])
                .call(d3.axisLeft(this.y))
                .append("text")
                    .attr("transform", "rotate(-90)")
                    .attr("y", 6)
                    .attr("dy", ".71em")
                    .style("text-anchor", "end")
                    .text("X");
        }
        // create column group
        var column = svg.selectAll(".column")
            .data(this.props.data)
            .enter().append("g")
            .attr("class", "g")
            .attr("transform", "translate(" + this.chart.width/2.25+ ",0)");

        
        var rects = column.selectAll("rect")
            .data(function (d) { return d.vals; })
            .enter()
        
            // first draw faux 3D side rectangles
            rects.append("rect")
                .attr("width", this.x.bandwidth()/6)
                .attr("x", -this.x.bandwidth()/6)
                .attr("y", function (d) { return this.y(d.y1); }.bind(this))
                .attr("height", function (d) { return this.y(d.y0) - this.y(d.y1); }.bind(this))
                .style("fill", function (d) { return this.z(d.name); }.bind(this))
                .style("fill-opacity", "0.7");   
        
            // draw full color facial rectangles
            rects.append("rect")
                .attr("width", this.x.bandwidth())
                .attr("y", function (d) { return this.y(d.y1); }.bind(this))
                .attr("height", function (d) { return this.y(d.y0) - this.y(d.y1); }.bind(this))
                .style("fill", function (d) { return this.z(d.name); }.bind(this));
        
        // add text over facial rectangles
        column.selectAll("text")
            .data(function (d) { return d.vals; })
            .enter().append("text")
                .attr("x", this.x.bandwidth()/2)
                .attr("y", function (d) { return this.y( d.y0 + (d.y1 - d.y0) / 2 ); }.bind(this))
                .attr("height", function (d) { return this.y(d.y0) - this.y(d.y1); }.bind(this))
                .style("fill", "white")
                .style("text-anchor", "middle")
                .text(function (d) { return d.y1 - d.y0; });
 
        // create legend
        var legend = svg.selectAll(".legend")
            .data(this.z.domain().slice().reverse())
            .enter().append("g")
            .attr("class",  "legend")
            .attr("transform", function (d, i) { return "translate(0," + i * 22 + ")"; });

            // draw legend rectangles
            legend.append("rect")
                .attr("x", this.chart.width)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill", this.z);
        
            // add legend text
            legend.append("text")
                .attr("x", this.chart.width - 6)
                .attr("y", 9)
                .attr("dy", ".35em")
                .style("text-anchor", "end")
                .text(function (d) { return d; });
    }
    
    render() {
        return (
            <div className  = {"visible"} 
                 ref        = { this.ref } 
            />
        );
    }
}

export default StackedBarChart;
