require("../styles/_Main.scss");
    
import BarChart             from "./BarChart.jsx";
import HorzStackedBarChart  from "./HorzStackedBarChart.jsx";
import LineChart            from "./LineChart.jsx";
//import Button             from "./Button.jsx";
import Slider               from "./Slider.jsx";
import TextSlider           from "./TextSlider.jsx";
import TextToggle           from "./TextToggle.jsx";

import {roundNumber, displayFormatted} from "./Common.jsx"


class IncInequal extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            varsJSON            : {
                xMin            : -10,
                xMax            : 200,
                yMin            : 0.0,
                yMax            : 3.0,
                year            : "2020",
                inflation       : "Not adjusted for inflation",
                middleClass     : "Not highlighting median & middle class"
            },
            mainSeries          : hhiJSON["2020"]["income"],
            visibleMainSeries   : hhiJSON["2020"]["income"],
            hhAgeSeries         : hhiJSON["2020"]["age"],
            numFamSeries        : hhiJSON["2020"]["families"],
            subfamSeries        : hhiJSON["2020"]["subfamilies"],
            mothersSeries       : hhiJSON["2020"]["mothers"],
            fathersSeries       : hhiJSON["2020"]["fathers"],
            dispSeries          : hhiJSON["2020"]["disposition"],
            showMiddleClass     : false,
            highlightSeries     : [],
            medianSeries        : [],
            mousedown           : false,
            keydown             : false,
            descFocused         : false,
            killGhost           : false,
            giniSeries          : [],
            lorenzSeries        : [],
            giniCoefficient     : 0.0,
            numBars             : 0
        };

        this.yearChoices        = ['1980','1990','2000','2001','2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020'];
        this.inflationChoices   = [ "Not adjusted for inflation", "In 1980 dollars, using CPI as a deflator", "In 1980 dollars, using the PCE deflator" ];
        this.middleClassCases   = [ "Highlighting median & middle class", "Not highlighting median & middle class"];
        
        this.handleUserEvents   = this.handleUserEvents.bind(this);
        this.updateValues       = this.updateValues.bind(this);
        this.createGiniSeries   = this.createGiniSeries.bind(this);
        this.adjustInflation    = this.adjustInflation.bind(this);
        this.calcNumBars        = this.calcNumBars.bind(this);
    }
    
    componentDidMount() {
        //this.adjustInflation(this.state.mainSeries, this.state.varsJSON.inflation )
        this.createGiniSeries();
    } 
    
    createGiniSeries() {
        // also creates visible series
        // also highlights middleClass, if opted
        var totalIncome     = 0, // approx weighted by %*100 / 1000 / total pop
            i               = 0,
            mainSeries      = this.state.mainSeries;
            
        for (i=0; i < mainSeries.length; i++) {
            totalIncome += mainSeries[i]["%"] / 100 * mainSeries[i]["$"];
        }
        
        var giniSeries      = [{a:0, b:0}];
        var lorenzSeries    = [{a:0, b:0}];
        var highlightSeries = [];
        var medianSeries    = [];
        var giniIncome      = 0.0,
            lorenzIncome    = 0.0,
            lorenzPct       = 0.0,
            giniArea        = 0.0,
            deltaArea       = 0.0,
            samplePct       = 0.0,
            sampleIncome    = 0.0,
            prevLorenzInc   = 0.0,
            prevGiniInc     = 0.0,
            cumulative      = 0.0,
            gotMedian       = false;
            
        // we use all data for Gini, so track visible separately
        var visibleMainSeries       = [];

        
        for (i=0; i < mainSeries.length; i++) {
            
            samplePct       = mainSeries[i]["%"] / 100.0;
            sampleIncome    = mainSeries[i]["$"];
            
            lorenzIncome    += (samplePct * sampleIncome);
            lorenzPct       += samplePct;
            giniIncome      =  lorenzPct * totalIncome;

            giniSeries.push(    {a : lorenzPct, b: giniIncome } );
            lorenzSeries.push(  {a : lorenzPct, b: lorenzIncome });
            
            // to get area more accurately, get midpoint of area bar
            
            giniArea        += samplePct * (giniIncome + prevGiniInc) / 2;
            deltaArea       += samplePct * (lorenzIncome + prevLorenzInc) / 2;
            
            prevGiniInc     = giniIncome;
            prevLorenzInc   = lorenzIncome;
            
            if (mainSeries[i]["$"] <= this.state.varsJSON.xMax && mainSeries[i]["$"] >= this.state.varsJSON.xMin) {
                visibleMainSeries.push(mainSeries[i]);
                if ( this.state.showMiddleClass ) {
                    cumulative += mainSeries[i]["%"];
                    if (cumulative >= 20 && cumulative <= 80) {
                        highlightSeries.push( mainSeries[i] );
                    }
                    if (!gotMedian && cumulative >= 50) {
                        medianSeries.push( mainSeries[i] );
                        gotMedian = true;
                    }
                } 
            }
        }

        this.setState(
            {
                giniSeries          : giniSeries,
                lorenzSeries        : lorenzSeries,
                giniCoefficient     : roundNumber( (giniArea - deltaArea) / giniArea * 100, 1),
                highlightSeries     : highlightSeries,
                medianSeries        : medianSeries,
                visibleMainSeries   : visibleMainSeries
            }
        );
    }
    
    handleUserEvents(key, value) {
        // mousedown handles touchstart too
        // keydown
        var obj  = {}
        obj[key] = value;

        this.setState(obj, function() {
            if ( !value && this.state.killGhost) {     // either keydown or mousedown is false
                this.killGhost( this.killGhostTimeout ); 
            }
        });
    }
    
    calcNumBars() {
        // call this when change: year, xMin, xMax, inflation.  Not needed for highlight
        // not exact if there are spaces in the series
        var numBars = 0;
        for (var j = 0; j < this.state.mainSeries.length; j++) {
            if (this.state.mainSeries[j]["$"] >= this.state.varsJSON.xMin && this.state.mainSeries[j]["$"] <= this.state.varsJSON.xMax) {
                numBars ++;
            }
        }
        
        this.setState(
            {
                numBars    : numBars
            }
        );
    }
    
    adjustInflation(year, deflator) {     // deflator text as in this.inflationChoices
        
        switch( this.inflationChoices.indexOf( deflator ) ) {
            case 1:
                return hhiJSON[ year ]["incomeCpi"];
                break;
            case 2:
                return hhiJSON[ year ]["incomePce"];
                break;
            default:
                return hhiJSON[ year ]["income"];
        }
    }
    
    _adjustInflation(series, year, deflator) {     // deflator text as in this.inflationChoices
        
        // adjusting for inflation compresses data (more than one bar can be rounded to $x)
        // so, need to track number of bars visible
        
        // old series compressed horz, so bars are skinnier. Need to rebuild 1980, rounding to nearest $1000
        var inflationAdjSeries = JSON.parse(JSON.stringify(hhiJSON["1980"]["income"])); // deep copy
        
        for (var i=0; i < inflationAdjSeries.length; i++) {
            inflationAdjSeries[i]["%"] = 0;
        }
        
        
        if (this.inflationChoices.indexOf( deflator ) == 1) { 
            var deflatorStr = "CPIdeflator"; 
        } else if (this.inflationChoices.indexOf( deflator ) == 2) {
            var deflatorStr = "PCEdeflator"; 
        } else {
            return series;
        }
        
        var newDollars;
        
        for (var j=0; j < series.length; j++) {
            
            newDollars = roundNumber( series[j]["$"] / hhiJSON[ year ][deflatorStr], 0);
            
            for (i=0; i < inflationAdjSeries.length; i++) {
                //console.log(inflationAdjSeries[i]["$"], newDollars)
                if (inflationAdjSeries[i]["$"] == newDollars){
                    inflationAdjSeries[i]["%"] += series[j]["%"];
                    break;
                } else if (inflationAdjSeries[i]["$"] > newDollars) {
                    // no match found, insert one
                    inflationAdjSeries.splice(i, 0, {"$": newDollars, "%": series[j]["%"]})
                    break;
                } else {
                    // no match found, insert one
                    inflationAdjSeries.splice(i, 0, {"$": newDollars, "%": series[j]["%"]})
                    break;
                }
            }
        }
        return inflationAdjSeries;
    }
    
    updateValues(jsonKey, valueDict) {        
        // this is a synchronous setting of state/calling new chart after sliders change
        // valueDict in form {moduleKey: value}
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify( this.state.varsJSON ));
        
        Object.keys(valueDict).map(function(key) {
            toUpdateJSON[key] = valueDict[key];
        });
        
        if (jsonKey === "year") {
            
            // make deep copy, so state doesn't change while manipulating
            //var toUpdateSeries = JSON.parse(JSON.stringify(hhiJSON[valueDict[jsonKey]]["income"]));

            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : this.adjustInflation( valueDict[jsonKey], this.state.varsJSON.inflation ),
                    hhAgeSeries     : hhiJSON[valueDict[jsonKey]]["age"],
                    numFamSeries    : hhiJSON[valueDict[jsonKey]]["families"],
                    subfamSeries    : hhiJSON[valueDict[jsonKey]]["subfamilies"],
                    mothersSeries   : hhiJSON[valueDict[jsonKey]]["mothers"],
                    fathersSeries   : hhiJSON[valueDict[jsonKey]]["fathers"],
                    dispSeries      : hhiJSON[valueDict[jsonKey]]["disposition"]
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            //toUpdateSeries = null;

        } else if (jsonKey === "inflation") {
            // make deep copy, so state doesn't change while manipulating
            //var toUpdateSeries = JSON.parse(JSON.stringify(hhiJSON[ this.state.varsJSON.year ]["income"]));

            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : this.adjustInflation(this.state.varsJSON.year, valueDict[jsonKey])
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            //toUpdateSeries = null;
            
            
        } else if (jsonKey === "xMin" || jsonKey === "xMax") {
            this.setState(
                {
                    varsJSON        : toUpdateJSON
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            
        } else if (jsonKey === "middleClass") {
        
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    showMiddleClass : ( valueDict[jsonKey] === "Highlighting median & middle class" )
                }, function() {
                    this.createGiniSeries();
                }
            );
        } else {
            this.setState(
                {
                    varsJSON        : toUpdateJSON
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
        }
        
        toUpdateJSON = null;
    }
    
    render() {  
        var dkColors = ["rgba(255,127,14,0.75)","rgba(214,39,40,0.75)","rgba(148,103,189,0.75)","rgba(44,160,44,0.75)","rgba(31,119,180,0.75)","rgba(227,119,194,0.75)","rgba(188,189,34,0.75)","rgba(140,86,75,0.75)","rgba(127,127,127,0.75)","rgba(23,190,207,0.75)"];
        
        var medColors = ["rgb(255,158,74)","rgb(237,102,93)","rgb(173,139,201)","rgb(114,158,206)","rgb(103,191,92)","rgb(237,151,202)","rgb(205,204,93)","rgb(168,120,110)","rgb(162,162,162)","rgb(109,204,218)"];
        
        var fullColors = ["rgb(255,127,14)","rgb(214,39,40)","rgb(148,103,189)","rgb(44,160,44)","rgb(31,119,180)","rgb(227,119,194)","rgb(188,189,34)","rgb(140,86,75)","rgb(127,127,127)","rgb(23,190,207)"];
        
        return (
            <div id="outer_canvas">
                <div className="chart_title">
                    <h1 className="pathHeadline text_gold">Income Inequality Study</h1>      </div>
                <div className="pathDescription textAlignCenter">
                    Correcting the false narrative.
                </div>
  
                <div className="fontSize13 marginTopMed">
                    <HorzStackedBarChart
                            data            = {[
                                                {
                                                  "1" : this.state.numFamSeries["1"],
                                                  "2" : this.state.numFamSeries["2"],
                                                  "3" : this.state.numFamSeries["3"],
                                                  "4" : this.state.numFamSeries["4"]
                                                }
                                              ]}                  
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "# Families in Household: ",
                                    titleWidth  : "220",
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                    <HorzStackedBarChart
                            data            = {[
                                                {
                                                  "0" : this.state.subfamSeries["0"],
                                                  "1" : this.state.subfamSeries["1"],
                                                  "2" : this.state.subfamSeries["2"],
                                                  "3" : this.state.subfamSeries["3"],
                                                  "4" : this.state.subfamSeries["4"]
                                                }
                                              ]}                  
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "# Sub-Families in Household: ",
                                    titleWidth  : "220",
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                    <HorzStackedBarChart
                            data            = {[
                                                {
                                                  "0" : this.state.mothersSeries["0"],
                                                  "1" : this.state.mothersSeries["1"],
                                                  "2" : this.state.mothersSeries["2"],
                                                  "3" : this.state.mothersSeries["3"],
                                                  "4" : this.state.mothersSeries["4"]
                                                }
                                              ]}                 
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "# Mothers in Household: ",
                                    titleWidth  : "220",
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                    <HorzStackedBarChart
                            data            = {[
                                                  {
                                                    "0" : this.state.fathersSeries["0"],
                                                    "1" : this.state.fathersSeries["1"],
                                                    "2" : this.state.fathersSeries["2"],
                                                    "3" : this.state.fathersSeries["3"],
                                                    "4" : this.state.fathersSeries["4"]
                                                  }
                                              ]}                       
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "# Fathers in Household: ",
                                    titleWidth  : "220", 
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                    <HorzStackedBarChart
                            data            = {[
                                                {
                                                    "Employee"  : this.state.dispSeries["Emp"],
                                                    "Proprietor": this.state.dispSeries["Prop"],
                                                    "Rental"    : this.state.dispSeries["Rntl"],
                                                    "Div/Int"   : this.state.dispSeries["DI"],
                                                    "Xfer Pmts" : this.state.dispSeries["Xfr"]
                                                  }
                                              ]}                      
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "Income Disposition: ",
                                    titleWidth  : "220",
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                    <HorzStackedBarChart
                            data            = {[
                                                {
                                                    "<25"   : this.state.hhAgeSeries["<25"],
                                                    "25-29" : this.state.hhAgeSeries["25-29"],
                                                    "30-34" : this.state.hhAgeSeries["30-34"],
                                                    "35-44" : this.state.hhAgeSeries["35-44"],
                                                    "45-54" : this.state.hhAgeSeries["45-54"],
                                                    "55-64" : this.state.hhAgeSeries["55-64"],
                                                    "65-74" : this.state.hhAgeSeries["65-74"],
                                                    "75+"   : this.state.hhAgeSeries["75+"]
                                                  }
                                              ]}                   
                            colors          = { dkColors }
                            height          = "24"
                            width           = "900"
                            xDataLabel      = { 
                                {
                                    title       : "Householder Age: ",
                                    titleWidth  : "110",
                                    prefix      : "",
                                    suffix      : "%",
                                    decimals    : 2
                                } 
                            }
                    />
                </div>
                            
                <div className="pathDescription">
                    <div className="marginTopSm">
                            Use these sliders to view the progression through the years in the study:
                    </div>
                    <div className = "heading_bar">
                        Year &nbsp; 
                        <TextSlider 
                            jsonKey             = "year"
                            moduleKey           = "year"
                            updateValues        = { this.updateValues }
                            value               = { this.state.varsJSON.year }  
                            handleUserEvents    = { this.handleUserEvents }
                            virtualWidth        = { 160 }
                            choices             = { this.yearChoices }
                        /> &nbsp; &nbsp; Chart min: $
                        <Slider 
                            jsonKey             = "xMin"
                            moduleKey           = "xMin"
                            updateValues        = { this.updateValues }
                            min                 = "-25"
                            max                 = { this.state.varsJSON.xMax - 1 } 
                            step                = "5" 
                            value               = { this.state.varsJSON.xMin }
                            handleUserEvents    = { this.handleUserEvents }
                        />k &nbsp; &nbsp; Chart max: $
                        <Slider 
                            jsonKey             = "xMax"
                            moduleKey           = "xMax"
                            updateValues        = { this.updateValues }
                            min                 = { this.state.varsJSON.xMin + 1 }
                            max                 = "3600" 
                            step                = "5" 
                            //growth              = "10"
                            value               = { this.state.varsJSON.xMax }
                            handleUserEvents    = { this.handleUserEvents }
                            />k &nbsp; &nbsp; 
                
                        <TextToggle 
                            jsonKey             = "inflation"
                            moduleKey           = "inflation"
                            updateValues        = { this.updateValues }
                            value               = { this.state.varsJSON.inflation }
                            choices             = { this.inflationChoices }
                            handleUserEvents    = { this.handleUserEvents }
                        /> &nbsp; &nbsp; 
                            
                        <TextToggle 
                            jsonKey             = "middleClass"
                            moduleKey           = "middleClass"
                            updateValues        = { this.updateValues }
                            value               = { this.state.varsJSON.middleClass }
                            choices             = { this.middleClassCases }
                            handleUserEvents    = { this.handleUserEvents }
                        />
                    </div>
                </div>
                            
                <div id="chart1">
                    <BarChart
                        title           = {`Household Income Distribution (${this.state.varsJSON.year})`}
                        mainSeries      = { this.state.visibleMainSeries }
                        highlightSeries = {
                                            [
                                                {   
                                                    data        : this.state.highlightSeries,
                                                    className   : "highlightBar" 
                                                },
                                                {   
                                                    data        : this.state.medianSeries,
                                                    className   : "medianBar" 
                                                }
                                            ]
                                          }
                        height          = "400"
                        width           = "900"
                        xMin            = { this.state.varsJSON.xMin }
                        xMax            = { this.state.varsJSON.xMax}
                        yMin            = { this.state.varsJSON.yMin }
                        yMax            = { this.state.varsJSON.yMax}
                        numBars         = { this.state.numBars}
                        xDataLabel      = { 
                            {
                                name        : "$",
                                prefix      : "",
                                suffix      : "k",
                                decimals    : 0
                            } 
                        }
                        yDataLabel      = { 
                             {
                                name        : "%",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 5,
                                axisDecimals: 1
                             }
                        }
                        mousedown       = { this.state.mousedown }
                        keydown         = { this.state.keydown }
                        cursorExtra     = "percentile"
                    /> 
                        
                    <LineChart
                        title           = {`Gini Coefficient: ${ this.state.giniCoefficient }`}
                        mainSeries      = { this.state.giniSeries }
                        subSeries       = { this.state.lorenzSeries }
                        height          = "160"
                        width           = "280"
                        margin          = {{top: 20, right: 0, bottom: 0, left: 0}}
                        labelPadding    = {{top: 0, right: 10, bottom: 10, left: 10}}
                        translate       = {{x: 574, y: -380}}
                        xDataLabel      = { 
                            {
                                name        : "a",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 0
                            } 
                        }
                        yDataLabel      = { 
                             {
                                name        : "b",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 0
                             }
                        }
                        mousedown       = { this.state.mousedown }
                        keydown         = { this.state.keydown }
                        showCursorData  = { false }
                        showGrid        = { false }
                        showAxes        = { false }
                    /> 
                  
                </div> 

                
                <div className="source">sources: IPUMS USA, University of Minnesota, www.ipums.org, and U.S. Department of Commerce Census Bureau</div>                    
            </div>
        )            
    }
}


export default IncInequal;
