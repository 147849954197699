require("../styles/_Main.scss");

import Slider       from "./Slider.jsx";


class InflationModule extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
             mounted    : false,
        };
    }
    
    componentDidMount() {
        // allows for animation.  Changing width
        setTimeout(
            function() {
        
                this.setState(
                    {
                        mounted     : true,
                    }
                );
            }.bind(this),
            0
        )
    }

    render() {
        

        var JSONkey         = this.props.JSONkey;

        var moduleClassName = ( this.props.module.minimized)
                                    ? "AIM_box module_box fullWidth module_minimized"
                                    : (this.props.module.active)
                                        ? "AIM_box module_box fullWidth module_visible"
                                        : "AIM_box module_box fullWidth module_minimized"

        if (!this.state.mounted) {
            moduleClassName = moduleClassName.replace("fullWidth", "zeroWidth");
        }
        
        var activeClassName;
                                                                               
        if ( this.props.module.active == false) {
            activeClassName = "inactive";
        } else {
            activeClassName = "displayNone";
        }
        

        return (

            <div className={ moduleClassName }>
                <div className="heading_bar">
                    <div className="left_heading text_red">Adjust for Inflation</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_red"  
                            type            = "text"  
                            defaultValue    = { this.props.module.title }
                            onBlur          = { function() { 
                                this.props.updateValues(JSONkey, {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                    	<div className="right_heading">
                    		<div className={ activeClassName }>(inactive) &nbsp; &nbsp; </div>
                    		<div className="icon_help"      onClick={ this.props.showHelpDom }></div>
                            <div className="icon_minimize"  onClick={ function() { this.props.stepChoices(JSONkey, "minimized") }.bind(this) }></div>
                    		<div className="icon_down"      onClick={ function() { this.props.moveDown(JSONkey) }.bind(this) }></div>
                    		<div className="icon_up"        onClick={ function() { this.props.moveUp(JSONkey) }.bind(this) }></div>
                    		<div className="icon_highlight" onClick={ function() { this.props.stepChoices(JSONkey, "highlighted") }.bind(this) }></div>
                		
                            <div className="icon_toggle"    onMouseOver={ function() { this.props.showEffects(JSONkey, true) }.bind(this) }
                                                            onMouseOut={ function() { this.props.showEffects(JSONkey, false) }.bind(this) }></div>
                            <div className="icon_deactivate" onClick={ function() { this.props.toggleActive(JSONkey) }.bind(this) }></div>
                            <div className="icon_delete"    onClick={ function() { this.props.removeModule(JSONkey) }.bind(this) }></div>
                    		<div className="clearBoth"></div>
                    	</div>
                </div>

                <div className="module_content">
                    I expect an inflation rate of &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "inflationRate"
                            updateValues        = { this.props.updateValues }
                            min                 = "-100"
                            max                 = "100"
                            step                = "0.25"
                            decimals            = "3"
                            value               = { this.props.module.inflationRate }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%, 
                    starting at age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "inflationAgeStart"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.inflationAgeStart }
                            handleUserEvents    = { this.props.handleUserEvents }
                            />&nbsp;
                    and lasting until age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "inflationAgeEnd"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.inflationAgeEnd }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />. 
                    <div className="inactive2">(inactive)</div><br /><br />
                    Adjusting for inflation is very important, as it allows me to determine the value of my account and future withdrawals based on the value of todays dollars.&nbsp; 
                    Otherwise, 100 future dollars may only buy a loaf of bread.&nbsp; 
                    I can use this module to add different inflation rates during various periods.&nbsp; 
                    I will make sure that I have entered inflation values to cover the entire path.&nbsp; 
                    Alternatively, I can enter my REAL rates of return (after inflation) in the Base Line module above. 
                </div>
            </div>
        );
    }
}


export default InflationModule;
