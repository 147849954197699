import Home         from "./Home.jsx";
import Path         from "./Path.jsx";
import RentOrBuy    from "./RentOrBuy.jsx";
import Lease        from "./Lease.jsx";
import Privacy      from "./Privacy.jsx";
import Terms        from "./Terms.jsx";
import IncInequal   from "./IncomeInequality.jsx";
import IncIneqTool  from "./IncomeInequalityTool.jsx";

// to get rid of 'Set' error in IE 10.  adds 11k to size
import 'core-js/es/map';
import 'core-js/es/set';

import ReactDOM      from 'react-dom';


// v4.0
//var ReactRouter     = require('react-router');
//import { Redirect }    from 'react-router';

// uses HTML5 history API
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'


//var AppContainer    = require('react-hot-loader').AppContainer;


//var NotFoundRoute   = Router.NotFoundRoute;
//var DefaultRoute    = Router.DefaultRoute;
//var RouteHandler    = Router.RouteHandler;
//var createHistory   = require('history').createBrowserHistory
//let history = createHistory()

//var testParams = {'one': 1, 'two':2};



class MainContainer extends React.Component {
    
    constructor(props) {
        super(props);
        //this.state = {
        //    varsJSON:   varsJSON
        //};
    }


    render() {  
        return (
            <div>
                <BrowserRouter>
                    <Switch>
                        
                        <Route  path="/path/:key"               component={Path} />
                        <Route  path="/path/"                   component={Path} />
                        <Route  path="/rent-or-buy/:key"        component={RentOrBuy} />
                        <Route  path="/rent-or-buy/"            component={RentOrBuy} />

                        <Route  path="/lease-calculator/:key"   component={Lease} />
                        <Route  path="/lease-calculator/"       component={Lease} />
            
                        <Route  path="/incomeinequality"        component={IncInequal} />
                        <Route  path="/income-inequality"       component={IncInequal} />
                        <Route  path="/income-inequality-chart" component={IncInequal} />
                        <Route  path="/income-inequality-chart/"component={IncInequal} />

                        <Route  path="/gini-coefficient-study"  component={IncIneqTool} />/

                        {/* privacy, terms, about us, etc */}
                        <Route  path="/privacy"                 component={Privacy}/>
                        <Route  path="/terms-of-use"            component={Terms}/>

                        {/* redirects */}
                        <Redirect from="/path"                  to="/path/"/>
                        <Redirect from="/path-to-retirement"    to="/path/"/>
                        <Redirect from="/path-to-retirement/"   to="/path/"/>
                        
                        <Route  path="/budget/"                 component={Home} />
                        <Route  path="/"                        component={Home} />
                        
                        <Route path='*'                         component={Path} />
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}


// https://github.com/rackt/react-router/blob/master/docs/api/run.md
//Router.run(routes, Router.HistoryLocation, function (Handler) {
//    React.render(<Handler />, document.getElementById('content'));
//});



ReactDOM.render((
    <MainContainer />
), document.getElementById('content'));


export default MainContainer;
// closure-compiler needs  format like these:

//module.exports.MainContainer = MainContainer;
//module.exports = {
//    MainContainer   : MainContainer
    //}
