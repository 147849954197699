require("../styles/_Main.scss");
import { line, arc } from 'd3';
import { select, selectAll } from "d3-selection";

const d3 = Object.assign(
  {},
  { select, selectAll, line, arc}, //request
)

class Gauge extends React.Component {

    constructor(props) {
        super(props);
        
        this.size                        = this.props.size   || 240;
        this.radius                      = this.size * 0.9 / 2;
        this.cx                          = this.size / 2;
        this.cy                          = this.cx;
        this.className                   = this.props.className      || '';
        this.transitionDuration          = this.props.transitionDuration || 500;
        this.preserveAspectRatio         = this.preserveAspectRatio      || 'xMinYMin meet';
        
        this.ref                = React.createRef();
        this.draw               = this.draw.bind(this);
        this.drawLine           = this.drawLine.bind(this);
        this.drawBand           = this.drawBand.bind(this);
        this.buildNeedlePath    = this.buildNeedlePath.bind(this);
        this.toDegrees          = this.toDegrees.bind(this);
        this.toRadians          = this.toRadians.bind(this);
        this.toPoint            = this.toPoint.bind(this);
        this.valueToPoint       = this.valueToPoint.bind(this);
    }
    
    componentDidMount() {
        this.svgParent = d3.select(this.ref.current)
            .append('svg')
            .attr('class'  ,  'd3-gauge' + (this.className ? ' ' + this.className : ''))
            .attr('width'  ,  this.size)
            .attr('height' ,  this.size)
            .attr('viewBox',  '0 0 ' + this.size + ' ' + this.size)
            .attr('preserveAspectRatio', this.preserveAspectRatio || 'xMinYMin meet')
    }
    
    componentDidUpdate() {
       
        this.value                       = this.props.value  || 0;
        this.min                         = this.props.min    || 0; 
        this.max                         = this.props.max    || 100; 
        this.label                       = this.props.label  || 'label'; 
        this.labelClass                  = 'label';
        this.labelX                      = 0;
        this.labelY                      = 13;   // dist below center
        this.readout                     = this.props.readout || "";
        this.readoutClass                = this.props.readoutClass   || "readoutGreen";
        this.minorTicks                  = this.props.minorTicks     || 4; 
        this.majorTicks                  = this.props.majorTicks     || 5; 
        this.needleWidthRatio            = 0.6; 
        this.needleContainerRadiusRatio  = 0.7; 
        this.midPoint                    = (this.max + this.min) / 2;
        this.range                       = this.max - this.min;
        this.zones                       = this.props.zones          || [
                                                                            {   
                                                                                className: 'red-zone',    
                                                                                from: this.min,       
                                                                                to: this.min * 0.1 
                                                                            },
                                                                            { 
                                                                                className: 'yellow-zone', 
                                                                                from: this.min * 0.1, 
                                                                                to: this.max * 0.1 
                                                                            }, 
                                                                            { 
                                                                                className: 'green-zone',  
                                                                                from: this.max * 0.1, 
                                                                                to: this.max 
                                                                            },
                                                                        ],
        this.fontSize                   = Math.round(this.size / 10);
        this.halfFontSize               = this.fontSize / 2;
            
        this.svgParent.selectAll(".g").remove();
        this.draw();
    }
    
    draw() {
        
        var _gauge = this.svgParent
            .append("g")
            .attr("class", "g");
        
        _gauge.append('circle')
            .attr('class' ,  'outer-circle')
            .attr('cx'    ,  this.cx)
            .attr('cy'    ,  this.cy)
            .attr('r'     ,  this.radius)

        // draw inner circle (dial)
        _gauge.append('circle')
            .attr('class' ,  'inner-circle')
            .attr('cx'    ,  this.cx)
            .attr('cy'    ,  this.cy)
            .attr('r'     ,  0.95 * this.radius)

        // draw gauge label
        _gauge.append('text')
            .attr('class', this.labelClass)
            .attr('x', this.cx + this.labelX)
            .attr('y', this.cy + this.halfFontSize + this.labelY)
            .attr('dy', this.halfFontSize)
            .attr('text-anchor', 'middle')
            .text(this.label)
        
        var majorDelta  = this.range / (this.majorTicks - 1), 
            minorDelta  = majorDelta / this.minorTicks, 
            textAnchor  = 'middle',
            point;

        // draw Ticks
        for (var major = this.min; major <= this.max; major += majorDelta) {
            var minorMax = Math.min(major + majorDelta, this.max);
            for (var minor = major + minorDelta; minor < minorMax; minor += minorDelta) {
                this.drawLine(_gauge, this.toPoint(minor, 0.75), this.toPoint(minor, 0.85), 'minor-tick');
            }

            this.drawLine(_gauge, this.toPoint(major, 0.75), this.toPoint(major, 0.9), 'major-tick');

            if (major < (this.midPoint * .9 - this.min * 0.1)) {
                textAnchor = 'start';
            } else if (major > (this.midPoint * .9 + this.max * 0.1)) {
                textAnchor = 'end';
            } else {
                textAnchor = 'middle';
            }

            point = this.toPoint(major, 0.7);

            // draw major tick labels
            _gauge.append('text')
                .attr('class', 'major-tick-label')
                .attr('x', point.x - 4)
                .attr('y', point.y + 7) // fudge based on font-size
                .attr('text-anchor', textAnchor)
                .text(major.toString()) // fudge to get "0" to print
        }
    
        //  draw zones
        this.zones.forEach(function(zone) {
            this.drawBand(_gauge, zone.from, zone.to, zone.className);
        }.bind(this));

    
        var needleContainer = _gauge
            .append('g')
                .attr('class', 'needle-container');

        var needlePath = this.buildNeedlePath( this.value );

        var needleLine = d3.line()
            .x(function(d) { return d.x })
            .y(function(d) { return d.y })
            //.curve(d3.curveBasis);
        
        // draw needle
        needleContainer.selectAll('path')
            .data([ needlePath ])
            .enter()
            .append('path')
                .attr('class' ,  'needle')
                .attr('d'     ,  needleLine)
        
        // draw needle aanchor
        needleContainer.append('circle')
            .attr('cx'      ,     this.cx)
            .attr('cy'      ,     this.cy)
            .attr('r'       ,     this.radius *     this.needleContainerRadiusRatio / 10)
            .attr('class'   , 'needle-anchor')
        
        // draw bottom readout
        needleContainer.selectAll('text')
            .data([ this.readout ])
            .enter()
            .append('text')
                .attr('x'             , this.cx)
                .attr('y'             , this.size - this.cy / 4 - this.fontSize)
                .attr('dy'            , this.fontSize / 2 + 7)
                .attr('text-anchor'   , 'middle')
                .attr('class'         , this.readoutClass)
                .text( this.readout )
    }
    
    drawLine(_gauge, p1, p2, className) {
        _gauge.append('line')
            .attr('class' ,  className)
            .attr('x1'    ,  p1.x)
            .attr('y1'    ,  p1.y)
            .attr('x2'    ,  p2.x)
            .attr('y2'    ,  p2.y)
    }
    

    drawBand(_gauge, start, end, className) {

        var arc = d3.arc()
            .startAngle(    this.toRadians(start) )
            .endAngle(      this.toRadians(end) )
            .innerRadius(   0.75 * this.radius)
            .outerRadius(   0.9 * this.radius);

        _gauge.append('path')
            .attr('class', className)
            .attr('d', arc)
            .attr('transform', 'translate(' + this.cx + ', ' + this.cy +') rotate(270)')
    }

    valueToPoint(value, factor) {
        return this.toPoint(value, factor);
    }

    buildNeedlePath(value) {

        var delta       = this.range * this.needleWidthRatio / 10, 
            tailValue   = value - ( this.range * (1/ (270/360)) / 2);

        var head        = this.valueToPoint(value, 0.85), 
            head1       = this.valueToPoint(value - delta, 0.12), 
            head2       = this.valueToPoint(value + delta, 0.12);

        var tail        = this.valueToPoint(tailValue, 0.28), 
            tail1       = this.valueToPoint(tailValue - delta, 0.12), 
            tail2       = this.valueToPoint(tailValue + delta, 0.12);

        return [head, head1, tail2, tail, tail1, head2, head];
    }

    toDegrees(value) {
        return value / this.range * 270 - ( this.min / this.range * 270 + 45);
    }

    toRadians(value) {
        return this.toDegrees(value) * Math.PI / 180;
    }

    toPoint(value, factor) {
        var len         = this.radius * factor;
        var inRadians   = this.toRadians(value);

        return {
            x: this.cx - len * Math.cos(inRadians),
            y: this.cy - len * Math.sin(inRadians)
        };
    }
    
    render() {
        return (
            <div className  = {"visible"} 
                 ref        = { this.ref } 
            />
        );
    }
}

export default Gauge;
