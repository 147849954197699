var touches = [];

var isIOS = ( navigator.userAgent.match(/iphone/i) || navigator.userAgent.match(/ipad/i) || navigator.userAgent.match(/ipod/i) ) != null;
var isAndroid = ( navigator.userAgent.match(/android/i) ) != null;
var isWindowsMobile = ( navigator.userAgent.match(/Windows Phone/i) || navigator.userAgent.match(/iemobile/i) ) != null;
var isNokia = ( navigator.userAgent.match(/NOKIA/i) ) != null;
var isMobile = ( isIOS || isAndroid || isWindowsMobile || isNokia );

var mouseIsDown = false;
var isDragging  = false;

var keysPressed = {};

document.addEventListener("keydown", function(e) {
    e = e || window.event;
    var charCode = e.keyCode || e.which;
    keysPressed[charCode] = true;
    return false;
});

document.addEventListener("keyup", function(e) {
    e = e || window.event;
    var charCode = e.keyCode || e.which;
    delete keysPressed[charCode];
    return false;
});

//document.addEventListener("mouseup",        bindings);
//document.addEventListener("touchend",       bindings);
//document.addEventListener("touchcancel",    bindings);


function bindings() {
    document.body.onselectstart = null;
    document.body.style.MozUserSelect = "";
    document.body.onmousedown = null;
    
    mouseIsDown=false;
    if(isDragging)
       //deleteGhostSeries();
    isDragging=false;
    
    //$(window).unbind('mousemove.numbers touchmove.numbers');

    //$('.hint').each( function(){
    //    $(this).css('display', 'none');
    //    $(this).hide();
    //});
    
    return document.body.style.cursor = "inherit";
}

function appendArray(array,array1){ 
    // adds the items in array1 to the end of array 
    // this is 2* quicker than invoking push
    
    var len,i;
    array1 = [].concat(array1);   // ensure array1 is an array
    len = array1.length;
    i = 0;
    while(i < len){
        array[array.length] = array1[i++]; 
    }
    return array;
}

function roundNumber(num, decimals) {
    var result;
    if (decimals == 0 || isNaN(decimals))
       result = Math.round(num);
    else result = Math.round( num * Math.pow(10,decimals) ) / Math.pow(10,decimals);
    
    // add trailing zeroes
    /*
    if (decimals > 0 && (trailingZeroes == undefined || trailingZeroes == true)) {
        result = result.toString();
        if (result.indexOf(".") == -1) {
            result += ".";
        }
        
        while (result.split(".")[1].length < decimals) {
            result += "0";
        }
    }
    */
    return result;
}

function displayFormatted(number, abbrevDecPlaces, cutoff, options) {
    
    options = options || {};
    
    var absValNumber    = Math.abs(number);
        
    if ( absValNumber > cutoff ) {
        // 2 decimal places => 100, 3 => 1000, etc
        var multiplier  = Math.pow(10, abbrevDecPlaces);
        var size        = 1,
            abbrev      = "";
        
        /* Trillion */
        if (absValNumber > 99999999999){
            size = 1000000000000;
            abbrev="T";
        }
        /* Billion */
        else if (absValNumber > 9999999999) {
            size = 1000000000;
            abbrev="B";
        }
        /* Million */
        else if (absValNumber > 999999) {
            size = 1000000;
            abbrev="M";
        }
        /* Thousand */
        else if (absValNumber > 999) {
            size = 1000;
            abbrev="K";
        }
        /* default no change */
        else
            size=1;

        number = roundNumber( number / size, abbrevDecPlaces );
        
        //options.minimumFractionDigits = abbrevDecPlaces;
        options.maximumFractionDigits = abbrevDecPlaces;
        
        //return [number.currencyFormat(abbrevDecPlaces), abbrev]
        
        // with commas, or decimal point, per locale
        return [number.toLocaleString(undefined, options), abbrev];  
    } else {
        // return [number.currencyFormat(decimals), ""]
        
        // with commas, or decimal point, per locale
        return [number.toLocaleString(undefined, options), ""];
    } 
}


module.exports = {
    isMobile            : isMobile,
    isIOS               : isIOS,
    keysPressed         : keysPressed,
    roundNumber         : roundNumber,
    appendArray         : appendArray,
    displayFormatted    : displayFormatted,
}