require("../styles/_Main.scss");
                    
import {isMobile, isIOS, keysPressed} from "./Common.jsx";


class TextToggle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.choices        = (props.choices != undefined)  ? props.choices     : ["Pay Cash", "Finance the Purchase"];
        this.displayIndex   = this.choices.indexOf(props.value);
        
        this.state = {
            text            : props.text,
            hasHover        : false,
        };
        
        this.hint           = (!isMobile)                   ? "click or arrows" : "tap";
        
        this.handleClick    = this.handleClick.bind(this);
        this.onMouseOver    = this.onMouseOver.bind(this);
        this.onMouseOut     = this.onMouseOut.bind(this);
        this.doKeyDown      = this.doKeyDown.bind(this);
    }
    
    onMouseOver() {
        if (isIOS) {
            this.setState(
                {
                    hasHover: false
                }
            );
            return true;    // iOS returns to hover after mouseup? remove this
        }
        
        this.setState(
            {
                hasHover: true
            }
        );
        
        document.addEventListener("keydown", this.doKeyDown);
    }
    
    doKeyDown(e) {
        e.preventDefault();
            
        if (keysPressed[37] || keysPressed[40])   {   // left, down
            this.props.handleUserEvents("keydown", true);
            
            this.displayIndex --;
            if (this.displayIndex < 0) this.displayIndex = this.choices.length - 1;

            this.props.updateValues(this.props.jsonKey, {[this.props.moduleKey]: this.choices[this.displayIndex]} );
            
        } else if (keysPressed[38] || keysPressed[39]) {  // up, right
            this.props.handleUserEvents("keydown", true);
            this.displayIndex ++;
            if (this.displayIndex >= this.choices.length) this.displayIndex = 0;

            this.props.updateValues(this.props.jsonKey, {[this.props.moduleKey]: this.choices[this.displayIndex]} );
            
        }
        return false; // if no arrow keys, do nothing
    }

    
    onMouseOut() {
        //console.log("onMouseOut")
        this.setState(
            {
                hasHover: false
            }
        )
        document.removeEventListener("keydown", this.doKeyDown )
    }

    handleClick() {
        var displayIndex = this.choices.indexOf(this.props.value) + 1;
        if (displayIndex >= this.choices.length) displayIndex = 0;

        this.props.updateValues(this.props.jsonKey, {[this.props.moduleKey]: this.choices[displayIndex]} );
    }
    
    render() {
        
        var hintClassName       =   (this.state.hasHover)             ? 'hintHovered'                  :  'displayNone';
        
        var displayDOM      =   <span>
                                    <span
                                         onClick        = { this.handleClick }
                                         onMouseOver    = { this.onMouseOver }
                                         onMouseOut     = { this.onMouseOut }
                                    >
                                        { this.props.value }
                                    </span>
                                    <span className="numAbbr"></span>
                                    <div className= { hintClassName }>
                                        { this.hint }
                                    </div>
                                </span>;

        
        var className   = (this.props.className != undefined)   ? this.props.className          : "DOWslider";    

        
        return (
            <span className={ className }>
                { displayDOM }
            </span>
        );
    }
}


export default TextToggle;
