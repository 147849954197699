require("../styles/_Main.scss");

import Slider                           from "./Slider.jsx";
import TextToggle                       from "./TextToggle.jsx";
import Gauge                            from "./Gauge.jsx";
import SaveDialog                       from "./SaveDialog.jsx";
import HeadlineDescription              from "./HeadlineDescription.jsx";
import Button                           from "./Button.jsx";

import {roundNumber, displayFormatted}  from "./Common.jsx"


class RentOrBuy extends React.Component {
    
    constructor(props) {
        super(props);
        
        if (MsgToUser.indexOf('Saved version not found') == 0) {
            this.urlKey = ''
        } else {
            this.urlKey         = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        }
        
        this.state = {
            varsJSON            : varsJSON,
            showHelpDOM         : false,
            showPasswordHelpDOM : false,
            mousedown           : false,
            keydown             : false,
            varsChanged         : false,
            saveClicked         : "",
            errorText           : "",
            savedKey            : "",
            chartWarningText    : "",
            overlayGray         : false,
        };
        
        this.showHelpDom        = this.showHelpDom.bind(this);
        this.hideHelpDom        = this.hideHelpDom.bind(this);
        this.updateBasicParams  = this.updateBasicParams.bind(this);
        this.updateValues       = this.updateValues.bind(this);
        this.stepChoices        = this.stepChoices.bind(this);
        this.handleUserEvents   = this.handleUserEvents.bind(this);
        this.hidePasswordHelpDom= this.hidePasswordHelpDom.bind(this);
        
        this.updateGauges       = this.updateGauges.bind(this);
        this.getGoodRange       = this.getGoodRange.bind(this);
        this.resetVarsChanged   = this.resetVarsChanged.bind(this);
        this.setOverlay         = this.setOverlay.bind(this);
        this.clearOverlay       = this.clearOverlay.bind(this);
    }
    
    componentDidMount() {
        this.updateGauges();
    } 
    
    setOverlay() {
        this.setState(
            {
                overlayGray     : true
            }
        );
    }
    clearOverlay() {
        this.setState(
            {
                overlayGray     : false,
                varsChanged     : false
            }
        );
    }
    
    resetVarsChanged() {
        // called after a save
        this.setState(
            {
                varsChanged     : false
            }
        );
    }
    
    
    handleUserEvents(key, value) {
        // mousedown handles touchstart too
        // keydown
        var obj  = {}
        obj[key] = value;
        
        //console.log("1: " + this.state.mousedown, this.state.keydown)
        
        this.setState(obj, function() {
            if ( !value && this.state.killGhost) {     // either keydown or mousedown is false
                this.killGhost( this.killGhostTimeout );
            }
            
            //console.log("2: " + this.state.mousedown, this.state.keydown)
            
        });
        
        
    }
    
    updateBasicParams(param, value) {
        // text areas controlled, so need to give them back a new value

        if (param == "headline" || param == "desc") {
            value = value.replace(/<\s?a\b[^>]*>(.*?)<\/a>/gi,"<link removed>");
            //var obj  = {}
            //obj[param] = value;
            //this.setState(obj);
        }
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        toUpdateJSON[param] = value;
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function() {
                //console.log(this.state.varsJSON)
            }
        );
        toUpdateJSON = null;
        
        
        // this.updateChartSeries();        // no need to redraw the chart for these changes
    }
    
    updateValues(jsonKey, valueDict) {
        // this is a synchronous setting of state/calling new chart
        // valueDict in form {moduleKey: value}
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify(this.state.varsJSON));
        
        if (jsonKey == "base") {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON[key] = valueDict[key];
            });
        } else {
            Object.keys(valueDict).map(function(key) {
                toUpdateJSON.modules[jsonKey][key] = valueDict[key];
            });
        }
        
        this.setState(
            {
                varsJSON        : toUpdateJSON,
                varsChanged     : true,
                savedKey        : "",
            },
            function () {
                this.updateGauges();
            }
        );
        
        toUpdateJSON = null;
        /*  send function to setState to make completely sync:
            setState(function(previousState, currentProps) {
                return {myInteger: previousState.myInteger + 1};
            });
        */
    }
    
    handleCheckboxChange(jsonKey, ref) {
        var isChecked = this.refs[ref].checked;
        this.updateValues( jsonKey, {[ref]: isChecked} );
    }

    stepChoices(jsonKey, moduleKey, options) {
        // steps through a list of choices, usually T/F
        options             = options           || {};
        var choices         = options.choices   || [ true, false ];
        
        var currentIndex    = choices.indexOf( this.state.varsJSON[moduleKey] );
        var nextIndex       = currentIndex + 1;
        
        if (nextIndex >= choices.length) nextIndex = 0;

        this.updateValues( jsonKey, {[moduleKey]: choices[nextIndex]} );
    }

    showHelpDom() {
        this.setState(
            {
                showHelpDOM: true
            }
        );
    }
    hideHelpDom() {
        this.setState(
            {
                showHelpDOM: false
            }
        );
    }
    showPasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: true
            }
        );
    }
    hidePasswordHelpDom() {
        this.setState(
            {
                showPasswordHelpDOM: false
            }
        );
    }

    
    getGoodRange(num) {
        var power = Math.floor(Math.log(num)/Math.log(10));
        var digit = Math.floor(num / Math.pow(10, power)) + 1;
        return digit * Math.pow(10, power);
    }
    
    updateGauges() {
        
        var params = this.state.varsJSON.modules["1"];
        
        var propCost            = parseInt(params.propCost),
            closingCosts        = parseInt(params.closingCosts),
            downPmt             = parseInt(params.downPmt),
            intRate             = parseFloat(params.intRate) / 1200,
            numMonths           = parseInt(params.numMonths),
            intOnly             = params.intOnly,
            propTaxes           = parseInt(params.propTaxes),
            ins                 = parseInt(params.ins),
            maint               = parseInt(params.maint),
            hoa                 = parseInt(params.hoa),
            variableCost        = parseFloat(params.variableCost) / 100,
            variableRent        = parseFloat(params.variableRent) / 100,
            rent                = parseInt(params.rent),
        
            occupancy           = parseInt(params.occupancy) / 100,
            appreciation        = parseFloat(params.appreciation),
            depreciateIt        = params.depreciateIt,
            taxDepPct           = parseFloat(params.taxDepPct) / 100,
            taxDepYears         = parseFloat(params.taxDepYears),
            taxBracket          = parseInt(params.taxBracket) / 100;

        var expenses = propTaxes + ins + maint + hoa + (variableCost * propCost) + (variableRent * rent);   // all monthly
        var monthlyPmt = (intRate * (propCost + closingCosts - downPmt)) / (1 - Math.pow((1 + intRate), -numMonths));   // yep, that's right.

        if (intOnly) {
            monthlyPmt = intRate * (propCost + closingCosts - downPmt);
        }

        if (monthlyPmt < 0) { monthlyPmt = 0; }
        
        
        var cashCashFlow = Math.round( (rent*occupancy) - expenses );
        var financedCashFlow = cashCashFlow - Math.round( monthlyPmt );
        
        
        var principalReduction = Math.round( monthlyPmt - (propCost + closingCosts - downPmt) * intRate); //close enough first year;

        var cashROI = roundNumber(cashCashFlow / (propCost + closingCosts) * 1200 + appreciation, 2);
        var financedROI = roundNumber( (financedCashFlow + principalReduction) / downPmt * 1200 + appreciation, 2);

        if (depreciateIt) {
            var depTaxSavings = propCost * taxDepPct / taxDepYears * taxBracket / 12;
            financedCashFlow += Math.round(depTaxSavings);
        }
        

        var gaugeMaxMonthlyCashFlow = this.getGoodRange(Math.abs(financedCashFlow * 2)),
            gaugeMaxMonthlyCashFlow = (gaugeMaxMonthlyCashFlow < 2000) ? 2000 : gaugeMaxMonthlyCashFlow,
            gaugeMinMonthlyCashFlow = -gaugeMaxMonthlyCashFlow,
            
            gaugeMaxCashROI = this.getGoodRange(Math.abs(cashROI * 1.1)),
            gaugeMaxCashROI = (gaugeMaxCashROI < 4) ? 4 : gaugeMaxCashROI,
            gaugeMinCashROI = -gaugeMaxCashROI,
        
            gaugeMaxFinancedROI = this.getGoodRange(Math.abs(financedROI * 1.1)),
            gaugeMaxFinancedROI = (gaugeMaxFinancedROI < 4) ? 4 : gaugeMaxFinancedROI,
            gaugeMinFinancedROI = -gaugeMaxFinancedROI;
            
        this.setState(
            {
                gaugeMinMonthlyCashFlow : gaugeMinMonthlyCashFlow,
                gaugeMaxMonthlyCashFlow : gaugeMaxMonthlyCashFlow,
                gaugeMinCashROI         : gaugeMinCashROI,
                gaugeMaxCashROI         : gaugeMaxCashROI,
                gaugeMinFinancedROI     : gaugeMinFinancedROI,
                gaugeMaxFinancedROI     : gaugeMaxFinancedROI,
                financedCashFlow        : financedCashFlow,
                cashROI                 : cashROI,
                financedROI             : financedROI,
                monthlyPmt              : displayFormatted( monthlyPmt, 2 )
            }
        );
    }
        

    render() {
        
        var overlayClass = (this.state.overlayGray) ? "overlayGray" : "displayNone";
        var overlayDOM = <div className = {overlayClass} />
        
        var params = this.state.varsJSON.modules["1"];
        
        var descAlignVisibility = (this.state.descFocused)          ? "displayBlock"        : "displayNone";
       

        var helpDOMClassName    = (this.state.showHelpDOM)          ? 'helpDOM'             : 'displayNone'; 
        
        var helpDOM =   <div className= { helpDOMClassName }> 
                            <div className="heading_bar"> 
                                <div className="center_heading text_blue">Help</div>  
    
                                <div className="right_heading"> 
                                    <div className="icon_delete" onClick={ this.hideHelpDom }></div> 
                                </div> 
                            </div> 
                            <div> 


                            <div className="text_blue clearBoth"><div className="clearBoth">Changing Values:</div></div><br /> 

                            There are several ways to easily change the values:<br/><br />

                            <div style={{"marginLeft":"16px"}}>

                                1) Click and drag numerical values to change.<br />
                                2) Double-click the value to enter directly<br />
                                3) Mouse over the value, and use the arrow keys to finely adjust<br /><br />
                            </div>

                            Hold down the following keys to adjust the rate of change of the slider: <br/><br /> 
                            <div style={{"marginLeft":"16px"}}>
                                (.) - adjust by 0.01<br /> 
                                (0) - adjust by 0.1<br /> 
                                (2) - adjust by 10x<br /> 
                                (3) - adjust by 100x<br /> 
                                (4) - adjust by 1000x<br /> 
                                (5) - adjust by 10000x<br /> 
                                (6) - adjust by 100000x 
                            </div>

                            <br />

                            <div className="text_blue clearBoth"><div className="clearBoth">Editing Headline and Description:</div></div><br />

                    
                            Double-click the Title or Description to edit either. &nbsp;While editing the description, icons for text alignment become visible.<br /><br />

                    
                            <div className="text_blue clearBoth"><div className="clearBoth">Icons:</div></div><br /> 
                            </div> 
                            <div><div className="icon_help clearBoth"></div> - this help file</div> 
                            <div><div className="icon_minimize clearBoth"></div> - minimize module to show just header (toggle to display again)</div> 
                            <div><div className="icon_down clearBoth"></div> - move module down.  (shift-click to move all the way to bottom)</div> 
                            <div><div className="icon_up clearBoth"></div>  - move module up.  (shift-click to move all the way to top)</div> 
                            <div><div className="icon_highlight clearBoth"></div> - highlight the date region of this module</div> 
                            <div><div className="icon_toggle clearBoth"></div> - mouse over to show effects of module</div> 
                            <div><div className="icon_deactivate"></div> - deactivate this module, without deleting</div>
                            <div><div className="icon_delete clearBoth"></div> - delete module</div> 
                            <div>
                            <br />


                                <div className="textAlignCenter">
                                    Questions or comments? &nbsp; Please <a href="mailto:plan@dynamicsofwealth.com">email Us</a>.<br /><br /> 
                                    <Button     
                                            text            = "Close"
                                            clickHandler    = { this.hideHelpDom }
                                            className       = "blue_button"
                                    />
                                </div>
                                <br /> <br /> 
                            </div> 
                    
                    
                        </div>;
                
      
        var currencySymbol = ""
                        
        switch (this.state.varsJSON.currencySymbol) {
            case "$":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "€":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "￥":
                currencySymbol = <span style = {{"marginRight":"0px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
            case "£":
                currencySymbol = <span style = {{"marginRight":"2px"}}>{this.state.varsJSON.currencySymbol}</span>
                break;
        }
        
        var financedCashFlowReadoutClass    = (this.state.financedCashFlow < 0) ? 'readoutRed' : "readoutGreen";
        var financedCashFlowReadout         = this.state.varsJSON.currencySymbol + this.state.financedCashFlow + '/mo';
        
        var cashROIReadoutClass             = (this.state.cashROI < 0) ? 'readoutRed' : "readoutGreen";
        var cashROIReadout                  = this.state.cashROI + '%';
        
        var financedROIReadoutClass         = (this.state.financedROI < 0) ? 'readoutRed' : "readoutGreen";
        var financedROIReadout              = this.state.financedROI + '%';

      
        return (
            <div id="outer_canvas">
            <div className = "width900" >
            
                { overlayDOM }
                { helpDOM }
                
                <HeadlineDescription
                    varsJSON            = { this.state.varsJSON }
                    updateBasicParams   = { this.updateBasicParams }
                />        
                        
                <div className = "textAlignCenter width900">
                    <div className = "inlineBlock">
                        <Gauge
                            label           = "Monthly Cash Flow"
                            value           = { this.state.financedCashFlow }
                            readout         = { financedCashFlowReadout }
                            readoutClass    = { financedCashFlowReadoutClass }
                            min             = { this.state.gaugeMinMonthlyCashFlow }
                            max             = { this.state.gaugeMaxMonthlyCashFlow }
                        />
                    </div>
                    
                    <div className = "inlineBlock">
                        <Gauge
                            label   = "Cash ROI"
                            value           = { this.state.cashROI }
                            readout         = { cashROIReadout }
                            readoutClass    = { cashROIReadoutClass }
                            min             = { this.state.gaugeMinCashROI }
                            max             = { this.state.gaugeMaxCashROI }
                        />
                    </div>
                    
                    <div className = "inlineBlock">
                        <Gauge
                            label   = "Financed ROI"
                            value           = { this.state.financedROI }
                            readout         = { financedROIReadout }
                            readoutClass    = { financedROIReadoutClass }
                            min             = { this.state.gaugeMinFinancedROI }
                            max             = { this.state.gaugeMaxFinancedROI }
                        />
                    </div>
                </div> 
                             
                        
                <div className="paramsContainer module_box">
                    <div className="heading_bar">
                        <div className="left_heading text_blue" style={{"paddingLeft":"0"}}>Rent or Buy?</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_blue"  
                            type            = "text"  
                            defaultValue    = { params.title }
                            onBlur          = { function() { 
                                this.updateValues("1", {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                        <div className="right_heading">
                        	<div className="icon_help"      onClick={ this.showHelpDom }></div>
                            
                    		<div className="icon_blank"     ></div>
                            <TextToggle 
                                jsonKey             = { "base" }
                                moduleKey           = "currencySymbol"
                                classname           = "currencySymbol"
                                updateValues        = { this.updateValues }
                                value               = { this.state.varsJSON.currencySymbol }                             
                                choices             = { [ "$", "€", "￥", "£" ] }
                                handleUserEvents    = { this.handleUserEvents }
                            />
                        	<div className="clearBoth"></div>
                        </div>  
                    </div>
                            
                    I am considering the purchase or lease of a real estate investment property.  &nbsp;The purchase price of the property would be { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "propCost"
                        value               = { params.propCost }
                        step                = "1000"
                        growth              = "10"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />. &nbsp;
                    
                    My portion of the closing costs to purchase this property would be { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "closingCosts"
                        value               = { params.closingCosts }
                        step                = "500"
                        growth              = "10"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />. &nbsp;
                        
                        
                    If I were to finance, I would invest { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "downPmt"
                        value               = { params.downPmt }
                        step                = "1000"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> as a down payment. &nbsp;
                    
                    I would finance the balance at&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "intRate"
                        value               = { params.intRate }
                        step                = "0.05"
                        decimals            = "3"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                        />% interest for&nbsp;
                        
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "numMonths"
                        value               = { params.numMonths }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> months. &nbsp;
                    
                    
                    (I have used the <a href="https://www.crystalbull.com/True-Mortgage-Cost-Calculator/">CrystalBull.com True Mortgage Cost Calculator</a> to determine my real, after tax, interest rate.)
                    <br /><br />
                    
                    Net monthly payment, after tax savings: { currencySymbol }{this.state.monthlyPmt}. &nbsp; 
                    
                    (<input type        = "checkbox" 
                            ref         = "intOnly" 
                            checked     = {this.state.intOnly}
                            onChange    = {function() { this.handleCheckboxChange("1", "intOnly") }.bind(this) }
                    /> Check if mortgage is interest-only.)
                    <br /><br />
                            
                            
                    Property taxes on this property would be { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "propTaxes"
                        value               = { params.propTaxes }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> &nbsp;(CA buyers: Use % of purchase price below.&nbsp; 1% annually = .0833% monthly) per month. &nbsp;
                    
                    
                    Insurance would be { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "ins"
                        value               = { params.ins }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> per month. &nbsp;
                    
                    
                    Maintenance costs, assessments, property management fees would be estimated at { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "maint"
                        value               = { params.maint }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> per month. &nbsp;
                    
                    {`Homeowners'`} association dues would be { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "hoa"
                        value               = { params.hoa }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> per month.
                    <br /><br />
                        
                    I have some variable costs, which are&nbsp;    
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "variableCost"
                        value               = { params.variableCost }
                        step                = "0.005"
                        decimals            = "3"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />% of the purchase price, per month. &nbsp;
                    
                    I have other variable costs, which are&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "variableRent"
                        value               = { params.variableRent }
                        step                = "0.005"
                        decimals            = "4"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />% of the monthly rental income.
                    <br /><br />
                        
                    This property has a rental value of { currencySymbol }
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "rent"
                        value               = { params.rent }
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> per month. &nbsp;
                    
                    I will estimate an occupancy rate of&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "occupancy"
                        value               = { params.occupancy }
                        max                 = "100"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />%.
                    <br /><br />
                        
                        
                    I estimate the real appreciation rate (nominal appreciation rate, less inflation rate) of&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "appreciation"
                        value               = { params.appreciation }
                        min                 = "-20"
                        step                = "0.1"
                        decimals            = "1"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />% per year.
                    <br /><br />
                        
                    <input  type            = "checkbox" 
                            ref             = "depreciateIt" 
                            onChange        = {function() { this.handleCheckboxChange("1", "depreciateIt") }.bind(this) }
                    /> &nbsp;Add depreciation tax savings to cash flow. &nbsp;  
                    
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "taxDepPct"
                        value               = { params.taxDepPct }
                        max                 = "100"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />% of the purchase price is depreciable (buildings, etc., not land). &nbsp;  
                    
                    I will depreciate on a&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "taxDepYears"
                        value               = { params.taxDepYears }
                        max                 = "100"
                        step                = "0.5"
                        decimals            = "1"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    /> year straight line. &nbsp;  
                    
                    I am in the&nbsp;
                    <Slider 
                        jsonKey             = "1"
                        moduleKey           = "taxBracket"
                        value               = { params.taxBracket }
                        max                 = "100"
                        updateValues        = { this.updateValues }
                        handleUserEvents    = { this.handleUserEvents }
                    />% marginal income tax bracket.
                    <span className="displayNone"></span>
                </div>
                        </div>
                    
                
                        
                <SaveDialog
                    varsJSON            = { this.state.varsJSON }
                    varsChanged         = { this.state.varsChanged }
                    resetVarsChanged    = { this.resetVarsChanged }
                    thisDir             = "/rent-or-buy/"
                    thisKey             = { this.urlKey }
                    submitUrl           = "/save_rent_or_buy/"
                    messages            = {{
                                                saveExisting    : "Save This Data Set",
                                                saveAsNew       : "Save As New Data Set",
                                          }}
                    setOverlay          = { this.setOverlay }
                    clearOverlay        = { this.clearOverlay }
                />
                    
                <div className="bottomContainer">
                Known issues: Taxes on rental income not considered, esp. new 3.8% additional tax on passive income.  Principal reduction, calculated in "Financed ROI" is not accurate, as it is based on after tax interest rate.  Also consider that this value changes each year, so these are first-year ROIs, not ROE.  Depreciation does not appear in ROI.  Depreciation is a phantom deduction which must be recaptured later, presumably at higher tax rates than today.
                </div>
                                 
            </div>

      )            
  }
}

export default RentOrBuy;
