require("../styles/_Main.scss");
import { NavLink } from 'react-router-dom'

class Button extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var className = this.props.className  || "save_button";
        return (
            <div 
                className   = { className }
                onClick     = { function () { this.props.clickHandler( this.props.whichClicked ); }.bind(this) }
            >
                <NavLink to="#" onClick={e => e.preventDefault()}>{ this.props.text }</NavLink>
            </div>
        );
    }
}


export default Button;

