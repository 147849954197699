//                    require("../styles/_Common.scss");
//                    require("../styles/_Privacy_TnC.scss");

class Privacy extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
  render() {

      return (
          <div>

              Privacy test.

          </div>

      )            
  }
}


export default Privacy;
