require("../styles/_Main.scss");

import Slider       from "./Slider.jsx";

class AddFundsModule extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
             mounted    : false,
        };
    }
    
    componentDidMount() {
        // allows for animation.  Changing width
        setTimeout(
            function() {
        
                this.setState(
                    {
                        mounted     : true,
                    }
                );
            }.bind(this),
            0
        )
    }

    render() {
        

        var JSONkey         = this.props.JSONkey;

        var moduleClassName = ( this.props.module.minimized)
                                    ? "ADM_box module_box fullWidth module_minimized"
                                    : (this.props.module.active)
                                        ? "ADM_box module_box fullWidth module_visible"
                                        : "ADM_box module_box fullWidth module_minimized"

        if (!this.state.mounted) {
            moduleClassName = moduleClassName.replace("fullWidth", "zeroWidth");
        }
        
        var activeClassName;
                                                                               
        if ( this.props.module.active == false) {
            activeClassName = "inactive";
        } else {
            activeClassName = "displayNone";
        }
        
        var retireAfterTax  = this.props.module.retireWithdraw * (1 - this.props.module.taxRateRetire/100 * this.props.module.tdPercentage/100 );

        
        return (

            <div className={ moduleClassName }>
                <div className="heading_bar">
                    <div className="left_heading text_green">Add Funds</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_green"  
                            type            = "text"  
                            defaultValue    = { this.props.module.title }
                            onBlur          = { function() { 
                                this.props.updateValues(JSONkey, {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                    	<div className="right_heading">
                    		<div className={ activeClassName }>(inactive) &nbsp; &nbsp; </div>
                    		<div className="icon_help"      onClick={ this.props.showHelpDom }></div>
                            <div className="icon_minimize"  onClick={ function() { this.props.stepChoices(JSONkey, "minimized") }.bind(this) }></div>
                    		<div className="icon_down"      onClick={ function() { this.props.moveDown(JSONkey) }.bind(this) }></div>
                    		<div className="icon_up"        onClick={ function() { this.props.moveUp(JSONkey) }.bind(this) }></div>
                    		<div className="icon_highlight" onClick={ function() { this.props.stepChoices(JSONkey, "highlighted") }.bind(this) }></div>
                		
                            <div className="icon_toggle"    onMouseOver={ function() { this.props.showEffects(JSONkey, true) }.bind(this) }
                                                            onMouseOut={ function() { this.props.showEffects(JSONkey, false) }.bind(this) }></div>
                            <div className="icon_deactivate" onClick={ function() { this.props.toggleActive(JSONkey) }.bind(this) }></div>
                            <div className="icon_delete"    onClick={ function() { this.props.removeModule(JSONkey) }.bind(this) }></div>
                    		<div className="clearBoth"></div>
                    	</div>
                </div>

                <div className="module_content">
                    I plan to add an additional { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "addAmount"
                            updateValues         = { this.props.updateValues }
                            value               = { this.props.module.addAmount }
                            handleUserEvents    = { this.props.handleUserEvents }
                        /> per month to my account, 
                    for <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "addMonths"
                            updateValues         = { this.props.updateValues }
                            value               = { this.props.module.addMonths }
                            handleUserEvents    = { this.props.handleUserEvents }
                        /> months, 
                    starting at age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "addStartAge"
                            updateValues         = { this.props.updateValues }
                            max                 = "2000"
                            value               = { this.props.module.addStartAge }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />
                    <div className="inactive2">(inactive)</div>
                </div>
            </div>
        );
    }
}


export default AddFundsModule;

