import { min, max, scaleLinear, axisBottom, axisLeft, format, line, area, bisector } from 'd3';
import { select, selectAll } from "d3-selection";

const d3 = Object.assign(
  {},
  { select, selectAll,  min, max, scaleLinear, axisBottom, axisLeft, format, line, area, bisector}, //request
)


import {appendArray} from "./Common.jsx";
 
class LineChart extends React.Component {
    
    // We want to show a transition as user changes values, so we
    // write both the current chart, along with it previous value (ghostSeries)
    // Previous values upated after X seconds of inactivity, via setTimeout()
    // After this X seconds, we redraw without ghostSeries, so charts axes are updated
    
    constructor(props) {
        super(props);
            
        this.ref            = React.createRef();
        this.bisectXparam   = 0;
        this.draw           = this.draw.bind(this);
        this.formatValue    = this.formatValue.bind(this);
        this.fadeGhosts     = this.fadeGhosts.bind(this);
        this.mousemove      = this.mousemove.bind(this);
        this.getYahead      = this.getYahead.bind(this);
        this.customXAxis    = this.customXAxis.bind(this);
        this.customYAxis    = this.customYAxis.bind(this);
        
        this.ghostSeriesData = (this.props.showGhostSeries) ? this.props.ghostSeries : [];
        this.subSeriesData   = (this.props.subSeries) ? this.props.subSeries : [];

        this.showCursorData  = (this.props.showCursorData != undefined) ? this.props.showCursorData : true;
        //this.showGrid        = (this.props.showGrid != undefined) ? this.props.showGrid : true;
        this.showAxes        = (this.props.showAxes != undefined) ? this.props.showAxes : true;

        // Margin is for chart and Axes
        // labelPadding lets us control the location of Axis Labels
        this.margin          = this.props.margin || {top: 20, right: 20, bottom: 20, left: 40};
        this.labelPadding    = this.props.labelPadding || {top: 0, right: 18, bottom: 18, left: 18};
        this.DOMwidth        = this.props.width;
        this.DOMheight       = this.props.height;
        this.translate       = this.props.translate || {x: 0, y: 0};

        this.chart           = {
            top         : this.labelPadding.top + this.margin.top,
            bottom      : this.DOMheight - (this.labelPadding.bottom + this.margin.bottom),
            left        : this.labelPadding.left + this.margin.left,
            right       : this.DOMwidth - (this.labelPadding.right + this.margin.right),
            width       : this.DOMwidth - this.labelPadding.left - this.labelPadding.right - this.margin.left - this.margin.right,
            height      : this.DOMheight - this.labelPadding.top - this.labelPadding.bottom - this.margin.top - this.margin.bottom
        };

        this.xDataLabel     = this.props.xDataLabel;
        this.yDataLabel     = this.props.yDataLabel; 
    }
    
    componentDidMount() {
        this.svgParent = d3.select(this.ref.current)
            .append("svg")
            .attr("class", "module_visible"); //, // overflow:visible, so translated elems can be seen
    }
    
    componentDidUpdate() {
        this.ghostSeriesData = (this.props.showGhostSeries) ? this.props.ghostSeries : [];
        this.subSeriesData   = (this.props.subSeries) ? this.props.subSeries : [];
        
        // define x and y linear ranges, with min and max of all series. Allow zeros
        this.Xmin = (typeof this.props.Xmin == 'number') ? this.props.Xmin : d3.min(this.props.mainSeries, (d) => d[this.xDataLabel["name"]]);
        this.Xmax = (typeof this.props.Xmax == 'number') ? this.props.Xmax : d3.max(this.props.mainSeries, (d) => d[this.xDataLabel["name"]]);
        this.Ymin = (typeof this.props.Ymin == 'number') ? this.props.Ymin : d3.min(this.props.mainSeries, (d) => d[this.yDataLabel["name"]]);
        this.Ymax = (typeof this.props.Ymax == 'number') ? this.props.Ymax : d3.max(this.props.mainSeries, (d) => d[this.yDataLabel["name"]]);

        // keep separately, to avoid mouseover errors when zooming
        //this.XminMain    = this.Xmin;
        //this.XMaxMain    = this.Xmax;
        
        if (this.props.showGhostSeries && this.ghostSeriesData.length > 0) {
            this.Xmin = Math.min(this.Xmin, d3.min(this.ghostSeriesData, (d) => d[this.xDataLabel["name"]]) );
            this.Ymin = Math.min(this.Ymin, d3.min(this.ghostSeriesData, (d) => d[this.yDataLabel["name"]]) );
            this.Xmax = Math.max(this.Xmax, d3.max(this.ghostSeriesData, (d) => d[this.xDataLabel["name"]]) );
            this.Ymax = Math.max(this.Ymax, d3.max(this.ghostSeriesData, (d) => d[this.yDataLabel["name"]]) );
        }

        this.xScale = d3.scaleLinear()
            .range([this.chart.left, this.chart.right])
            .domain([ this.Xmin, this.Xmax ]);

        this.yScale = d3.scaleLinear()
            .range([ this.chart.bottom, this.chart.top ])
            .domain([ this.Ymin, this.Ymax ]);
    
        // customize the axes
        this.xAxis = d3.axisBottom(this.xScale)
            .ticks(10)
            .tickSizeInner(-this.chart.height)
            .tickSizeOuter(-this.chart.height)
            .tickPadding(5);

        this.yAxis = d3.axisLeft(this.yScale)
            //.tickArguments(["color:red"])
            .ticks(10, this.yDataLabel["name"])
            .tickFormat(d3.format("." + this.yDataLabel["decimals"] + "f"))
            .tickSizeInner(-this.chart.width)
            .tickSizeOuter(-this.chart.width)
            .tickPadding(5);
            
            
        this.svgParent.select("*").remove(); // or selectAll
        if (this.props.mainSeries || this.props.subSeries) {
            this.draw();
        }
        
    }
    
    draw() {
    
        // define the lines
        var seriesLine = d3.line()
            .x( (d) => this.xScale(d[this.xDataLabel["name"]]) )
            .y( (d) => this.yScale(d[this.yDataLabel["name"]]) );

        // For Area chart, we separate into positive and negative data sets
    
        var posDataSet = [];
        var negDataSet = [];
        
        for (var index in this.props.mainSeries) {
            if ( this.props.mainSeries[index][this.yDataLabel["name"]] >= 0) {
                posDataSet.push(this.props.mainSeries[index]);
            } else {
                negDataSet.push(this.props.mainSeries[index]);
            }
        }
    
        var Y0 = (this.Ymin > 0)
                    ? this.Ymin
                    : (this.Ymax < 0)
                        ? this.Ymax
                        : 0
    
        var seriesArea = d3.area()
            .x( (d) => this.xScale(d[this.xDataLabel["name"]]) )
            .y1( (d) => this.yScale(d[this.yDataLabel["name"]]) )
            .y0(this.yScale(Y0))//.y0(this.chart.bottom);

        // In IE: parent svg can't translate or x: y:, so put everything in group
        var svg = this.svgParent.append("g")
            .attr("transform", "translate(" + this.translate.x + ", " + this.translate.y + ")");
        
        svg.append("rect")
            //.attr("viewBox", this.translate.x + " " + this.translate.y + " " + this.DOMwidth + " " + this.DOMheight)
            .attr("width",  this.DOMwidth)
            .attr("height", this.DOMheight)
            .attr("class",  "border1");
            
        // Title
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform",   "translate("+ (this.DOMwidth/2) + "," +  (this.margin.top*2/3) + ")")
            .attr("class",       "chartTitle")
            .text(this.props.title);

        // mouseover values at top-right
        svg.append("text")
            .attr("class",       "mouseoverValuesText")
            .attr("text-anchor", "end")
            .attr("transform",   "translate(" + this.chart.right + ", " +  (this.margin.top*2/3) + ")");
            
        if (this.showAxes) {
            // X Axis
            svg.append("g")
                .attr("transform", "translate(0," + this.chart.bottom + ")")
                .call(this.customXAxis, this.xAxis); // can call special functions to alter things after setup
        
            // Y Axis
            svg.append("g")
                .attr("class", "yAxis")
                .call(this.customYAxis, this.yAxis);
        
            // X Axis Label
            svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform",   "translate("+ (this.DOMwidth/2) + "," + (this.DOMheight-(this.labelPadding.bottom/3))+")")
                .attr("class",       "axisLabels")
                .text(this.xDataLabel["prefix"] + this.xDataLabel["name"] + this.xDataLabel["suffix"]);
        
            // Y Axis Label
            svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform",   "translate("+ (this.labelPadding.left/3) + "," + (this.DOMheight/2) + ")rotate(-90)")
                .attr("dy",          ".71em")
                .attr("class",       "axisLabels")
                .text(this.yDataLabel["prefix"] + this.yDataLabel["name"] + this.yDataLabel["suffix"]);
        }

        // if X ranges changed so there is no data
        if ( this.props.mainSeries.length < 1) {
            svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform",   "translate("+ (this.DOMwidth/2) + "," + (this.DOMheight/2) + ")")
                .attr("dy",          ".71em")
                .attr("class",       "axisLabels")
                .text("Ending " + this.xDataLabel["name"] + " must be larger than starting " + this.xDataLabel["name"] + ".");
        
            return (
                <div className  = {"visible"} 
                     ref        = { this.ref } 
                />
            );
        }
    
        // Draw areas (pos & neg) (with no stroke), then put path on it later
        svg.append("path")
            .datum(posDataSet)
            .attr("d",     seriesArea)
            .attr("class", "area")
            .attr("fill",  "lightblue")
        
        svg.append("path")
            .datum(negDataSet)
            .attr("d",     seriesArea)
            .attr("class", "area")
            .attr("fill",  "#ff3330");
        
        // Draw paths
        svg.append("path")
            .datum(this.props.mainSeries)
            .attr("d",     seriesLine)
            .attr("class", "path");
    
        // draw ghostSeries
        if (this.props.showGhostSeries) {
            var ghostArea = svg.append("path")
                .datum(this.ghostSeriesData)
                .attr("d",      seriesArea)
                .attr("class", "ghostArea fadeThis");
        
            // Draw paths
            var ghostPath = svg.append("path")
                .datum(this.ghostSeriesData)
                .attr("d",      seriesLine)
                .attr("class", "ghostPath fadeThis")
                .call(this.fadeGhosts.bind(this));
        }
    
        if (this.showCursorData) {
            var cursorGroup = svg.append("g")
                .attr("width",  100)
                .attr("class",  "cursorGroup")
                .style("display", "none")
        
            cursorGroup.append("rect")
                .attr("width", 1)
                .attr("height", this.chart.height)
                .attr("class", "cursorLine");  
        

            cursorGroup.append("text")
                .attr("class", "cursorText")
                .attr("x", 10)
                .attr("y", "1em");
        

            cursorGroup.append("circle")
                .attr("r",      3)
                .attr("class", "cursorCircle");
        
            svg.append("rect")
                .attr("class",     "transparentOverlay")
                .attr("width",     this.chart.width)
                .attr("height",    this.chart.height)
                .attr("transform", "translate(" + this.chart.left + ", " + this.chart.top + ")")
                .on("mouseover",    function() { d3.select(".cursorGroup").style("display", null); })
                .on("mouseout",     function() { d3.select(".cursorGroup").style("display", "none"); })
                .on("mousemove",    this.mousemove)
                .on("touchstart",   function() { d3.select(".cursorGroup").style("display", null); })
                .on("touchend",     function() { d3.select(".cursorGroup").style("display", "none"); })
                .on("touchmove",    this.mousemove);
            }
    
        // draw highlighted ranges
        var highlightSeries = this.props.highlightSeries || [];
    

        if (highlightSeries.length > 0) {
            var highlightWidth  = 0.0;
        
            var highlightArea = d3.area()
                .x( (d) => this.xScale(d[this.xDataLabel["name"]]) )
                .y1(this.chart.top)
                .y0(this.chart.bottom);
            
            
                for ( var j=0; j<highlightSeries.length; j++) {
                
                    highlightWidth = this.xScale(highlightSeries[j][1]) - this.xScale(highlightSeries[j][0]);
                
                    if (highlightWidth > 0) {   // user's rangese in bounds
                        highlightGroup.append("rect")
                            .attr("width",     highlightWidth)
                            .attr("height",    this.chart.height)
                            .attr("class",     highlightSeries[j][2])
                            .attr("transform", "translate(" + this.xScale(highlightSeries[j][0]) + ", " + this.chart.top + ")");
                    } else {
                        // e.g. short sale.  bought before sold
                    
                        continue;   // is we allow short sale, also need to restructure the finance charge
                    
                        highlightGroup.append("rect")
                            .attr("width",     -highlightWidth)
                            .attr("height",    this.chart.height)
                            .attr("class",     highlightSeries[j][2])
                            .attr("transform", "translate(" + this.xScale(highlightSeries[j][1]) + ", " + this.chart.top + ")");
                    }  
                }
        }
    
        // draw subSeries
        if (this.subSeriesData.length > 0) {
            // draw white fill
            svg.append("path")
                .datum(this.subSeriesData)
                .attr("d",    seriesArea)
                .attr("fill", "white");
        
            // Draw paths
            svg.append("path")
                .datum(this.subSeriesData)
                .attr("d", seriesLine)
                .attr("class", "path");
        }
        
        
        this.bisectXparam = d3.bisector( (d) => d[this.xDataLabel["name"]]).left;
    }
    
    mousemove(event) {
        event.preventDefault();
    
        //var mouseX = d3.event.layerX;
        var mouseX = event.layerX;  
    
        var xParam = this.xScale.invert( mouseX );   // exact Xaxis lookup, based on scale given
    
        // lookup from mouse position xParam falls between 2 actual data points, 
        // so find closest real one
    
        // make sure it's in range (can be out of range if x range decreases (ghostSeries wider than mainSeries))
        // set to mainSeries min or max if off chart
        xParam = Math.max(Math.min(xParam, this.Xmax), this.Xmin);

        var JSONpos     = this.bisectXparam(this.props.mainSeries, xParam, 0, this.props.mainSeries.length);
        var prevDatum   = ( JSONpos > 0 ) ? this.props.mainSeries[JSONpos - 1] : this.props.mainSeries[JSONpos];
        var Datum       = this.props.mainSeries[JSONpos];

        var match       = (xParam - prevDatum[this.xDataLabel["name"]]) < (Datum[this.xDataLabel["name"]] - xParam) 
                                ? prevDatum 
                                : Datum;

        d3.select(".cursorGroup rect")
            .attr("transform", "translate(" + this.xScale(match[this.xDataLabel["name"]]) + ", " +  this.chart.top + ")")
                        
        d3.select(".cursorGroup circle")
            .attr("transform", "translate(" + this.xScale(match[this.xDataLabel["name"]]) + ", " + this.yScale(match[this.yDataLabel["name"]])  + ")")
        d3.select(".cursorGroup text")  
                                .selectAll("tspan").remove()
                                
        // adjust Y of cursorGroup
                        
        var yLookAhead  = this.getYahead(mouseX);
        var dyAdjust    = -2.2;

        if (yLookAhead > match[this.yDataLabel["name"]]) {
            // positive slope
            dyAdjust    = 0;
        }
                        
        d3.select(".cursorGroup text")
            .attr("transform", "translate(" + this.xScale(match[this.xDataLabel["name"]]) + ", " + this.yScale(match[this.yDataLabel["name"]]) + ")")
    
            .append('svg:tspan')
                .attr("class", "cursorText")
                .attr('x',     8)
                .attr('dy',     dyAdjust + "em")
                .text( this.xDataLabel["name"] + ": " + this.formatValue(match[this.xDataLabel["name"]], this.xDataLabel["prefix"], this.xDataLabel["suffix"], this.xDataLabel["decimals"]) )
            .append('svg:tspan')
                .attr("class", "cursorText")
                .attr('x',     8)
                .attr('dy',   "1.1em")
                .text( this.yDataLabel["name"] + ":"  + this.formatValue(match[this.yDataLabel["name"]], this.yDataLabel["prefix"], this.yDataLabel["suffix"], this.yDataLabel["decimals"]) );
    

        // top-right display     
        d3.select(".mouseoverValuesText")
                .text( this.xDataLabel["name"] + ": " + this.formatValue(match[this.xDataLabel["name"]], this.xDataLabel["prefix"], this.xDataLabel["suffix"], this.xDataLabel["decimals"]) + ",     " + this.yDataLabel["name"] + ":"  + this.formatValue(match[this.yDataLabel["name"]], this.yDataLabel["prefix"], this.yDataLabel["suffix"], this.yDataLabel["decimals"]) );
            
            
        if (this.props.cursorExtra && this.props.cursorExtra == "percentile" ) {
            var percentileTotal = 0;
            for (var i=0; i<=JSONpos; i++) {
                percentileTotal += this.props.mainSeries[i]["%"];
            }
            /*d3.select(".cursorGroup text")
                .append('svg:tspan')
                    .attrs({
                        "class" : "cursorText",
                        'x'     : 8,
                        'dy'    : "1.1em",
                    })
                    .text( "Percentile: " + Math.round(percentileTotal) );
            */
            d3.select("svg text")
                .append('svg:tspan')
                .text( ",     Percentile: " + Math.round(percentileTotal) );
        }
    
        //this.svgParent.append("svg")
        //    .attr("class", "lineChart");
    }

    getYahead(mouseX) {
        //  Trick to keep cursorGroup away from positive or negative sloping line
        //  text is approx. 100 pixels wide
                            
        var xLookAhead = this.xScale.invert( mouseX + 15 );
            xLookAhead = Math.max(Math.min(xLookAhead, this.Xmax), this.Xmin);
    
        var JSONpos     = this.bisectXparam(this.props.mainSeries, xLookAhead, 0, this.props.mainSeries.length);
        var prevDatum   = ( JSONpos > 0 ) ? this.props.mainSeries[JSONpos - 1] : this.props.mainSeries[JSONpos];
        var Datum       = this.props.mainSeries[JSONpos];
    
        var match       = (xLookAhead - prevDatum[this.xDataLabel["name"]]) < (Datum[this.xDataLabel["name"]] - xLookAhead) 
                                ? prevDatum 
                                : Datum;
                            
        return match[this.yDataLabel["name"]];
    }

    customXAxis(g, xAxis) {
        g.call(xAxis);
        g.selectAll(".tick:not(:first-of-type) line")
            .attr("stroke",  "#aaa")
            .attr("stroke-dasharray",  "2,2");
    }

    customYAxis(g, yAxis) {
        g.call(yAxis)
            .attr("transform", "translate(" + this.chart.left + ", " + 0 + ")");
            //g.select(".domain").remove();
            //g.selectAll(".tick line")

        g.selectAll(".tick:not(:first-of-type) line")
            .attr("stroke", "#aaa")
            .attr("stroke-dasharray", "2,2");
    }
    
    
    formatValue(value, prefix, suffix, decimals) {
        var formatVal = d3.format(",." + decimals + "f");
        return prefix + formatVal(value) + suffix;
    }

  
    fadeGhosts() {
        //if ( !(this.props.mousedown || this.props.keydown) && this.props.killGhost ) {
        if ( this.props.killGhost ) {
            d3.selectAll(".fadeThis").transition().duration(3000).style('opacity', 0);
        } else {
            //console.log("not killing: this.props.killGhost: " + this.props.killGhost)
        }
    }
    
    render() {
        return (
            <div className  = {"visible"} 
                 ref        = { this.ref } 
            />
        );
    }

}

export default LineChart;
