require("../styles/_Main.scss");

import Slider           from "./Slider.jsx";
import TextToggle       from "./TextToggle.jsx";

import {roundNumber}    from "./Common.jsx"

class AssetPurchaseModule extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mounted     : false,
            agePurchase : this.props.module.agePurchase 
        };

        this.updateSellPrice        = this.updateSellPrice.bind(this);
        this.handleDependentParams  = this.handleDependentParams.bind(this);
    }
    
    componentDidMount() {
        // allows for animation.  Changing width
        setTimeout(
            function() {
        
                this.setState(
                    {
                        mounted     : true,
                    }
                );
            }.bind(this),
            0
        )
    }
    
    handleDependentParams(key, value) {
        // store independent in state, so dependent can call it.
        // e.g. selling age should not be before purchase age.
        var obj  = {}
        obj[key] = value;
        this.setState(obj);
    }

    updateSellPrice(jsonKey, moduleKey, newAppDepRate) {
        // changing appDepRate changes final selling price of asset
        //  FV = PV * (1 + r)^n
        
        var n           = Math.round( ( parseFloat(this.props.module.ageSell) - parseFloat(this.props.module.agePurchase) ) * 12);
        var PV          = parseFloat(this.props.module.assetPrice);
        var FV_new      = PV * Math.pow(1 + newAppDepRate/1200, n);
        
        this.props.updateValues(this.props.JSONkey, {"sellPrice": FV_new});
    }
    
    render() {
        
        // In separate account, we handle asset appreciation/depreciation
        // let's find the consistent earnings rate, not just straight-line
        // straight line: (sell_price - asset_price) / num_months;
        // compounded: FV = PV * (1 + r)^n
        var n           = Math.round( ( parseFloat(this.props.module.ageSell) - parseFloat(this.props.module.agePurchase) ) * 12);
        var PV          = parseFloat(this.props.module.assetPrice);
        var FV          = parseFloat(this.props.module.sellPrice);
        var appDepAPR   = (Math.pow(FV/PV, 1/n) - 1) * 1200;
        
        var intRate         = parseFloat( this.props.module.intRate ) / 1200;
        var amtFinanced     = PV - parseFloat( this.props.module.downPmt );
        var pmtMonths       = parseFloat( this.props.module.pmtMonths );
        
        //console.log(intRate, amtFinanced, pmtMonths)
        
        var moPmt = ( intRate == 0 )
                        ? roundNumber( amtFinanced / pmtMonths, 2)
                        : roundNumber( (intRate * amtFinanced) / (1 - Math.pow((1 + intRate), -pmtMonths)), 2);
        
        

        var JSONkey     = this.props.JSONkey;
        
        var moduleClassName = ( this.props.module.minimized)
                                    ? "APM_box module_box fullWidth module_minimized"
                                    : (this.props.module.active)
                                        ? "APM_box module_box fullWidth module_visible"
                                        : "APM_box module_box fullWidth module_minimized"

        if (!this.state.mounted) {        
            moduleClassName = moduleClassName.replace("fullWidth", "zeroWidth");
        } 
        
        var activeClassName;
                                                                               
        if ( this.props.module.active == false) {
            activeClassName                = "inactive";
        } else {
            activeClassName                = "displayNone";
        }
        
        var displayFinanceClassName =   ( this.props.module.payMethod == "Pay Cash" )
                                            ? "displayNone"
                                            : "_toggle_div";
        
        var appDepSpan  =               ( appDepAPR >= 0 ) 
                                            ? "(effective appreciation rate: "
                                            : "(effective depreciation rate: ";
                                            
        //var agePurchaseValue = this.refs.agePurchase.value;
        
        return (

            <div className={ moduleClassName }>
                <div className="heading_bar">
                    <div className="left_heading text_gold">Asset Purchase</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_gold"  
                            type            = "text"  
                            defaultValue    = { this.props.module.title }
                            onBlur          = { function() { 
                                this.props.updateValues(JSONkey, {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                    	<div className="right_heading">
                    		<div className={ activeClassName }>(inactive) &nbsp; &nbsp; </div>
                    		<div className="icon_help"      onClick={ this.props.showHelpDom }></div>
                            <div className="icon_minimize"  onClick={ function() { this.props.stepChoices(JSONkey, "minimized") }.bind(this) }></div>
                    		<div className="icon_down"      onClick={ function() { this.props.moveDown(JSONkey) }.bind(this) }></div>
                    		<div className="icon_up"        onClick={ function() { this.props.moveUp(JSONkey) }.bind(this) }></div>
                    		<div className="icon_highlight" onClick={ function() { this.props.stepChoices(JSONkey, "highlighted") }.bind(this) }></div>
                		
                            <div className="icon_toggle"    onMouseOver={ function() { this.props.showEffects(JSONkey, true) }.bind(this) }
                                                            onMouseOut={ function() { this.props.showEffects(JSONkey, false) }.bind(this) }></div>
                            <div className="icon_deactivate" onClick={ function() { this.props.toggleActive(JSONkey) }.bind(this) }></div>
                            <div className="icon_delete"    onClick={ function() { this.props.removeModule(JSONkey) }.bind(this) }></div>
                    		<div className="clearBoth"></div>
                    	</div>
                </div>
                
                <div className="module_content">
                    I plan to purchase this asset at age &nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "agePurchase"
                            updateValues        = { this.props.updateValues }
                            max                 = "150"
                            value               = { this.props.module.agePurchase }
                            handleUserEvents    = { this.props.handleUserEvents }
                            handleDependentParams= { this.handleDependentParams }
                        />. &nbsp; 
                    The purchase price will be { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "assetPrice"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.assetPrice }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />, including expenses, 
                            and I will &nbsp;
                        <TextToggle 
                            jsonKey             = { JSONkey }
                            moduleKey           = "payMethod"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.payMethod }
                            choices             = { this.props.module.payChoices }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />.<br /> 
                    <div className="inactive2">(inactive)</div>
                    <div className={ displayFinanceClassName }><br /> 
                        I will put { this.props.currencySymbol }
                            <Slider 
                                jsonKey             = { JSONkey }
                                moduleKey           = "downPmt"
                                updateValues        = { this.props.updateValues }
                                value               = { this.props.module.downPmt }
                                handleUserEvents    = { this.props.handleUserEvents }
                            /> as a down payment, 
                                and finance the balance for &nbsp;
                            <Slider 
                                jsonKey             = { JSONkey }
                                moduleKey           = "pmtMonths"
                                updateValues        = { this.props.updateValues }
                                value               = { this.props.module.pmtMonths }
                                handleUserEvents    = { this.props.handleUserEvents }
                            /> months, 
                                at &nbsp;
                            <Slider 
                                jsonKey             = { JSONkey }
                                moduleKey           = "intRate" 
                                updateValues        = { this.props.updateValues }
                                decimals            = "3"
                                step                = "0.01"
                                value               = { this.props.module.intRate }
                                handleUserEvents    = { this.props.handleUserEvents }
                            />% interest (nominal APR. Monthly Pmt: { this.props.currencySymbol }{ moPmt }). 
                    </div><br /> 
                    I plan to sell this asset at age &nbsp;
                        <Slider 
                            jsonKey                 = { JSONkey }
                            moduleKey               = "ageSell"
                            updateValues            = { this.props.updateValues }
                            value                   = { this.props.module.ageSell }
                            handleUserEvents        = { this.props.handleUserEvents }
                            min                     = { this.state.agePurchase }
                        /> &nbsp;
                    
                    for { this.props.currencySymbol }
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "sellPrice"
                            updateValues        = { this.props.updateValues }
                            value               = { this.props.module.sellPrice }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />. &nbsp; 
                    <span className="_app_dep">{ appDepSpan }</span>
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "appDepRate"
                            updateValues        = { this.updateSellPrice }
                            min                 = "-100"
                            max                 = "100"
                            step                = "0.01"
                            decimals            = "3"
                            value               = { appDepAPR }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% APR). 
                </div>
            </div>
        );
    }
}


export default AssetPurchaseModule;

