require("../styles/_Main.scss");

import Slider       from "./Slider.jsx";

class AdvisorModule extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
             mounted    : false,
        };
    }
    
    componentDidMount() {
        // allows for animation.  Changing width
        setTimeout(
            function() {
        
                this.setState(
                    {
                        mounted     : true,
                    }
                );
            }.bind(this),
            0
        )
    }

    render() {
        

        var JSONkey         = this.props.JSONkey;

        var moduleClassName = ( this.props.module.minimized)
                                    ? "AAF_box module_box fullWidth module_minimized"
                                    : "AAF_box module_box fullWidth module_visible"

        if (!this.state.mounted) {
            moduleClassName = moduleClassName.replace("fullWidth", "zeroWidth");
        }
        
        var activeClassName;
                                                                               
        if ( this.props.module.active == false) {
            activeClassName = "inactive";
        } else {
            activeClassName = "displayNone";
        }
        
        return (

            <div className={ moduleClassName }>
                <div className="heading_bar">
                    <div className="left_heading text_orange">Adjust for Advisor Fees</div>
                        <input 
                            ref             = "title"
                            className       = "center_heading text_orange"  
                            type            = "text"  
                            defaultValue    = { this.props.module.title }
                            onBlur          = { function() { 
                                this.props.updateValues(JSONkey, {"title": this.refs.title.value}) }.bind(this)
                            }
                            maxLength       = "60"
                        />
                	<div className="right_heading">
                		<div className={ activeClassName }>(inactive) &nbsp; &nbsp; </div>
                		<div className="icon_help"      onClick={ this.props.showHelpDom }></div>
                        <div className="icon_minimize"  onClick={ function() { this.props.stepChoices(JSONkey, "minimized") }.bind(this) }></div>
                		<div className="icon_down"      onClick={ function() { this.props.moveDown(JSONkey) }.bind(this) }></div>
                		<div className="icon_up"        onClick={ function() { this.props.moveUp(JSONkey) }.bind(this) }></div>
                		<div className="icon_highlight" onClick={ function() { this.props.stepChoices(JSONkey, "highlighted") }.bind(this) }></div>
                		
                        <div className="icon_toggle"    onMouseOver={ function() { this.props.showEffects(JSONkey, true) }.bind(this) }
                                                        onMouseOut={ function() { this.props.showEffects(JSONkey, false) }.bind(this) }></div>
                        <div className="icon_deactivate" onClick={ function() { this.props.toggleActive(JSONkey) }.bind(this) }></div>
                        <div className="icon_delete"    onClick={ function() { this.props.removeModule(JSONkey) }.bind(this) }></div>
                		<div className="clearBoth"></div>
                	</div>
                </div>
                            
    
                <div className="module_content">
                    At age&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeStartAge"
                            value               = { this.props.module.feeStartAge }
                            min                 = "0"
                            max                 = "200"
                            decimals            = "0"
                            updateValues         = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                            handleDependentParams= { this.handleDependentParams }
                        />, 
                            
                    I will place&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feePctUnderAdvisor"
                            value               = { this.props.module.feePctUnderAdvisor }
                            max                 = "100"
                            decimals            = "0"
                            updateValues         = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% of my account with an investment advisor or hedge fund. &nbsp;
                        
                    I expect this advisor to earn a&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeReturn"
                            value               = { this.props.module.feeReturn }
                            step                = "0.05" 
                            decimals            = "2"
                            updateValues        = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% average annual return, before fees. <br /><br />
                        
                    This advisor will charge me a&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeAnnualPct"
                            value               = { this.props.module.feeAnnualPct }
                            max                 = "20"
                            step                = "0.05" 
                            decimals            = "2"
                            updateValues        = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% annual fee,&nbsp; 
                        
                    in addition to a fee of&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeCarry"
                            value               = { this.props.module.feeCarry }
                            max                 = "60"
                            step                = "0.05" 
                            decimals            = "2"
                            updateValues        = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% of the annual profits (carry). &nbsp;
                        
                    This advisor will place&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeLoadFraction"
                            value               = { this.props.module.feeLoadFraction }
                            max                 = "100"
                            decimals            = "0"
                            updateValues        = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />% of my account into load funds,&nbsp;
                        
                    with an average sales fee (load) of&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeLoad"
                            value               = { this.props.module.feeLoad }
                            max                 = "60"
                            step                = "0.05" 
                            decimals            = "2"
                            updateValues        = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                        />%. <br /><br />
                            
                    Each year, this advisor will move&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeChurn"
                            value               = { this.props.module.feeChurn }
                            max                 = "100"
                            step                = "0.5" 
                            decimals            = "1"
                            updateValues         = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                            />% of my account from fund to fund (churn). <br /><br />
                            
                    I will leave this advisor at age&nbsp;
                        <Slider 
                            jsonKey             = { JSONkey }
                            moduleKey           = "feeEndAge"
                            value               = { this.props.module.feeEndAge }
                            min                 = "0"
                            max                 = "200"
                            decimals            = "0"
                            updateValues         = { this.props.updateValues }
                            handleUserEvents    = { this.props.handleUserEvents }
                            handleDependentParams= { this.handleDependentParams }
                        />.
                            
                    <div className="inactive2">(inactive)</div><br /><br />
                    
                </div>
            </div>
        );
    }
}


export default AdvisorModule;

