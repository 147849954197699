require("../styles/_Main.scss");
    
import BarChart             from "./BarChart.jsx";
import HorzStackedBarChart  from "./HorzStackedBarChart.jsx";
import LineChart          from "./LineChart.jsx";
//import Button             from "./Button.jsx";
import Slider               from "./Slider.jsx";
import TextSlider           from "./TextSlider.jsx";
import TextToggle           from "./TextToggle.jsx";

import {roundNumber, displayFormatted} from "./Common.jsx"


class IncIneqTool extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            varsJSON        : {
                xMin        : -10,
                xMax        : 110,
                yMin        : 0.0,
                yMax        : 100.0,
                case        : "gini = 0",
                middleClass : "Not highlighting median & middle class",
                growth      : 0.0,
                rateAt      : 100.0,
                rate        : 3.5,
                periods     : 0
            },
            mainSeries      : testJSON["gini = 0"]["income"],
            highlightSeries : [],
            medianSeries    : [],
            showMiddleClass : false,
            mousedown       : false,
            keydown         : false,
            descFocused     : false,
            varsChanged     : false,
            killGhost       : false,
            giniSeries      : [],
            lorenzSeries    : [],
            giniCoefficient : 0.0,
            numBars         : 0
        };

        this.testCases        = [
            "gini = 0",
            "gini = 1",
            "2 classes",
            "4 classes",
            "5 classes",
            "5 classes (small migration)",
            "5 classes (more migration)",
            "5 classes (upper migration)",
            "5 classes ($1M 1%)",
            "2017",
            "2017 w/o btm 10%",
            "2017 w/o top 1%",
            "2017 w/o both",
            "2017 MW current",
            "2017 MW ideal",
            "2017 MW spread",
            "2017 MW actual"
        ];
        
        this.middleClassCases   = [ "Highlighting median & middle class", "Not highlighting median & middle class"];
        
        this.handleUserEvents   = this.handleUserEvents.bind(this);
        this.updateValues       = this.updateValues.bind(this);
        this.createGiniSeries   = this.createGiniSeries.bind(this);
        this.calcNumBars        = this.calcNumBars.bind(this);
    }
    
    componentDidMount() {
        this.createGiniSeries();
    } 
    
    calcNumBars() {
        // call this when change: year, xMin, xMax, inflation.  Not needed for highlight
        // not exact if there are spaces in the series
        var numBars = 0;
        for (var j = 0; j < this.state.mainSeries.length; j++) {
            if (this.state.mainSeries[j]["$"] >= this.state.varsJSON.xMin && this.state.mainSeries[j]["$"] <= this.state.varsJSON.xMax) {
                numBars ++;
            }
        }
        
        this.setState(
            {
                numBars    : numBars
            }
        );
    }
    
    createGiniSeries() {
        // We also highlight the middle class using this function
        
        var totalIncome     = 0.0, // approx weighted by %*100 / 1000 / total pop
            i               = 0,
            mainSeries      = this.state.mainSeries;
            
        for (i=0; i < mainSeries.length; i++) {
            totalIncome += parseFloat(mainSeries[i]["%"]) / 100 * parseInt(mainSeries[i]["$"]);
        }
        
        var giniSeries      = [{a:0, b:0}];
        var lorenzSeries    = [{a:0, b:0}];
        var highlightSeries = [];
        var medianSeries    = [];
        var giniIncome      = 0.0,
            lorenzIncome    = 0.0,
            lorenzPct       = 0.0,
            giniArea        = 0.0,
            deltaArea       = 0.0,
            samplePct       = 0.0,
            sampleIncome    = 0.0,
            prevLorenzInc   = 0.0,
            prevGiniInc     = 0.0,
            cumulative      = 0.0,
            gotMedian       = false;
        
        for (i=0; i < mainSeries.length; i++) {
            
            samplePct       = mainSeries[i]["%"] / 100.0;
            sampleIncome    = mainSeries[i]["$"];
            
            lorenzIncome    += (samplePct * sampleIncome);
            lorenzPct       += samplePct;
            giniIncome      =  lorenzPct * totalIncome;

            giniSeries.push(    {a : lorenzPct, b: giniIncome } );
            lorenzSeries.push(  {a : lorenzPct, b: lorenzIncome });
            
            // to get area more accurately, get midpoint of area bar
            
            giniArea        += samplePct * (giniIncome + prevGiniInc) / 2;
            deltaArea       += samplePct * (lorenzIncome + prevLorenzInc) / 2;
            
            prevGiniInc     = giniIncome;
            prevLorenzInc   = lorenzIncome;
            
            if ( this.state.showMiddleClass ) {
                cumulative += mainSeries[i]["%"];
                if (cumulative >= 20 && cumulative <= 80) {
                    highlightSeries.push( mainSeries[i] );
                }
                if (!gotMedian && cumulative >= 50) {
                    medianSeries.push( mainSeries[i] );
                    gotMedian = true;
                }
            } 
        }

        this.setState(
            {
                giniSeries      : giniSeries,
                lorenzSeries    : lorenzSeries,
                giniCoefficient : Math.round((giniArea - deltaArea) / giniArea * 1000) / 10,
                highlightSeries : highlightSeries,
                medianSeries    : medianSeries
            }
        );
    }
    
    handleUserEvents(key, value) {
        // mousedown handles touchstart too
        // keydown
        var obj  = {}
        obj[key] = value;

        this.setState(obj, function() {
            if ( !value && this.state.killGhost) {     // either keydown or mousedown is false
                this.killGhost( this.killGhostTimeout ); 
            }
        });
    }
    
    updateValues(jsonKey, valueDict) {        
        // this is a synchronous setting of state/calling new chart after sliders change
        // valueDict in form {moduleKey: value}
        
        // make deep copy, so state doesn't change while manipulating
        var toUpdateJSON = JSON.parse(JSON.stringify( this.state.varsJSON ));
        
        Object.keys(valueDict).map(function(key) {
            toUpdateJSON[key] = valueDict[key];
        });
        
        if (jsonKey == "case") {
            
            toUpdateJSON["growth"] = 0;
            toUpdateJSON["rateAt"] = 100;
            
            if ( valueDict[jsonKey].indexOf("2017") > -1 ) {
                toUpdateJSON["xMax"] = 350;
                toUpdateJSON["yMax"] = 1.5;
            } else {
                toUpdateJSON["xMax"] = 110;
                toUpdateJSON["yMax"] = 100;
            }
            
            // make deep copy, so state doesn't change while manipulating
            if ( valueDict[jsonKey].indexOf("MW") > -1 ) {
                var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[2017]["income"]));
                toUpdateJSON["xMax"] = 60;
                toUpdateJSON["yMax"] = 2.5;
                
                var cumulativePct = 0.0;
                
                if (valueDict[jsonKey].indexOf("ideal") > -1) {
                    for (var i=0; i < toUpdateSeries.length; i++) {
                        cumulativePct += toUpdateSeries[i]["%"];
                        
                        if ( toUpdateSeries[i]["$"] > 15 && toUpdateSeries[i]["$"] < 30 ) {
                            toUpdateSeries[i]["%"] *= 0.9;
                        } else if ( toUpdateSeries[i]["$"] == 30 ) {
                            toUpdateSeries[i]["%"] *= ( cumulativePct * 0.1 );
                        }
                    }
                } else if (valueDict[jsonKey].indexOf("spread") > -1) {
                    for (var i=0; i < toUpdateSeries.length; i++) {
                        cumulativePct += toUpdateSeries[i]["%"];
                        
                        if ( toUpdateSeries[i]["$"] < 2 ) {
                            toUpdateSeries[i]["%"] *= 0.9;
                        } else if ( toUpdateSeries[i]["$"] < 50 ) {
                            toUpdateSeries[i]["%"] = testJSON[2017]["income"][i - 1]["%"];
                        }
                        if ( toUpdateSeries[i]["$"] == 30 ) {
                            toUpdateSeries[i]["%"] *= ( cumulativePct * 0.1 );
                        }
                    }
                }
                
            } else {
                var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[valueDict[jsonKey]]["income"]));
            }
            
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : toUpdateSeries,
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            
            toUpdateSeries = null;

        } else if (jsonKey == "growth") {
            // make deep copy, so state doesn't change while manipulating
            var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[this.state.varsJSON.case]["income"]));
            var cumulativePct   = 0.0,
                addlRate        = 0.0;
            
            for (var i=0; i < toUpdateSeries.length; i++) {
                cumulativePct += toUpdateSeries[i]["%"];
                
                if ( cumulativePct >= this.state.varsJSON.rateAt ) {
                    addlRate = this.state.varsJSON.rate;
                }
                toUpdateSeries[i]["$"] *= (1 + (valueDict[jsonKey] + addlRate) / 100);
            }
            
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : toUpdateSeries
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            toUpdateSeries = null;
            
        } else if (jsonKey == "rate") {
            // make deep copy, so state doesn't change while manipulating
            var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[this.state.varsJSON.case]["income"]));
            var cumulativePct   = 0.0,
                addlRate        = 0.0;
    
            for (var i=0; i < toUpdateSeries.length; i++) {
                cumulativePct += toUpdateSeries[i]["%"];
        
                //console.log(cumulativePct, this.state.varsJSON.rateAt)
                if ( cumulativePct >= this.state.varsJSON.rateAt ) {
                    addlRate = valueDict[jsonKey];
                }
                //console.log(this.state.varsJSON.growth, addlRate)
                toUpdateSeries[i]["$"] *= Math.pow( (1 + (this.state.varsJSON.growth + addlRate) / 100), this.state.varsJSON.periods );
            }
    
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : toUpdateSeries
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            toUpdateSeries = null;
    
        } else if (jsonKey == "rateAt") {
            // make deep copy, so state doesn't change while manipulating
            var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[this.state.varsJSON.case]["income"]));
            var cumulativePct   = 0.0,
                addlRate        = 0.0;
    
            for (var i=0; i < toUpdateSeries.length; i++) {
                cumulativePct += toUpdateSeries[i]["%"];
        
                if ( cumulativePct >= valueDict[jsonKey] ) {
                    addlRate = this.state.varsJSON.rate;
                }
                toUpdateSeries[i]["$"] *= Math.pow( (1 + (this.state.varsJSON.growth + addlRate) / 100), this.state.varsJSON.periods );
            }
    
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    mainSeries      : toUpdateSeries
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            toUpdateSeries = null;
            
        } else if (jsonKey == "periods") {
                    // make deep copy, so state doesn't change while manipulating
                    var toUpdateSeries = JSON.parse(JSON.stringify(testJSON[this.state.varsJSON.case]["income"]));
                    var cumulativePct   = 0.0,
                        addlRate        = 0.0;

                    for (var i=0; i < toUpdateSeries.length; i++) {
                        cumulativePct += toUpdateSeries[i]["%"];
    
                        if ( cumulativePct >= this.state.varsJSON.rateAt ) {
                            addlRate = this.state.varsJSON.rate;
                        }
                        toUpdateSeries[i]["$"] *= Math.pow( (1 + (this.state.varsJSON.growth + addlRate) / 100), valueDict[jsonKey] );
                    }

                    this.setState(
                        {
                            varsJSON        : toUpdateJSON,
                            mainSeries      : toUpdateSeries
                        }, function() {
                            this.calcNumBars();
                            this.createGiniSeries();
                        }
                    );
                    toUpdateSeries = null;
    
        } else if (jsonKey === "xMin" || jsonKey === "xMax") {
            this.setState(
                {
                    varsJSON        : toUpdateJSON
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
            
        } else if (jsonKey === "middleClass") {
        
            this.setState(
                {
                    varsJSON        : toUpdateJSON,
                    showMiddleClass : ( valueDict[jsonKey] === "Highlighting median & middle class" )
                }, function() {
                    this.createGiniSeries();
                }
            );
        } else {
            this.setState(
                {
                    varsJSON        : toUpdateJSON
                }, function() {
                    this.calcNumBars();
                    this.createGiniSeries();
                }
            );
        }
        
        toUpdateJSON = null;
    }
    
    render() {  
        
        return (
            <div id="outer_canvas">
                <div className="chart_title">
                    <h1 className="pathHeadline text_gold">Gini Coefficient Study</h1>      
                </div>
                <div className="pathDescription textAlignCenter">
            &nbsp;
                </div>
          
                <div className="pathDescription">
                    Case &nbsp; 
                        <TextToggle 
                            jsonKey             = "case"
                            moduleKey           = "case"
                            updateValues        = { this.updateValues }
                            value               = { this.state.varsJSON.case }
                            choices             = { this.testCases }
                            handleUserEvents    = { this.handleUserEvents }
                        /> &nbsp; &nbsp; Chart min: $
                        <Slider 
                            jsonKey             = "xMin"
                            moduleKey           = "xMin"
                            updateValues        = { this.updateValues }
                            min                 = "-25"
                            max                 = { this.state.varsJSON.xMax - 1 } 
                            step                = "5" 
                            value               = { this.state.varsJSON.xMin }
                            handleUserEvents    = { this.handleUserEvents }
                        />k &nbsp; &nbsp; Chart max: $
                        <Slider 
                            jsonKey             = "xMax"
                            moduleKey           = "xMax"
                            updateValues        = { this.updateValues }
                            min                 = { this.state.varsJSON.xMin + 1 }
                            max                 = "3600" 
                            step                = "5" 
                            value               = { this.state.varsJSON.xMax }
                            handleUserEvents    = { this.handleUserEvents }
                        />k. &nbsp; &nbsp; 
                            
                        <TextToggle 
                            jsonKey             = "middleClass"
                            moduleKey           = "middleClass"
                            updateValues        = { this.updateValues }
                            value               = { this.state.varsJSON.middleClass }
                            choices             = { this.middleClassCases }
                            handleUserEvents    = { this.handleUserEvents }
                        />
                            
                       <div className = "marginTopSm">
                            Growth: &nbsp;
                           <Slider 
                               jsonKey             = "growth"
                               moduleKey           = "growth"
                               updateValues        = { this.updateValues }
                               min                 = "-20"
                               max                 = "200" 
                               step                = "1" 
                               value               = { this.state.varsJSON.growth }
                               handleUserEvents    = { this.handleUserEvents }
                           />% &nbsp; &nbsp;
                               Additional growth rate at: &nbsp; 
                           <Slider 
                               jsonKey             = "rateAt"
                               moduleKey           = "rateAt"
                               updateValues        = { this.updateValues }
                               min                 = "0"
                               max                 = "99" 
                               step                = "1" 
                               value               = { this.state.varsJSON.rateAt }
                               handleUserEvents    = { this.handleUserEvents }
                               />th percentile &nbsp; &nbsp; Addl rate: &nbsp;
                           <Slider 
                               jsonKey             = "rate"
                               moduleKey           = "rate"
                               updateValues        = { this.updateValues }
                               min                 = "0"
                               max                 = "50" 
                               step                = "1" 
                               value               = { this.state.varsJSON.rate }
                               handleUserEvents    = { this.handleUserEvents }
                           />% &nbsp; &nbsp;
                       </div>
                       <div className = "marginTopSm">
                           Compound for: &nbsp;
                              <Slider 
                                  jsonKey             = "periods"
                                  moduleKey           = "periods"
                                  updateValues        = { this.updateValues }
                                  min                 = "1"
                                  max                 = "20" 
                                  step                = "1" 
                                  value               = { this.state.varsJSON.periods }
                                  handleUserEvents    = { this.handleUserEvents }
                              /> &nbsp; periods
                          </div>
                
                        
                </div>
                            
                <div id="chart1">
                    <BarChart
                        title           = {`Household Income Distribution`}
                        mainSeries      = { this.state.mainSeries }
                        highlightSeries = {
                                            [
                                                {   
                                                    data        : this.state.highlightSeries,
                                                    className   : "highlightBar" 
                                                },
                                                {   
                                                    data        : this.state.medianSeries,
                                                    className   : "medianBar" 
                                                }
                                            ]
                                          }
                        height          = "400"
                        width           = "900"
                        xMin            = { this.state.varsJSON.xMin }
                        xMax            = { this.state.varsJSON.xMax}
                        yMin            = { this.state.varsJSON.yMin }
                        yMax            = { this.state.varsJSON.yMax}
                        numBars         = { this.state.numBars}
                        xDataLabel      = { 
                            {
                                name        : "$",
                                prefix      : "",
                                suffix      : "k",
                                decimals    : 0
                            } 
                        }
                        yDataLabel      = { 
                             {
                                name        : "%",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 5,
                                axisDecimals: 1
                             }
                        }
                        mousedown       = { this.state.mousedown }
                        keydown         = { this.state.keydown }
                        cursorExtra     = "percentile"
                    /> 
                        
                    <LineChart
                        title           = {`Gini Coefficient: ${ this.state.giniCoefficient }`}
                        mainSeries      = { this.state.giniSeries }
                        subSeries       = { this.state.lorenzSeries }
                        height          = "160"
                        width           = "280"
                        margin          = {{top: 20, right: 0, bottom: 0, left: 0}}
                        labelPadding    = {{top: 0, right: 10, bottom: 10, left: 10}}
                        translate       = {{x: 574, y: -380}}
                        xDataLabel      = { 
                            {
                                name        : "a",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 0
                            } 
                        }
                        yDataLabel      = { 
                             {
                                name        : "b",
                                prefix      : "",
                                suffix      : "",
                                decimals    : 0
                             }
                        }
                        mousedown       = { this.state.mousedown }
                        keydown         = { this.state.keydown }
                        showCursorData  = { false }
                        showGrid        = { false }
                        showAxes        = { false }
                    /> 
                  
                </div> 
                   
            </div>
        )            
    }
}


export default IncIneqTool;
